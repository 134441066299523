import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from "react-router-dom";

import Header from '../includes_admin/Header';
import { BannerSection } from '../includes_admin/BannerSection';

/* DATA */
import { UserContext } from "../../../context/userContext"
import { CandidateContext } from "../../../context/CandidateContext";

import { DataPersonalApplicant } from './includes-details/DataPersonalApplicant';

/* PRE IFA */
import { DataRRSS } from './includes-details/DataRRSS';
import { DataBank } from './includes-details/DataBank';

import { TypeContract } from './includes-details/TypeContract';
import { HistoryActions } from './includes-details/HistoryActions';

import { ButtonsModal } from './includes-details/ButtonsModal';

import { API, setToken } from './../../../http-common';
import { useSnackbar } from 'react-simple-snackbar'

import { ChangeStatusContacted } from './includes-details/ChangeStatusContacted';
/* CSS */
import '../../../assets/css/new_applicant.css';
import { GROUP } from '../../../constants';
import {PROSPECT_STATUS} from "../../../constants-prod";

export const DetailsApplicant = () => {
    let history = useHistory();

    const optionsError = {
        position: 'bottom-right',
        style: {
            backgroundColor: 'var(--bs-red)',
            border: '0',
            color: '#fff',
            fontSize: '20px',
            textAlign: 'center',
            marginBottom: '5rem',
        },
        closeStyle: {
            color: '#fff',
            fontSize: '16px',
            marginRight: '1rem',
        },
    }

    const optionsSuccess = {
        position: 'bottom-right',
        style: {
            backgroundColor: 'var(--bs-green)',
            border: '0',
            color: '#fff',
            fontSize: '20px',
            textAlign: 'center',
            marginBottom: '5rem',
        },
        closeStyle: {
            color: '#fff',
            fontSize: '16px',
            marginRight: '1rem',
        },
    }

    const [openSnackbarSuccess] = useSnackbar(optionsSuccess)
    const [openSnackbarError] = useSnackbar(optionsError)

    const { userData } = useContext(UserContext)

    const [candidateData, setCandidateData] = useState('');
    const ProviderCandidateData = useMemo(() => ({candidateData, setCandidateData}), [candidateData, setCandidateData]);

    const [ checkBandkData, setCheckBandkData ] = useState( false )
    const [ checkContractData, setContractData ] = useState( false )
    const [ checkKnowingIfaData, setKnowingIfaData ] = useState( false )

    const [asesor, setAsesor] = useState("")

    const [loadingBtn, setLoadingBtn] = useState(false);

    const candidato_id = history.location.state.candidato_id;
    const is_balanz = history.location.state.is_balanz;

    const STATUS_SHOW_BTN_CONTACTADO = [PROSPECT_STATUS.PRE_CANDIDATO, PROSPECT_STATUS.CONTACTADO];

    useEffect(() => {
        if( candidato_id ) {
            getProspectById( candidato_id )
        }
    }, [candidato_id])

    /* APROBAR AL CANDIDATO PARA UN NUEVO CODIGO DE ESTATUS */
    const setAprove = async ( candidato_id, status,source ) => {
        setLoadingBtn(status === 102 && !loadingBtn)

        /* ROL QUE APRUEBA */
        console.log(`setAprove candidato => `, candidato_id)
        await API.post(`/prospects/status/${candidato_id}/${status}`,{sc_avanza:source})
        .then(function (response) {
            console.log(`setAprove response => `, response)
            openSnackbarSuccess(`Se ha actualizado el estado`)
            setLoadingBtn(false)
            window.history.back();

        })
        .catch(function (error) {
            setLoadingBtn(false)
            if(error.response){
                openSnackbarError(`No se pudo aprobar al postulante` )
            }
        });
    }
    async function resendMailProspect (candidato_id, setGenerateContractOpen) {
        setLoadingBtn(true)
        console.log(`resendMailProspect candidato => `, candidato_id)
        
        await API.post(`/prospects/send-mail/${candidato_id}`)
        .then(function (response) {
            console.log(`resendMailProspect response => `, response)
            openSnackbarSuccess(`Se ha reenviado el correo`);
            setLoadingBtn(false)
            setGenerateContractOpen(false)
        })
        .catch(function (error) {
            if(error.response){
                setLoadingBtn(false)
                setGenerateContractOpen(false)
                openSnackbarError(`No se pudo reenviar el correo` )
            }
        });
    }

    const setReject = async ( candidato_id, msg, code_id ) => {
        /* ROL QUE APRUEBA */
        console.log(`Reject candidato => `, candidato_id, msg)
        await API.post(`/prospects/status/${candidato_id}/${code_id}`, {message: msg})
        .then(function (response) {
            console.log(`Reject response => `, response)
            openSnackbarSuccess(`Se ha actualizado el estado`);
            window.history.back();
        })
        .catch(function (error) {
            if(error.response){
                openSnackbarError(`No se pudo rechazar al postulante` )
            }
        });
    }
    const setChangeStatusContacted = async (candidato_id, prospect_status, status,isChecked) =>{
        setLoadingBtn(true)
        console.log(`Change Status Contacted => `, candidato_id, status)
        await API.post(`/prospects/status/${candidato_id}/${status}/${prospect_status}`,{isChecked})
        .then(function (response) {
            console.log(`Change Status Contacted response => `, response)
            openSnackbarSuccess(`Se ha actualizado el estado`);
            window.location.reload();
        })
        .catch(function (error) {
            setLoadingBtn(false)
            if(error.response){
                openSnackbarError(`No se pudo actualizar el estado` )
            }
        })
    }
    /* SET PENDIENTE AL CANDIDATO */
    const setPendingProspect = async ( candidato_id ) => {
        window.history.back();
        // await API.post(`/prospects/set_pending/${candidato_id}`)
        //     .then(function (response) {
        //         console.log(`setPendingProspect data =>`, response)
        //         openSnackbarSuccess("Se ha actualizado el estatus a [PENDIENTE] ");
        //     })
        //     .catch( function(error) {
        //         console.log(`setPendingProspect error =>`, error.response)
        //         const msg = error.response.data.errors[0].msg ? error.response.data.errors[0].msg : "Error: no se pudo llevar a cabo la acción";
        //         openSnackbarError(msg);
        //     })
    }

    const sendOtherArea = async ( candidato_id, msg, code_id,source ) => {
        console.log(`change area=> `, candidato_id, msg, code_id)
        await API.post(`/prospects/status/${candidato_id}/${code_id}`, {message: msg, sc_retrocede:source})
        .then(function (response) {
            console.log(`change area response => `, response)
            openSnackbarSuccess(`Se ha envio al prospecto a ${GROUP.EQ_SOPORTE.includes(userData.role_id) ? "Equipo Comercial": "Soporte Comercial"}`);
            window.history.back();
        })
        .catch(function (error) {
            if(error.response){
                openSnackbarError(`No se pudo actualizar al postulante` )
            }
        });
    }

    async function getProspectById(candidato_id) {
        await API.get(`/prospects/${candidato_id}`)
        .then(function (response) {
            let data = response.data.data;

            /* SETEAR EN CONTEXTO LA PROP IS BALANZ OBTENIDA DE LA LISTA */
            /*VISTA COMPLIANCE PROSPECT STATUS = 50*/
            /*USER ROL 3 Complianse */
            data['is_balanz'] = is_balanz
            //data['prospect_status'] = 50
            setCandidateData( data )
        })
        .catch( function(error) {
            //console.log(`getProspectById => error`, error.response)
            openSnackbarError('Error al obtener los datos')
        })
    }

    return (

        <CandidateContext.Provider value={ ProviderCandidateData } >
            <div className="container-fluid" id="applicant_container_fluid">
                <div className="row mb-4">
                    <Header />
                </div>

                <BannerSection user_role={userData.role_id} prospect_status={ candidateData.prospect_status }/>
                <br/>

                <DataPersonalApplicant knowingIfaData={setKnowingIfaData} setAdviser={setAsesor} />
                <br/>

                {candidateData.prospect_status < 30 &&
                    <>
                        <DataRRSS />
                        <br/>

                        <DataBank
                            user_role={ userData.role_id }
                            setCheckBandkData={ setCheckBandkData }
                         />
                        <br/>
                        <br/>
                    </>
                }

                { candidateData.prospect_status >= 30 &&
                    <>
                        <TypeContract user_role={ userData.role_id } check_data={setContractData} />
                        <br/>
                    </>
                }

                <br/>
                <br/>

                {GROUP.EQ_DIRECTORES.includes(userData.role_id) && STATUS_SHOW_BTN_CONTACTADO.includes(candidateData.prospect_status) && (
                    <ChangeStatusContacted
                        candidateData={candidateData}
                        setChangeStatusContacted={setChangeStatusContacted}
                        loading_btn={loadingBtn}
                    />
                )}

                <br/>
                <ButtonsModal
                    user_role={ userData.role_id }
                    set_approve={ setAprove }
                    set_pending={ setPendingProspect }
                    set_reject={ setReject }
                    set_other_area={ sendOtherArea }
                    is_balanz={ is_balanz }
                    checkBandkData={ checkBandkData }
                    check_contract_data={ checkContractData}
                    check_knowing_ifa={checkKnowingIfaData}
                    loading_btn={loadingBtn}
                    resendMailProspect={resendMailProspect}
                />

                <HistoryActions prospectsId={candidato_id} />
                <br/>
                <br/>

            </div>
        </CandidateContext.Provider>
    )
}

