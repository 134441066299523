import React, { useContext, useEffect, useState } from 'react'
import { useSnackbar } from 'react-simple-snackbar'
/* API */
import { API } from './../../../../http-common';
import { ValidateInput } from '../../../../hooks/ValidateInput'
import { CandidateContext } from '../../../../context/CandidateContext';
import '../../../../assets/css/knowing_ifa.css'
import { Form } from 'react-bootstrap';
import {
    GRUPO_EQ_COMERCIAL, ROLE_COMPLIANCE,
    ROLE_EQ_COMERCIAL_ASISTENTE,
    ROLE_EQ_COMERCIAL_LIDER,
    ROLE_SOP_COMERCIAL,
    GROUP
} from "../../../../constants";

export const KnowingIfa = ({ user_role, checkData }) => {

    const { candidateData } = useContext( CandidateContext )
    const checkContractStatus = candidateData.prospect_status === 100;


    const knowingIfaInitialState = {
        presentation_date: '',
        canales: '',
        conozca_ifa: '',
        actividad_principal: ''
    }
    const errorKnowingIfaInitialState = {
        presentation_date: '',
        canales: '',
        conozca_ifa: '',
        actividad_principal: ''
    }

    const [knowingIfa, setKnowingIfa] = useState(knowingIfaInitialState)

    const [errorKnowingIfa, setErrorKnowingIfa] = useState(errorKnowingIfaInitialState)

    const [working, setWorking] = useState('')

    const [submit, setSubmit] = useState(false)

    const [today, setToday ] = useState("")

    const optionsError = {
        position: 'bottom-right',
        style: {
            backgroundColor: 'var(--bs-red)',
            border: '0',
            color: '#fff',
            fontSize: '20px',
            textAlign: 'center',
            marginBottom: '5rem',
        },
        closeStyle: {
            color: '#fff',
            fontSize: '16px',
            marginRight: '1rem',
        },
    }

    const optionsSuccess = {
        position: 'bottom-right',
        style: {
            backgroundColor: 'var(--bs-green)',
            border: '0',
            color: '#fff',
            fontSize: '20px',
            textAlign: 'center',
            marginBottom: '5rem',
        },
        closeStyle: {
            color: '#fff',
            fontSize: '16px',
            marginRight: '1rem',
        },
    }
    const [openSnackbarSuccess] = useSnackbar(optionsSuccess)
    const [openSnackbarError] = useSnackbar(optionsError)

    const handleValidateInput = ( event, typeOfPermitido ) => {
        const { name, value } = event.target

        const checkInput = ValidateInput(value, typeOfPermitido)

        let validation = true

        if(checkInput === false && name !== "canales") {
            //console.log("name: ", name, " error")
            setErrorKnowingIfa( prevErrorKnowingIfa => ({
                ...prevErrorKnowingIfa,
                [name]: "Valor inválido"
            }))

            validation = false

        }
        else if(checkInput === true) {
            setErrorKnowingIfa( prevErrorKnowingIfa => ({
                ...prevErrorKnowingIfa,
                [name]: ""
            }))
            validation = true
        }

        return validation
    }


    const handleChange = async ( e, typeOfPermitido ) => {
        const { name, value, id, type } = e.target
        console.log("name: ", name, " value: ", value, " id: ", id, " type: ", type)
        console.log("typeOfPermitido: ", typeOfPermitido)
        let validation = false

        if(type !== 'date'){
            validation = await handleValidateInput( e, typeOfPermitido );
        }

        if ( id === "canales_otros") {
            const inputs_radios = document.querySelectorAll("input[name='canales']")
            inputs_radios.forEach( (campo) => campo.checked = false)
        }

        if (name === "canales" && id !=="canales_otros") {
            const otro_input = document.querySelectorAll("input[id='canales_otros']");
            otro_input.value="";
        }

        setKnowingIfa( prevKnowingIfa => ({
            ...prevKnowingIfa,
            [name]: value
        }))
        if ( validation ) {
            setErrorKnowingIfa( prevErrorKnowingIfa => ({
                ...prevErrorKnowingIfa,
                [name]: ""
            }))
        }

        if( name === "presentation_date" && value > today  ) {
            setErrorKnowingIfa( prevErrorKnowingIfa => ({
                ...prevErrorKnowingIfa,
                [name]: "No puedes asignar una fecha posterior al dia de hoy."
            }))
        openSnackbarError('Fecha inválida')
        }
    }

    const handleSubmit = event => {
        event.preventDefault();
        setSubmit( checkInputsKnowingIfa() );
    }
    const checkInputsKnowingIfa = () => {
        let success = true;
        for( const [name, value] of Object.entries(knowingIfa) ) {
            if( value === "" ) {
                setErrorKnowingIfa( prevErrorKnowingIfa => ({
                    ...prevErrorKnowingIfa,
                    [name]: "El campo es requerido"
                }))
                openSnackbarError('Campos requeridos')
                success = false;

            }  else if ( name === "canales" && !value ) {
                setErrorKnowingIfa( prevErrorKnowingIfa => ({
                    ...prevErrorKnowingIfa,
                    [name]: "Elegi un canal antes de enviar el formulario."
                }))
            } else if ( (name === "actividad_principal" || name === "conozca_ifa") && value.length < 1 ) {
                setErrorKnowingIfa( prevErrorKnowingIfa => ({
                    ...prevErrorKnowingIfa,
                    [name]: "Ingrese la información necesaria"
                }))
            }

            for ( const [ key, value ] of Object.entries( errorKnowingIfa ) ) {
                if(key === "is_incubator"){
                    continue;
                }
                if ( value.length > 0 && key ){
                    openSnackbarError(`¡ Verifique los campos!`)
                    success = false
                }
            }
        }

        return success;
    }

    useEffect(() => {
        //Validacion de la fecha
        var today = new Date();
        var today_max = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        today = yyyy + "-" + mm + "-" + dd;
        setToday( today )

        if( submit ) {
            openSnackbarSuccess(' Guardando datos... ')
            setWorking( true )
            saveKnowingIfa();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submit])

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + (d.getDate() + 1 ),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    useEffect(() => {
        if (GROUP.EQ_SOPORTE.includes(user_role) && candidateData.prospect_status >= 40) {
            //console.log("entre en prospect_status")
            setWorking(false)
        }
        if(candidateData.id) {
            getKnowingIfa(candidateData.id)
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [candidateData])


    const getKnowingIfa = async () => {
        await API.get(`/prospects/knowing/${candidateData.id}`)
        .then(function (response) {
            //console.log(response,"res")
            let initialState = knowingIfaInitialState

            // const data = {
            //     presentation_date: "2021-10-10",
            //     network_channel: 1,
            //     referred_channel: 0,
            //     commercial_management: 0,
            //     others_channel: "",
            //     knowing_ifa: "Prueba",
            //     principal_activity: "prueba"
            // }

            if( response.data.knowing_ifa ) { //ver
                let data = response.data.knowing_ifa
                if(data.commercial_management > 0 ){
                    initialState.canales = "gestion comercial"
                    let query = document.querySelector("#gestion_comercial")
                    if(query)
                    query.checked = true
                }
                else if(data.network_channel > 0 ){
                    initialState.canales = "redes"
                    let query = document.querySelector("#redes")
                    if(query)
                    query.checked = true
                }
                else if(data.referred_channel > 0 ){
                        initialState.canales = "referido por"
                        let query = document.querySelector("#referido_por")
                        console.log(query, "and now what?")
                        if(query)
                        query.checked = true
                    }
                    else{
                        initialState.canales = data.others_channel !== null ? data.others_channel : ""
                    }

                    initialState.conozca_ifa = data.knowing_ifa !== null ?  data.knowing_ifa : ""
                    initialState.actividad_principal = data.principal_activity !== null ? data.principal_activity : ""

                    let date = formatDate(data.presentation_date)
                    initialState.presentation_date = date;
                    //initialState.presentation_date = "2021-12-02"

                    if( (initialState.presentation_date !== "1970-1-01" && initialState.presentation_date !== '') &&
                    (initialState.canales !== null && initialState.canales !== '') &&
                    (initialState.conozca_ifa !== null && initialState.conozca_ifa !== '') &&
                    (initialState.actividad_principal !== null && initialState.actividad_principal !== '')) {
                        checkData(true)
                    }
                    else{
                        checkData(false)
                    }

                setKnowingIfa( initialState ); //ver

            } else {
                setKnowingIfa( knowingIfaInitialState )
            }
        })
        .catch(function (error) {
            if ( error.response ){
                openSnackbarError(`No se pudo cargar los datos de conociendo a mi IFA`)
            }
            console.log(`getKnowingIfa error =>`, error);
        });
	};


    const saveKnowingIfa = async () => {
        /*const data = {
            presentation_date: knowingIfa.presentation_date,
            canales: knowingIfa.canales,
            conozca_ifa: knowingIfa.conozca_ifa,
            actividad_principal: knowingIfa.actividad_principal
        }*/

        const data = {
            presentation_date: knowingIfa.presentation_date,
            network_channel: 0,
            referred_channel: 0,
            commercial_management: 0,
            others_channel: "",
            knowing_ifa: knowingIfa.conozca_ifa,
            principal_activity: knowingIfa.actividad_principal
        }

        if(knowingIfa.canales !=="redes" && knowingIfa.canales !=="referido por" && knowingIfa.canales !=="gestion comercial") {
            data.others_channel = knowingIfa.canales

        } else if(knowingIfa.canales ==="redes") {
            data.network_channel = 1;

        } else if(knowingIfa.canales ==="referido por") {
            data.referred_channel = 1;

        } else if(knowingIfa.canales ==="gestion comercial") {
            data.commercial_management = 1
        }



        await API.post(`/prospects/knowing/${candidateData.id}`, data)
        .then(function (response) {
            console.log(`saveKnowingIfa response => `, response)
            checkData(true)
            openSnackbarSuccess('¡Datos guardados!')
        })
        .catch(function (error) {
            console.log(`saveKnowingIfa error =>`, error.response);
            openSnackbarError('no se pudo guardar los datos')
        });

        setWorking( false )
        setSubmit( false )
	};

    function checkOtros() {
        if(knowingIfa.canales !=="redes" && knowingIfa.canales !=="referido por" && knowingIfa.canales !=="gestion comercial"){
            return true
        }
        else{ return false }
    }

    const makeErrorField = (text) => {
		return (
			<Form.Text
				className="text-danger fw-bold"
				style={{ fontSize: "11px", lineHeight: "0" }}
			>
				{text}
			</Form.Text>
		);
	};

    /*const setDisplayClass = () => {
        const status = candidateData.prospect_status;
        let display_btn = "btn blue_btn balanz_btn"
        if( status === 28 || status === 29 || status === 48 || status === 49 || status === 58 || status === 59) {
            display_btn = "d-none"
        }

        return display_btn
    }*/

    const setSeeAble = () => {
        const status = candidateData.prospect_status;

        const show = 'btn blue_btn balanz_btn';
        const none = 'd-none';
        let display_btn = "";

        if( status === 28 || status === 29 || status === 48 || status === 49 || status === 58 || status === 59) {
            display_btn = none
        }
        else if( GROUP.EQ_DIRECTORES.includes(user_role) && status > 20 && status <= 31  ) {
            display_btn = show

        } else if ( GROUP.EQ_SOPORTE.includes(user_role) && status > 29 && status <= 40 ) {
            display_btn = show

        } else if ( GROUP.COMPLIANCE.includes(user_role) && status > 39 && status <= 50 ) {
            display_btn = show

        } else{
            display_btn = none
        }

        return display_btn
    }

    return (

        <div className="col-md-12 item my-4 px-0" id="container_knowing_my_ifa">

            <div className="row px-2 h3 text-uppercase my-5 border-bottom">conociendo mi ifa</div>
            <form action="#" id="form_knowing_my_ifa" onSubmit={ (e) => handleSubmit(e) }>
                {[...GROUP.EQ_DIRECTORES, ...GROUP.EQ_SOPORTE].includes(user_role)? (
                    <>
                        <div className="form-row d-flex align-items-baseline">
                            <label className="data_label blue_title me-2">Fecha de presentación</label>
                            <input
                                onChange={ (e) => handleChange(e, 'date') }
                                type="date"
                                value={ knowingIfa.presentation_date !== null? knowingIfa.presentation_date : "" }
                                placeholder="Fecha de presentación"
                                name="presentation_date"
                                id="presentation_date"
                                max={ today }
                                className="data_item blue_title me-2"
                                disabled={working || checkContractStatus}
                            />
                            {errorKnowingIfa.presentation_date && makeErrorField( errorKnowingIfa.presentation_date ) }
                        </div>
                        <br/>
                        <div className="row form-row align-items-baseline">
                            <div className="col-md-1 mb-2 me-2-sm">
                                <span className="data_label blue_title">Canales</span>
                            </div>
                            <div className="col-md-2 me-2-sm">
                                <label id="canal_redes" className="form-check-inline data_label blue_title">
                                    <span className="me-1">Redes</span>
                                    <input onChange={ (e) => handleChange(e) }
                                    disabled={working || checkContractStatus}
                                    value= "redes"
                                    type="radio"
                                    className="form-check-input"
                                    name="canales"
                                    id="redes" />
                                </label>
                            </div>
                            <div className="col-md-2 me-2-sm">
                                <label id="canal_referido" className="form-check-inline data_label blue_title">
                                    <span className="me-1">Referido por</span>
                                    <input
                                    onChange={ (e) => handleChange(e) }
                                    disabled={working || checkContractStatus}
                                    value="referido por"
                                    type="radio"
                                    className="form-check-input"
                                    name="canales"
                                    id="referido_por" />
                                </label>
                            </div>
                            <div className="col-md-3 me-2-sm">
                                <label id="canal_gestion_comercial data_label blue_title" className="form-check-inline">
                                    <span className="me-1 blue_title">Por gestión comercial</span>
                                    <input
                                    onChange={ (e) => handleChange(e) }
                                    disabled={working || checkContractStatus}
                                    value="gestion comercial"
                                    type="radio"
                                    className="form-check-input"
                                    name="canales"
                                    id="gestion_comercial" />
                                </label>
                            </div>
                            <div className="col-md-4">
                                <label id="canales_otros" className="me-2 d-inline data_label blue_title">Otros</label>
                                <input
                                onChange={ (e) => handleChange(e) }
                                disabled={working || checkContractStatus}
                                type="text"
                                value={checkOtros()?knowingIfa.canales:""}
                                name="canales"
                                id="canales_otros"
                                className="form-control d-inline"
                                />
                            </div>
                        </div>
                        <div className="row">{errorKnowingIfa.canales && makeErrorField( errorKnowingIfa.canales ) }</div>
                    </>
                ): (
                    <div className="row">
                        <div className="col-md-3 mb-2">
                            <span className="data_label blue_data">Fecha de presentación <br /></span>
                            <span className="data_item blue_data">{knowingIfa.presentation_date}</span>
                        </div>
                        <div className="col-md-3">
                            <span className="data_label blue_data"> Canales </span>
                            {knowingIfa.canales === "referido por" && <span className="data_item blue_data">Referido por</span>}
                            {knowingIfa.canales === "gestion comercial" && <span className="data_item blue_data">Gestion Comercial</span>}
                            {knowingIfa.canales === "redes" && <span className="data_item blue_data">Redes</span>}
                            {(knowingIfa.canales !== "redes" ||
                            knowingIfa.canales !== "gestion comercial" ||
                            knowingIfa.canales !== "referido por")  && <span className="data_item blue_data"> Otros: {knowingIfa.canales}</span>}
                        </div>
                    </div>
                )}

                <br/><br/>

                {[...GROUP.EQ_DIRECTORES, ...GROUP.EQ_SOPORTE].includes(user_role)? (
                    <div className="form-row">
                        <div className="col-md-6">
                            <span className="data_label blue_title d-block">Conozca su IFA
                            {errorKnowingIfa.conozca_ifa &&
                                    <span className="text-danger ms-2 fw-bold" style={{fontSize: '12px'}}>{ errorKnowingIfa.conozca_ifa }</span>
                                }
                            </span>

                            <textarea onChange={ (e) => handleChange(e, "text") }
                            value={knowingIfa.conozca_ifa}
                            name="conozca_ifa"
                            id="conozca_ifa"
                            className="form-control data_item" cols="30" rows="7"
                            disabled={working || checkContractStatus}
                            ></textarea>

                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-md-12">
                            <span className="data_label blue_data d-block">Conozca su IFA </span>
                            <span className="data_item blue_data">{knowingIfa.conozca_ifa}</span>
                        </div>
                    </div>
                )}

                <br/>
                {[...GROUP.EQ_DIRECTORES, ...GROUP.EQ_SOPORTE].includes(user_role)? (
                    <div className="form-row">
                        <div className="col-md-6">
                            <span className="data_label blue_title d-block">Actividad principal (lo más detallada posible)
                            {errorKnowingIfa.actividad_principal &&
                                    <span className="text-danger ms-2 fw-bold" style={{fontSize: '12px'}}>{ errorKnowingIfa.actividad_principal }</span>
                                }
                            </span>
                            <textarea onChange={ (e) => handleChange(e, "text") }
                            value={ knowingIfa.actividad_principal }
                            name="actividad_principal"
                            id="actividad_principal"
                            className="form-control data_item" cols="30" rows="7"
                            disabled={working || checkContractStatus}
                            ></textarea>
                        </div>
                    </div>
                ) : (
                    <div className="row">
                        <div className="col-md-12">
                            <span className="data_label blue_data d-block">Actividad principal</span>
                            <span className="data_item blue_data">{knowingIfa.actividad_principal}</span>
                        </div>
                    </div>
                )}

                <br/>
                {/*PREGUNTAR A ELIU*/}
                {[...GROUP.EQ_DIRECTORES, ...GROUP.EQ_SOPORTE].includes(user_role)? (
                    <div className="row form-row">
                        <div className="row col-md-6" style={{display: 'none'}}>
                            <div className="col-md-6 d-inline">
                                <label className="blue_title" htmlFor="src_cv">Adjuntar</label>
                                <div className="input-group mb-3">
                                    <div className="input-group-append">
                                        <span className="input-group-text d-none" style={{padding: ".65rem"}}> <i className="fas fa-paperclip blue_title"></i> </span>
                                    </div>
                                    <input type="file" className="form-control" id="src_cv" name="src_cv" />
                                </div>
                            </div>
                            <div className="col-md-6 d-inline">
                               {/* <label className="data_label_hide">.</label>
                                <button type="button" className="btn ml-2 bg_gray button_cv balanz_btn">
                                    <span className="text-white">Adjuntar</span>
                                </button>
                                <button type="button" className="btn ml-2 mt-4 gray_btn button_cv balanz_btn_long">
                                    <img src="../images/icon/file_attached.png" alt="file"/>
                                    <span>ver adjunto</span>
                                </button>*/}
                            </div>
                        </div>
                        <div className="col-md-6 d-flex mt-4 justify-content-end align-items-end">
                            <button
                                type="submit"
                                name="form_knowing_my_ifa"
                                id="form_knowing_my_ifa"
                                className={ setSeeAble() }
                                disabled={ working || checkContractStatus}
                            >
                                Guardar
                            </button>
                        </div>
                    </div>
                ) : (
                    ""
                )}

            </form>
        </div>
    )
}