import React from "react";
import { Container } from "react-bootstrap";

const AgreementComplete = ({ type }) => {
    return (
        <>
            <div className="headerModal d-flex align-items-center justify-content-center mb-5" style={{color:"white", fontSize:"40px", fontWeight:"bold"}}>BALANZ-IFA CONTRATO</div>
            <Container>
                <div className="message-info">
                    <p className='my-3 text-center'>
                        {type === 'completed' && 'Gracias por firmar el documento'}
                        {type === 'cancel' && 'Usted ha salido de la sesión de firma de contrato de DocuSing. Recuerde que el contrato aún no quedó aprobado ni rechazado. Vuelva a ingresar a la plataforma para finalizar el proceso.'}
                        {type === 'declined' && 'Hemos registrado su disconformidad con la firma del contrato. Su asesor quedará notificado y en breve se estará comunicando con usted para repasar la razón por la cual decidió no firmar el contrato. También puede contactarse con su asesor si así lo desea.'}

                    </p>
                </div>
            </Container>
        </>
    );
}

export default AgreementComplete;
