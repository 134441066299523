import React, { Component } from 'react'
import './PublicHeader.css'

class PublicHeader extends Component {
    render() {
        const { title } = this.props;
        const titleDefault = <h3><span>La independencia</span><span>que querés, </span><span>con el respaldo </span><span>que necesitás.</span></h3>;

        const titleFormStep = <h3><span>Postulación a</span>
        <span>Independent Financial Advisor</span></h3>;

        return(
            <header className="row" id="header_home">
                <h2 className="blue_title">BALANZ</h2>
                <div className="row">
                    { title === "defaultHome"&& 
                        titleDefault
                    }
                    { title !== "defaultHome"&& 
                        titleFormStep
                    }
                </div>
            </header>
        );
    }
}

export default PublicHeader;