import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

/* CSS */
import './ModelAlert.css'

/* ALERTAS MODALES PARA USO GENERAL */

export const ModelAlert = ({ title, text, link_src= "", link_text= "", isOpen, setIsOpen }) => {

    return (
        <Modal isOpen={ isOpen } className="" id="modal_alert">
            <ModalHeader className="justify-content-end">
                <button className="close bg-transparent border-0" onClick={ setIsOpen } >
                    <span>&times;</span>
                </button>
            </ModalHeader>
            <ModalBody className="modal-body">

                {title &&
                    <p className="modal_title">
                        { title } <br/> <br/>
                    </p>
                }
                {text &&
                    <p className="modal_text_info">
                        { text }
                    </p>
                }
                {link_src && link_text &&
                    <div className="row">
                        <a href={ link_src } className="btn blue_text_btn col">
                            { link_text }
                        </a>
                    </div>
                }
            </ModalBody>
        </Modal>
    )
}
