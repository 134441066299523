import axios from "axios";
import {API_URL,TOKEN_KEY} from './constants.js';

/*const TOKEN_KEY = 'BLNZ_TOKEN';
const API_URL = "http://balanz.development-xyclon.com:3080/api/v1";*/

const testUrl = "http://localhost:3000";

export const API =  axios.create({
  baseURL: `${API_URL}`,
  timeout: 50000,
  headers: {
    "Content-type": "application/json"
  }
});


export const APItest = axios.create({
  baseURL: `${testUrl}`,
});


export function setToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
}

export function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export function deleteToken() {
  localStorage.removeItem(TOKEN_KEY);
}

export function initAxiosInterceptors() {
  API.interceptors.request.use(config => {
    const token = getToken();

    if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }

    return config;
  });

  API.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401 || error.response.status === 403){
        deleteToken();
        window.location = '/admin/login';
      } else {
        return Promise.reject(error);
      }
    }
  )
}
