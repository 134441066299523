export const ValidateInput = (value, permitidos) => {
    if( value === "") {
        return true
    } else {

    //seleccionar los caracteres apartir del parametro de la funcion
        switch(permitidos)	{
            case  'int':
                case 'num':
                case 'numerico':
                case 'numericos':
                case 'numeros':
                case 'enteros':
                return validateInputNumeric(value)

            case 'string':
                case 'alfabetico':
                case 'letras':
                return validateInputText(value)

            case 'text':
                case 'texto':
                return validateInputText2(value)

            case 'date':
                return validateInputDate(value);

            case 'business_name':
                return validateInputBankName(value);

            case 'email':
            case 'mail':
                return ValidateInputEmail(value);

            case 'phone':
            case 'number_phone':
            return validateInputPhoneNumber(value)

            case 'phone_code_country':
                return validateInputCodCountry( value )

            case 'cuit':
                return validateInputCuit(value)

            case 'num_letras_simbolos':
                break

            case 'num_simbolos':
                break;

            default:
                return validateInputText(value)
        }
    }
}

export const validateInputText = (texto) => {
    const pattern = /^[a-z|A-Z| |áéíóúñ]*$/
    if(pattern.test(texto)) {
        return true
    } else {
        return false
    }
}

export const validateInputText2 = (texto) => {
    const pattern = /^[a-z|A-Z| |áéíóúñ|0-9|.|,|/|+|:|;|\s|\S|&|(|)|=|#|-]*$/
    if(pattern.test(texto)) {
        return true
    } else {
        return false
    }
}

export const validateInputBankName = (texto) => {
    const pattern = /^[a-z|A-Z| |áéíóúñ|.|,|-]*$/
    if(pattern.test(texto)) {
        return true
    } else {
        return false
    }
}

export const validateInputNumeric = (num) => {
    const pattern = /^[0-9]*$/
    return pattern.test(num)
}

export const ValidateInputEmail = (email) => {
    //res = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const validateInputCodCountry = ( codigo_pais ) => {
    const re = /^[+]+([0-9])*$/
    return re.test(codigo_pais);
}

export const validateInputPhoneNumber = ( number_phone ) => {
    //const re = /^[+]+([0-9])*$/
    const re = /^[+](\d{1,5}) (\d{1,5})-(\d{3,10})$/
    return re.test(number_phone);
}

export const validateInputCuit = ( cuit ) => {
    const re = /^(\d{2}) (\d{8}) (\d{1})$/
    return re.test(cuit);
}

//regex para fechas en formato YYYY/MM/DD
export const validateInputDate = ( date ) => {
    const date_regex= /^(\d{4})-(\d{1,2})-(\d{1,2})$/
    return date_regex.test(date);
}