import React from 'react'
import '../../assets/css/email_candidato.css';

const Email = ({ name="Mariana García" }) => {
    return (
        <div className="container-fluid p-0" id="email_candidato_container_fluid">
            <header className="row"></header>
            <div className="row mt-4">
                <div className="container mt-4">

                    <div className="body_info my-4">
                        <strong className="head_name h5 font-weight-bold">
                            <span id="name">{ name },</span>
                        </strong>
                        <span>¡Gracias por postularte como Independent Financial Advisor!</span>
                        <span>Para completar tu postulación es necesario que completes los datos personales</span>
                        <span>dentro de tu perfil de cliente Balanz.</span>
                    </div>

                    <div className="footer_info my-4">
                        <a href="https://completar" className="btn blue_btn balanz_btn_long py-2">
                            Completar Mis Datos
                        </a>
                    </div>
                </div>
            </div>
            <div className="row blue_line_botom py-2 mx-0"></div>
        </div>
    )
}
export default Email