import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { checkProspectStatus } from '../../../hooks/commons'

export const BannerSection = ({user_role, prospect_status}) => {
    const handleBack = () => {
        window.history.back();
    }

    const toggleEdit = () => {
        let campos = document.querySelectorAll('form#data_postulant table tbody input, form#data_postulant table tbody select')
        campos.forEach( (campo) => campo.toggleAttribute('disabled'))

        /*let pep = document.querySelector("#pep").value
        let pep_reason = document.querySelector("#pep_reason")
        let agente_cnv_aagi_ap = document.querySelector("#agente_cnv_aagi_ap").value
        let enrollment_aagi = document.querySelector("#enrollment_aagi")

        if(Number(pep) === 2) {
            pep_reason.toggleAttribute('disabled')
        }
        if(Number(agente_cnv_aagi_ap) === 2) {
            enrollment_aagi.toggleAttribute('disabled')
        }*/

        let buttos_action = document.querySelector('#buttons_actions_form');
        buttos_action.classList.toggle('hidden')

        let button_edit = document.querySelector('#edit');
        button_edit.classList.toggle('hidden')
    }

    /* setDisplayClass = () => {
        const status = prospect_status;
        let display_btn = "btn blue_btn balanz_btn_long"
        if( status === 28 || status === 29 || status === 48 || status === 49 || status === 58 || status === 59) {
            display_btn = "d-none"
        }

        return display_btn
    }*/

    const setSeeAble = () => {
        const status = prospect_status;
        const show = 'btn blue_btn balanz_btn_long';
        const none = 'd-none';
        let display_btn = none;

        if(checkProspectStatus(status, user_role)) {
            display_btn = show;
        }
        return display_btn
    }

    return (
        
         <div className="row banner_section mt-4">
            <div className="col-md">
                <span className="title_section blue_title">Nuevo Postulante al IFA </span>
            </div>
            <div className="col-md">
                <button href="#back" onClick={ (e)=>handleBack() } className="arrow_back gray_text border-0 bg-transparent">
                    <FontAwesomeIcon icon={faAngleLeft} />
                    <span><b>volver al listado</b></span>
                </button>
                {user_role !== 3 &&
                    <button type="button" id="edit" className={ setSeeAble() } onClick={ toggleEdit }>
                        <span>editar datos</span>
                        <FontAwesomeIcon icon={faEdit} />
                    </button>
                }

            </div>
        </div>
    )
}
