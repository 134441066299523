import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Tab, Spinner } from "react-bootstrap";
import Button from '@restart/ui/esm/Button';

import { ModelAlert } from '../../../ModelAlert';

import {ValidateInput, ValidateInputEmail} from '../../../hooks/ValidateInput'

/* API */
import { API, initAxiosInterceptors } from './../../../http-common';
initAxiosInterceptors();

const FormType = ({ formType }) => {


    const [modalSuccess, setModalSuccess] = useState(false)
    const [modalError, setModalError] = useState(false)

    const modalErrorApiInitialState = {
        state: false,
        msg: ''
    }
    const [modalErrorApi, setModalErrorApi] = useState(modalErrorApiInitialState)

    const [submit, setSubmit] = useState(false)

    const datosGeneralesInitialState = {
        document_type: 1,
        physical_person: 1,
        cuit: '',
        document: '',
        business_name: '',
        last_name: '',
        first_name: '',
        email: '',
        phone_country_code: '',
        phone_area: '',
        phone_number: '',
        suitable: 0,
    }
    const [datosGenerales, setDatosGenerales] = useState(datosGeneralesInitialState)

    const errorDatosGeneralesInitialState = {
        document: '',
        cuit: '',
        business_name: '',
        last_name: '',
        first_name: '',
        email: '',
        phone_country_code: '',
        phone_area: '',
        phone_number: '',
    }
    const [errorDatosGenerales, setErrorDatosGenerales] = useState(errorDatosGeneralesInitialState)

    const handleValidateInput = ( event, typeOfPermitido ) => {
        const { name, value } = event.target

        if( name === "email") {

            const checkEmail = ValidateInputEmail(value);
            if( checkEmail ) {
                setErrorDatosGenerales( prevErrorDatosGenerales => ({
                    ...prevErrorDatosGenerales,
                    [name]: ''
                }))

            } else {
                setErrorDatosGenerales( prevErrorDatosGenerales => ({
                    ...prevErrorDatosGenerales,
                    [name]: 'Correo electrónico no válido'
                }))
            }

        }else {
            const checkInput = ValidateInput(value, typeOfPermitido)

            if(checkInput === true) {
                setErrorDatosGenerales( prevErrorDatosGenerales => ({
                    ...prevErrorDatosGenerales,
                    [name]: ''
                }))

            } else {
                setErrorDatosGenerales( prevErrorDatosGenerales => ({
                    ...prevErrorDatosGenerales,
                    [name]: "valor no válido"
                }))
            }
        }
    }

    const handleChangeInput = ( event, typeOfPermitido ) => {
        const { name, value } = event.target

        handleValidateInput(event, typeOfPermitido)

        setDatosGenerales( prevDatosGenerales => ({
            ...prevDatosGenerales,
            [name]: (name === "suitable") ? (event.target.checked === true) ? 2 : 1 : value
        }))

    }

    const handleSubmitForm = ( event ) => {
        event.preventDefault();

        setSubmit( checkInputs() );
    }

    const checkInputs = () => {
        let success = true;
        for( const [clave, valor] of Object.entries(datosGenerales) ) {

            if( formType === "form_fisica") {
                //TODO: EVITAR QUE EL BACK PIDA CUIT Y BUSINESS_NAME CUANDO SE LLENE UN FORMULARIO FISICO
                setDatosGenerales(prevDatosGenerales => ({
                    ...prevDatosGenerales,
                    'physical_person': 1,
                    /* 'cuit': 12345678901,
                    'bussineess_name': 'razon social' */
                }))

                if( clave === "business_name" || clave === "cuit") {
                    continue
                } else {
                    if( valor === "" ) {

                        setErrorDatosGenerales( prevErrorDataGenerales => ({
                            ...prevErrorDataGenerales,
                            [clave]: "campo requerido"
                        }))
                        success = false;

                    } else if( clave === "document" && ( valor.length < 7 || valor.length > 8 ) ) {

                        setErrorDatosGenerales( prevErrorDataGenerales => ({
                            ...prevErrorDataGenerales,
                            [clave]: "la longitud debe ser de 7 a 8 caracteres numéricos"
                        }))
        
                        success = false

                    } else if( clave === "phone_number" && ( valor.length < 4 || valor.length > 10 ) ) {

                        setErrorDatosGenerales( prevErrorDataGenerales => ({
                            ...prevErrorDataGenerales,
                            [clave]: "la longitud debe ser de 4 a 10 caracteres numéricos"
                        }))
        
                        success = false

                    }else if( clave === "phone_area" && ( valor.length < 2 || valor.length > 5 ) ) {
                        setErrorDatosGenerales( prevErrorDataGenerales => ({
                            ...prevErrorDataGenerales,
                            [clave]: "la longitud debe ser de 2 a 5 caracteres numéricos"
                        }))

                        success = false
                    } else if( clave === "phone_country_code" && ( valor.length < 2 || valor.length > 8 ) ) {
                        setErrorDatosGenerales( prevErrorDataGenerales => ({
                            ...prevErrorDataGenerales,
                            [clave]: "la longitud debe ser de 2 a 8 caracteres"
                        }))

                        success = false
        
                    } else if ( ( clave === "first_name" || clave === "last_name") && valor.length < 3 ) {
                        setErrorDatosGenerales( prevErrorDataGenerales => ({
                            ...prevErrorDataGenerales,
                            [clave]: "la longitud mínima es de 3 caracteres"
                        }))
                    }
                }
            } else {
                //TODO: EVITAR QUE EL BACK PIDA "DOCUMENT" CUANDO SE ENVIE UN FORMULARIO DE TIPO JURIDICO

                setDatosGenerales(prev => ({
                    ...prev,
                    'physical_person': 0,
                    document: 111111
                }))
                if ( clave === "document" ) {
                    continue
                } else {
                    if( valor === "" ) {
                        setErrorDatosGenerales( prevErrorDataGenerales => ({
                            ...prevErrorDataGenerales,
                            [clave]: "campo requerido"
                        }))
                        success = false;

                    } else if( clave === "cuit" && ( valor.length < 11 || valor.length > 11 ) && formType !== "form_fisica" ) {
                        setErrorDatosGenerales( prevErrorDataGenerales => ({
                            ...prevErrorDataGenerales,
                            [clave]: "la longitud debe ser de 11 caracteres numéricos"
                        }))
                        success = false
                    }
                }
            }

            if ( success ) {
                for ( const [ key, value ] of Object.entries( errorDatosGenerales ) ) {

                    if ( formType === "form_fisica") {
                        if( (key !== "cuit" && key !== "business_name") &&  value.length > 0) {
                            success = false
                        }

                    } else {
                        if( key !== "document" &&  value.length > 0) {
                            success = false
                        }
                    }
                }
            }
        }

        if( success ) {
            //SET PHONE CODE COUNTRY LIKE A STRING WITH "+" SYMBOL
            const phone_country_code = `+${datosGenerales.phone_country_code}`;
            setDatosGenerales( prevDatosGenerales => ({
                ...prevDatosGenerales,
                phone_country_code: phone_country_code
            }));
        }

        return success;
    }

    useEffect(() => {
        if( submit ) {

            ( async() => {
                await saveProspect()
            })();
        }

    }, [submit])

    useEffect(() => {
        setErrorDatosGenerales( errorDatosGeneralesInitialState )
        setDatosGenerales( datosGeneralesInitialState )
    }, [formType])


    const saveProspect = async () => {
        
        let data = JSON.parse(JSON.stringify(datosGenerales))
        let url = window.location.search;  
        url = url.replace('?', '');
        data.referred = url !== '' ? url : "-";

        await API.post('/prospects/create', data)

            .then(function (response) {
                setModalSuccess( true )
            })
            .catch( function(error) {
                if (error.response) {
                    setModalErrorApi({
                        state: true,    
                        msg: error.response.data.errors[0].msg
                    })
                }
            })

        setSubmit(false)
        setErrorDatosGenerales( errorDatosGeneralesInitialState )
        setDatosGenerales( datosGeneralesInitialState )
    }

    const makeErrorField = ( text ) => {
        return <Form.Text className="text-danger fw-bold" style={{ fontSize: '11px', lineHeight: '0' }}>{ text }</Form.Text>
    }

    return (
        <Tab.Pane className="" eventKey={ formType } >
            <Form onSubmit={ handleSubmitForm } id="form_postulant_public">
                {formType !== "form_fisica" &&
                    <Row>
                        <Form.Group as={Col} className="pe-4">
                            <Form.Control
                                type="text"
                                placeholder="Razón Social"
                                name=       'business_name'
                                id=         'business_name'
                                value=      { datosGenerales.business_name }
                                onChange={ (e) => handleChangeInput(e, 'business_name') }
                                className={ errorDatosGenerales.business_name ? "text-danger border-danger" : "" }
                            />
                            { errorDatosGenerales.business_name &&
                                makeErrorField(errorDatosGenerales.business_name)
                            }
                        </Form.Group>
                    </Row>
                }
                <Row>
                    <Form.Group as={Col} className="pe-4">
                        <Form.Control
                            type="text"
                            placeholder="Nombre"
                            name=       'first_name'
                            id=         'first_name'
                            value=      { datosGenerales.first_name }
                            onChange={ (e) => handleChangeInput(e, 'letras') }
                            className={ errorDatosGenerales.first_name ? "text-danger border-danger" : "" }
                        />
                        { errorDatosGenerales.first_name &&
                            makeErrorField(errorDatosGenerales.first_name)
                        }
                    </Form.Group>

                    <Form.Group as={Col}>
                        <Form.Control
                            type="text"
                            placeholder="Apellido"
                            name=       "last_name"
                            id=         "last_name"
                            value=      { datosGenerales.last_name }
                            onChange={ (e) => handleChangeInput(e, 'letras') }
                            className={ errorDatosGenerales.last_name ? "text-danger border-danger" : "" }
                        />
                        { errorDatosGenerales.last_name &&
                            makeErrorField(errorDatosGenerales.last_name)
                        }
                    </Form.Group>
                </Row>

                <Row>
                    <Form.Group as={Col} className="pe-4">
                        <Form.Control
                            type="text"
                            placeholder="Correo Electrónico"
                            name="email"
                            id="email"
                            value={ datosGenerales.email }
                            onChange={ (e) => handleChangeInput(e) }
                            className={ errorDatosGenerales.email ? "text-danger border-danger" : "" }
                        />

                        { errorDatosGenerales.email &&
                            makeErrorField(errorDatosGenerales.email)
                        }
                    </Form.Group>

                    <Form.Group as={Col}>
                        <Form.Control
                            type="text"
                            placeholder={ (formType === "form_fisica") ? 'DNI': 'CUIT' }
                            name=       { (formType === "form_fisica") ? 'document': 'cuit' }
                            id=         { (formType === "form_fisica") ? 'document': 'cuit' }
                            value=      { (formType === "form_fisica") ? datosGenerales.document : datosGenerales.cuit }
                            onChange={ (e) => handleChangeInput(e, 'numeros') }
                            className={
                                formType === "form_fisica" && errorDatosGenerales.document ? "text-danger border-danger"
                                : formType !== "form_fisica" && errorDatosGenerales.cuit ? "text-danger border-danger" : ""}
                        />
                        { formType === "form_fisica" && errorDatosGenerales.document &&
                            makeErrorField(errorDatosGenerales.document)
                        }

                        { formType !== "form_fisica" && errorDatosGenerales.cuit &&
                            makeErrorField(errorDatosGenerales.cuit)
                        }
                    </Form.Group>
                </Row>

                <Row id="formGroupRowTlf">
                    <Col className="row" md={6}>

                        <Form.Group as={Col} md={3}>
                            <div className="d-flex aling-items-center">
                                <b className="pe-0 pb-0 h2 m-0" style={{ padding: '.75rem', color: 'var(--lblue)'}}>+</b>
                                <Form.Control
                                    type="text"
                                    placeholder="54"
                                    name="phone_country_code"
                                    id="phone_country_code"
                                    value={ datosGenerales.phone_country_code }
                                    onChange={ (e) => handleChangeInput(e, 'numeros') }
                                    className={ errorDatosGenerales.phone_country_code ? "text-danger border-danger" : "" }
                                />
                            </div>
                            { errorDatosGenerales.phone_country_code &&
                                makeErrorField(errorDatosGenerales.phone_country_code)
                            }
                        </Form.Group>

                        <Form.Group as={Col} md={3}>
                            <Form.Control
                                type="text"
                                placeholder="Área"
                                name="phone_area"
                                id="phone_area"
                                value={ datosGenerales.phone_area }
                                onChange={ (e) => handleChangeInput(e, 'numeros') }
                                className={ errorDatosGenerales.phone_area ? "text-danger border-danger" : "" }
                            />
                            { errorDatosGenerales.phone_area &&
                                makeErrorField(errorDatosGenerales.phone_area)
                            }
                        </Form.Group>

                        <Form.Group as={Col} md={6} id="formGridTlf">
                            <Form.Control
                                type="text"
                                placeholder="Teléfono"
                                name="phone_number"
                                id="phone_number"
                                value={ datosGenerales.phone_number }
                                onChange={ (e) => handleChangeInput(e, 'numeros') }
                                className={ errorDatosGenerales.phone_number ? "text-danger border-danger" : "" }
                            />
                            { errorDatosGenerales.phone_number &&
                                makeErrorField(errorDatosGenerales.phone_number)
                            }
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group id="formGridCheckbox" >
                    <Form.Check
                        type="checkbox"
                        label="Soy idóneo en el mercado de capitales"
                        name="suitable"
                        id="suitable"
                        checked={ datosGenerales.suitable }
                        onChange={ (e) => handleChangeInput(e) }
                    />
                </Form.Group>

                <Form.Group id="formGridSubmit">
                    <Button variant="primary" disabled={submit} className='d-flex justify-conten-center align-items-center' type="submit">
                        {submit && (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: '7px' }}
                            />
                        )}
                        Quiero postularme
                    </Button>
                    <br/>
                    {errorDatosGenerales.submit &&
                        makeErrorField('Verifique los campos del formulario')
                    }
                </Form.Group>
            </Form>

            <ModelAlert
                title="Error"
                text="Error mensaje"
                isOpen={ modalError }
                setIsOpen={ () => setModalError(false) }
            />

            <ModelAlert
                text={ modalErrorApi.msg }
                isOpen={ modalErrorApi.state }
                setIsOpen={ () => setModalErrorApi(modalErrorApi.state) }
            />

            <ModelAlert
                title="¡Gracias por registrarte! "
                text="En breve nos estaremos comunicando con usted para avanzar en el proceso de pre ingreso"
                isOpen={ modalSuccess }
                setIsOpen={ () => setModalSuccess(false) }
            />

        </Tab.Pane>
    )
}
export default FormType