
import FadeLoader from "react-spinners/FadeLoader";

const Loading = () => {
	const style = {
		width: '100%',
		height: '100%',
		backgroundColor: '#004b80',
		position: 'fixed',
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center'
	}

	return (
		<div style={style} >
			<FadeLoader color='#fff' loading={true} css={""} height={25} width={5} radius={2} margin={2} />
		</div>
	)
}

export default Loading