import React from 'react';
import { Spinner } from 'react-bootstrap';
export const ChangeStatusContacted = ({candidateData,setChangeStatusContacted,loading_btn}) => {
    const handleSubmit = (e) => {
      let isChecked = e.target.checked
      setChangeStatusContacted(candidateData.id, candidateData.prospect_status, 3,isChecked)
    };
    

    return (
      <div className="row item d-flex flex-row flex-wrap justify-content-start pb-5 modal_btns" id="btns_modals" >
             <label className="input-contactado d-inline-block" >
             <input
                type="checkbox"
                id="submit_add_data_bank"
                className={`check-input-btn ${loading_btn && 'd-none'}`}
                disabled={loading_btn}
                checked={ candidateData.prospect_status === 3 ? true : false}
                onChange={ (e) => handleSubmit(e)}
              />
              {loading_btn ? (
                                    <>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <Spinner style={{ 'margin-right': '5px' }} size="sm" animation="border" />
                                            <span>Enviando...</span>
                                        </div>

                                    </>
                                ) : 'CONTACTADO'}
            </label>

         
      </div>
        
    );
}
