import React, { useContext, useState } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

//import { UserContext } from "../../../../context/userContext"
import { CandidateContext } from '../../../../context/CandidateContext';
import { useSnackbar } from 'react-simple-snackbar'
import { API } from '../../../../http-common';
import { Spinner } from 'react-bootstrap';
import {GRUPO_EQ_COMERCIAL, ROLE_COMPLIANCE, ROLE_SOP_COMERCIAL, GROUP,PROSPECT_STATUS} from "../../../../constants";

export const ButtonsModal = ({ user_role, set_approve, set_pending, set_reject, is_balanz, set_other_area, checkBandkData, check_contract_data, check_knowing_ifa, loading_btn = false , resendMailProspect}) => {
    const optionsError = {
        position: 'bottom-right',
        style: {
            backgroundColor: 'var(--bs-red)',
            border: '0',
            color: '#fff',
            fontSize: '20px',
            textAlign: 'center',
            marginBottom: '5rem',
        },
        closeStyle: {
            color: '#fff',
            fontSize: '16px',
            marginRight: '1rem',
        },
    }
    const optionsSuccess = {
        position: 'bottom-right',
        style: {
            backgroundColor: 'var(--bs-green)',
            border: '0',
            color: '#fff',
            fontSize: '20px',
            textAlign: 'center',
            marginBottom: '5rem',
        },
        closeStyle: {
            color: '#fff',
            fontSize: '16px',
            marginRight: '1rem',
        },
    }

    const [openSnackbarSuccess] = useSnackbar(optionsSuccess)
    const [openSnackbarError] = useSnackbar(optionsError)
    const [rejectMsg, setRejectMsg] = useState("")
    const [rejectMsg2, setRejectMsg2] = useState("")
    const [sendOtherAreaMsg, setSendOtherAreaMsg] = useState("")

    const [ApproveModalOpen, setApproveModalOpen] = useState(false);

    const [generateContractOpen, setGenerateContractOpen] = useState(false);
    const [openModalSendMail, setOpenModalSendMail] = useState(false)
    const [reactivateProcess, setReactivateProcess] = useState(false);
    const [ApproveModalPreIfa, setApproveModalPreIfa] = useState( false );
    const [ApproveModalPreIfa2, setApproveModalPreIfa2] = useState( false );

    const [getDataBankApi, setGetDataBankApi] = useState(true)

    const [RejectModalOpen, setRejectModalOpen] = useState(false);
    const [RejectModal2Open, setRejectModal2Open] = useState(false);
    const [sendOtherArea, setSendOtherArea] = useState(false);

    //const { userData } = useContext(UserContext)
    const { candidateData } = useContext(CandidateContext)

    const [ ableToApprovePreIfa, setAbleToApprovePreIfa ] = useState(false)

    const [ working, setWorking ] = useState(false)

    const [ cliBalanz, setCliBalanz ] = useState(false)

    const remainingFields = [];

    const setSeeAble = () => {
        const status = candidateData.prospect_status;
        const show = 'd-inline-block w-20';
        const none = 'd-none';
        let display_btn = "";
        if (status === PROSPECT_STATUS.EC_SOLO_BLZ ||
            status === PROSPECT_STATUS.EC_DESCARTADO_BLZ || 
            status === PROSPECT_STATUS.SC_DESCARTADO ) 
           { 
            display_btn = none;
            } 
        
        else if (GROUP.EQ_DIRECTORES.includes(user_role) && status > PROSPECT_STATUS.EC_DESCARTADO && status <= PROSPECT_STATUS.EC_SOLO_IFA) {
                display_btn = show
        } else if ( GROUP.EQ_SOPORTE.includes(user_role) && status > 29 && status <= PROSPECT_STATUS.SC_CANDIDATO || status === PROSPECT_STATUS.SC_BACK_CANDIDATO ) {
            display_btn = show
        } else if ( GROUP.COMPLIANCE.includes(user_role) && status > 39 && status === PROSPECT_STATUS.CMP_CANDIDATO || status === PROSPECT_STATUS.CMP_BACK_CANDIDATO ) {
            display_btn = show
        } else{
            display_btn = none
        }
        return display_btn
    }
  
    const handleClose = () => {
        setRejectMsg("");
        setRejectMsg2("");
        setSendOtherAreaMsg("");
        setRejectModalOpen(false);
        setRejectModal2Open(false);
        setSendOtherArea(false)
    }

    const scrollIntoViewTo = (id) => {
        if ( document.getElementById(id) ) {
            document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
        } else if ( document.querySelector(`[for="${id}"]`) ) {
            document.querySelector(`[for="${id}"]`).scrollIntoView({ behavior: 'smooth' })
        }
    }

    const showSnackbarErrorValidations = (list_errors) => {
        openSnackbarError(
            <div>
                <b role="button" onClick={ () => scrollIntoViewTo('edit') } >
                    Complete los datos personales:
                </b>
                <br/>
                <div className='text-left'>
                    {list_errors.map((word) => {
                        return <span
                                role="button"
                                className='d-block me-2 mt-2'
                                key={word.id}
                                onClick={ () => scrollIntoViewTo(word.id) }
                                style={{'textAlign': 'left'}}
                            >
                            - {word.text} <br/>
                        </span>
                    })}
                </div>
            </div>
            ,[500000]
        )
    }

    const handleShowModalPreIfa = async (isent_balanz = false ) => {
        /* setApproveModalPreIfa( prev => ({ ...prev, status: true })
        setApproveModalPreIfa( prev => ({ ...prev, status: true }) */

        setWorking( true )

        if ( isent_balanz ) {
            setCliBalanz( true )
        }

        if( checkBandkData === false ) {
            openSnackbarError("faltan datos bancarios")
            setWorking( false )

        } else {
            let ableToApprove = await getProspectById(candidateData.id)

            if( ableToApprove === true ) {
                if ( isent_balanz ) {
                    setApproveModalPreIfa( true )
                } else {
                    setApproveModalPreIfa2( true )
                }

            } else {
                let list = []
                let titles = document.querySelectorAll("label");
                titles.forEach(field => {
                    remainingFields.forEach(element => {
                        if(field.htmlFor === element) {
                            list.push({text:field.innerText, id:element})
                        }
                    });
                })
                showSnackbarErrorValidations(list);
            }
        }
    }
    /* CONSULTAR LA DATA PERSONAL DEL CANDIDATO */
    const getProspectById = async (candidato_id) => {

        let ableToApprove = true

        await API.get(`/prospects/${candidato_id}`)

        .then(function (response) {
            const data = response.data.data;

            /**
             * KEYS TO IGNORE INTO VALIDATION FOR APPROVED
             * SOME KEYS ARE BALANZ DATA BECAUSE NO NEED VALIDATION
             */
            const array_keys_to_ignore_validation = [
                "account_type",
                "bank_name",
                "btn_nosis",
                "btn_afip",
                "btn_additional_info",
                "btn_compliance_report",
                "btn_ccbu",
                "btn_mac",
                "btn_google_doc",
                "btn_doc_varios",
                "btn_uif",
                "btn_world_check",
                "business_id",
                "business_unit",
                "cv_id",
                "cbu",
                "ddjj_so",
                "document_front",
                "document_back",
                "is_incubator",
                "suitability_exam",
                "legajo",
                "relationships",
                "rrss_fb",
                "rrss_ig",
                "rrss_tw",
                "rrss_lk",
                "other_relationships",
                'date_nosis',
                'name_nosis',
                'date_afip',
                'name_afip',
                'date_world_check',
                'name_world_check',
                'date_uif',
                'name_uif',
                'date_additional_info',
                'name_additional_info',
                'date_compliance_report',
                'name_compliance_report',
                'date_mac',
                'name_mac',
                'date_google_doc',
                'name_google_doc',
                'date_doc_varios',
                'name_doc_varios',
            ];
            for ( const [key, value] of Object.entries(data) ) {

                if(
                    array_keys_to_ignore_validation.includes(key) ||
                    (key === "registration_number" && value === "") ||
                    (key === "agente_cnv_aagi_ap" && value === 1)
                    ) {
                    continue
                }
                else if(data.agente_cnv_aagi_ap === 1 && data.enrollment_aagi === ""){
                    continue
                }
                else if(
                    value === "" ||
                    value === 0 ||
                    value === null ||
                    value === undefined ||
                    value.toString().length < 1
                ) {
                    console.log(key, value)
                    remainingFields.push(key)
                    //console.log("entre aqui 4", value, key)
                    ableToApprove =  false
                }
            }
        })
        .catch( function(error) {
            //console.log(`getProspectById => error`, error.response)
            openSnackbarError('Error al obtener los datos del candidato')
            ableToApprove =  false
        })
        setWorking( false )
        return ableToApprove
    }

    const checkData = () => {
        if(GROUP.EQ_SOPORTE.includes(user_role) && (check_knowing_ifa === false || check_contract_data === false) ) {
            let err = []
            if(!check_contract_data) err.push("Tipo de Contrato")
            if(!check_knowing_ifa) err.push("Conociendo mi IFA")

            openSnackbarError(`Debe rellenar los datos: ${err.join(', ')}`)
        }
        else if(is_balanz < 1 && candidateData.prospect_status === 30) {
            openSnackbarError("El candidato debe ser Cliente Balanz")
        }
        else {
            setApproveModalOpen(true)
        }
    }
  
    /* STATUS APROBAR */
    let nex_status_approved = 0;

     if (GROUP.EQ_SOPORTE.includes(user_role) && candidateData.prospect_status === PROSPECT_STATUS.SC_CANDIDATO) {
        //aprueba soporte comercial
        nex_status_approved = 50
    } else if(GROUP.EQ_SOPORTE.includes(user_role) && candidateData.prospect_status === PROSPECT_STATUS.SC_BACK_CANDIDATO){
        nex_status_approved = 51
    }
    else if (GROUP.EQ_DIRECTORES.includes(user_role)) {
        if(candidateData.prospect_status >= 30) {
            //aprobar ifa y pasa a soporte comercial
            nex_status_approved = 40
        }else {
            //aprobar pre candidato
            nex_status_approved = 30
        }
    }  else if (GROUP.COMPLIANCE.includes(user_role)) {
        //aprueba compliance
        nex_status_approved = 100
    }

    let reactivate = 0;

    if(candidateData.prospect_status === 39) {
        reactivate = 30
    } else if(candidateData.prospect_status === 49) {
        reactivate = 40
    }else if(candidateData.prospect_status === 59) {
        reactivate = 50
    } else {
        reactivate = 1
    }

    return (
        <>
            <div className="row item d-flex flex-row flex-wrap justify-content-start pb-5" id="btns_modals">
                    <div className={ setSeeAble() } id="btn_aprobar_ifa">
                    <button
                        type="button"
                        style={{height: '59px' }}
                        className="w-100 btn green_btn balanz_btn"
                        aling="left"
                        onClick={ () => checkData() }
                    >
                        Aprobar
                    </button>
                </div>

                {
                [...GROUP.EQ_SOPORTE, ...GROUP.COMPLIANCE].includes(user_role) === true && (candidateData.prospect_status >= PROSPECT_STATUS.SC_CANDIDATO )&&
                <div className={setSeeAble("otra area")} id="btn_enviar_a">
                    <button type="button" className="w-100 btn gray_btn balanz_btn_long" onClick={() => setSendOtherArea(true)}>
                        {GROUP.COMPLIANCE.includes(user_role) ? (
                            <>Enviar a <br/>Soporte IFA</>
                        ) : (
                            <>Enviar a <br/>otra área</>
                        ) }
                        
                    </button>
                </div>
                }

                { candidateData.prospect_status < 100 &&
                        <div className={ setSeeAble() } id="btn_rechazar_ifa">
                            <button
                                type="button"
                                style={{height: '59px'}}
                                className="w-100 btn red_btn balanz_btn_long"
                                onClick={e => setRejectModalOpen(true)}
                            >
                                Rechazar
                            </button>
                        </div>
                }

                {/* BOTONES PRE IFA */}
                {(GROUP.EQ_DIRECTORES.includes(user_role) && candidateData.prospect_status <= 27) &&
                    <>
                        <div className="d-inline-block w-20 m-0" id="btn_pre_aprobar_ifa" style={{margin: "10px 10px"}}>
                            <button
                                className="w-100 btn green_btn balanz_btn_long"
                                aling="left"
                                onClick={ () => handleShowModalPreIfa() }
                            >
                                <small> Aprobar como<br/>Candidato IFA {is_balanz < 1 && "- Balanz"}</small>
                            </button>
                        </div>

                        {is_balanz === 0 &&
                            <div className="d-inline-block w-20 m-0" id="btn_pre_aprobar_ifa_2">
                                <button
                                    type="button"
                                    className="w-100 btn green_btn2 balanz_btn_long small"
                                    onClick={ () => handleShowModalPreIfa('isent_balanz') }
                                >
                                    <small style={{whiteSpace: 'nowrap'}}>Aprobar candidato IFA</small>
                                    <br/>
                                    <small style={{whiteSpace: 'nowrap'}}>sin ser cliente Balanz</small>
                                </button>
                            </div>
                        }
                        {is_balanz === 0 &&
                        <div className="d-inline-block w-20 m-0" id="btn_pre_rechazar_ifa_apro_balanz" style={{margin: "0 10px"}}>
                            <button
                            type="button"
                            className="w-100 text-white btn btn-secondary balanz_btn_long small"
                            onClick={() => setRejectModal2Open(true)}>

                                <small style={{whiteSpace: 'nowrap'}}>Rechazar como candidato</small>
                                <br/>
                                <small style={{whiteSpace: 'nowrap'}}>IFA / Aprobar Balanz</small>
                            </button>
                        </div>}
                        <div className="d-inline-block w-20 m-0" id="btn_pre_rechazar_todo">
                            <button
                            type="button"
                            className="w-100 btn red_btn balanz_btn_long small"
                            onClick={() => setRejectModalOpen(true)}>
                                Rechazar como
                                <br/>
                                <small style={{whiteSpace: 'nowrap'}}>candidato IFA {is_balanz < 1 && "- Balanz"} </small>
                            </button>
                        </div>

                    </>
                }

                {/* BOTON GENERAR CONTRATO */}
                {( GROUP.EQ_SOPORTE.includes(user_role) && candidateData.prospect_status === 100) &&
                    <div className="d-inline-block w-23" id="btn_generar_contrato">
                        <button type="button" onClick={() => setGenerateContractOpen(true)} className="w-100 btn green_btn balanz_btn_long">
                            Generar <br/> Contrato
                        </button>
                    </div>
                }
                {/* BOTON REENVIO DE MAIL */}
                { (GROUP.EQ_SOPORTE.includes(user_role) && candidateData.prospect_status === 102) &&
                    <div className="d-inline-block w-23" id="btn_reenvio_mail">
                        <button type="button" onClick={() => setOpenModalSendMail(true)} className="w-100 btn green_btn2 balanz_btn_long">
                            Reenviar email 
                            <br/>
                            de Contrato
                        </button>
                    </div>
                }
                
                {GROUP.EQ_SOPORTE.includes(user_role) && [29,28,49,59].includes(candidateData.prospect_status) &&
                    <div className="d-inline-block w-23" id="btn_reactivate">
                        <button type="button" onClick={() => setReactivateProcess(true)} className="w-100 btn green_btn balanz_btn_long">
                            Reactivar <br/> Proceso
                        </button>
                    </div>
                }

              
                <div className='d-inline-block w-20' id="btn_aprobar_ifa">
                        <button
                            style={{height: '59px' }}
                            className="w-100 btn greenllow_btn balanz_btn_long"
                            aling="left"
                            onClick={ (e) => set_pending( candidateData.id ) }
                            >
                            Ver <br/>más tarde &nbsp;
                        </button>
                    </div>
                </div>

            {/* APROBAR MODAL */}
            <Modal isOpen={ApproveModalOpen} className="" id="to_approve">
                <ModalHeader className="justify-content-end">
                    <button className="close bg-transparent border-0" onClick={() => handleClose()} >
                        <span>&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody className="modal-body">
                    <p className="modal_title green_text">
                        ¿Deseas aprobar al candidato para seguir con el proceso de IFA?
                        <span className="ms-2 name_applicant"><b> { candidateData.complete_name }</b></span>?
                    </p>
                    <p className="modal_text_info blue_title">
                        {/*<span>La información será enviada a Compilance para </span>*/}
                        {/*<span>Continuar el proceso.</span>*/}
                    </p>
                    <div className="row modal_btns">
                        <button
                            type="button"
                            className="btn blue_btn"
                            id="to_approve_email_confirm_btn"
                            onClick={ (e) => set_approve( candidateData.id, nex_status_approved, 'ec_avanzar' ) }
                        >
                            Confirmar
                        </button>
                        <button type="button" className="btn red_btn" id="to_approve_email_cancel_btn" onClick={e => setApproveModalOpen(false)}>
                            Cancelar
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            {/* RECHAZAR MODAL */}
            <Modal isOpen={RejectModalOpen} className="" id="to_reject">
                <ModalHeader className="justify-content-end">
                    <button className="close bg-transparent border-0" onClick={e => handleClose()} >
                        <span>&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody className="modal-body">
                    <p className="modal_title red_text">
                        ¿Quieres rechazar al postulante?:
                        <span className="name_applicant"> { candidateData.complete_name } </span>
                    </p>
                    <p className="modal_text_info blue_title">
                        Por favor, explica el motivo del rechazo:
                    </p>
                    <form action="#">
                        <textarea name="motivo" id="motivo" cols="30" rows="7" value={rejectMsg} onChange={ e => setRejectMsg(e.target.value) }></textarea>

                        <div className="row modal_btns">
                            <button type="button" className="btn blue_btn" id="to_reject_confirm_btn" onClick={() => set_reject(candidateData.id, rejectMsg, 29)}>
                                Confirmar
                            </button>
                            <button type="button" className="btn red_btn" id="to_reject_cancel_btn" onClick={e => handleClose()}>
                                Cancelar
                            </button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
            {/* ENVIAR A OTRA AREA MODAL */}
            <Modal isOpen={sendOtherArea} className="" id="to_reject">
                <ModalHeader className="justify-content-end">
                    <button className="close bg-transparent border-0" onClick={e => handleClose()} >
                        <span>&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody className="modal-body">
                    <p className="modal_title">
                        Se enviara al postulante <span className="name_applicant"> { candidateData.complete_name } </span>
                         a {GROUP.COMPLIANCE.includes(user_role)? " Soporte Comercial" : "Equipo Comercial"}

                    </p>
                    <p className="modal_text_info blue_title">
                        Por favor, explica el motivo del cambio:
                    </p>
                    <form action="#">
                        <textarea name="motivo" id="motivo" cols="30" rows="7" value={sendOtherAreaMsg} onChange={ e => setSendOtherAreaMsg(e.target.value) }></textarea>

                        <div className="row modal_btns">
                            <button type="button" className="btn blue_btn" id="to_reject_confirm_btn" onClick={() =>
                                set_other_area(candidateData.id, sendOtherAreaMsg, candidateData.prospect_status - 10, 'sc_retrocede' )}>
                                Confirmar
                            </button>
                            <button type="button" className="btn red_btn" id="to_reject_cancel_btn" onClick={e => handleClose()}>
                                Cancelar
                            </button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>

            {/* RECHAZAR IFA APROBAR BALANZ MODAL */}

            <Modal isOpen={RejectModal2Open} className="" id="to_reject">
                <ModalHeader className="justify-content-end">
                    <button className="close bg-transparent border-0" onClick={e => setRejectModal2Open(false)} >
                        <span>&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody className="modal-body">
                    <p className="modal_title red_text">
                        ¿Quieres rechazar al postulante {<span className="name_applicant"> { candidateData.complete_name }, </span>} y sugerirle que sea cliente Balanz?


                    </p>
                    <p className="modal_text_info blue_title">
                        Por favor, explica el motivo del rechazo:
                    </p>
                    <form action="#">
                        <textarea name="motivo" id="motivo" cols="30" rows="7" value={rejectMsg2} onChange={ e => setRejectMsg2(e.target.value) }></textarea>

                        <div className="row modal_btns">
                            <button type="button" className="btn blue_btn" id="to_reject_confirm_btn" onClick={() => set_reject(candidateData.id, rejectMsg2, 28)}>
                                Confirmar
                            </button>
                            <button type="button" className="btn red_btn" id="to_reject_cancel_btn" onClick={e => handleClose()}>
                                Cancelar
                            </button>
                        </div>
                    </form>
                </ModalBody>
            </Modal>

            {/* Aprobar ifa balanz MODAL */}
            <Modal isOpen={ApproveModalPreIfa2} className="" id="to_approve">
                <ModalHeader className="justify-content-end">
                    <button className="close bg-transparent border-0" onClick={ () => setApproveModalPreIfa2( false )} >
                        <span>&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody className={working === true ? 'hidden' : ''}>
                    <>
                        <p className="modal_title green_text">
                            ¿Aprobar al postulante:
                            <span className="ms-2 name_applicant"><b> { candidateData.complete_name }</b></span>?
                        </p>

                        <div className="row modal_btns">
                            <button
                                type="button"
                                className="btn blue_btn"
                                id="to_approve_email_confirm_btn"
                                onClick={ () => set_approve( candidateData.id, 30, 'sc_avanza') }
                            >
                                Confirmar
                            </button>
                            <button type="button" className="btn red_btn" onClick={ () => setApproveModalPreIfa2( false )} >
                                Cancelar
                            </button>
                        </div>
                    </>
                </ModalBody>
            </Modal>

            {/* Aprobar pre candidato ifa sin ser balanz MODAL */}
            <Modal isOpen={ApproveModalPreIfa} className="" id="to_approve">
                <ModalHeader className="justify-content-end">
                    <button className="close bg-transparent border-0" onClick={ () => setApproveModalPreIfa( false )} >
                        <span>&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody className={working === true ? 'hidden' : ''}>
                    <>
                        <p className="modal_title green_text">
                            ¿Aprobar al postulante:
                            <span className="ms-2 name_applicant"><b> { candidateData.complete_name }</b></span>?
                            {is_balanz < 1 &&
                                <b className="d-block fw-bold text-danger">¿Sin ser cliente Balanz?</b>
                            }
                        </p>

                        <div className="row modal_btns">
                            <button
                                type="button"
                                className="btn blue_btn"
                                id="to_approve_email_confirm_btn"
                                onClick={ () => set_approve( candidateData.id, 31, 'sc_avanza') }
                            >
                                Confirmar
                            </button>
                            <button type="button" className="btn red_btn" onClick={ () => setApproveModalPreIfa( false )} >
                                Cancelar
                            </button>
                        </div>
                    </>
                </ModalBody>
            </Modal>

             {/* Reactivar Proceso */}
             <Modal isOpen={reactivateProcess} className="" id="to_approve">
                <ModalHeader className="justify-content-end">
                    <button className="close bg-transparent border-0" onClick={ () => setReactivateProcess( false )} >
                        <span>&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody className={working === true ? 'hidden' : ''}>
                    <>
                        <p className="modal_title green_text">
                            ¿Esta Seguro que desea reanudar el proceso del candidato:
                            <span className="ms-2 name_applicant"><b> { candidateData.complete_name }</b></span>?
                        </p>

                        <div className="row modal_btns">
                            <button
                                type="button"
                                className="btn blue_btn"
                                id="to_approve_email_confirm_btn"
                                onClick={ () => set_approve( candidateData.id, reactivate ) }
                            >
                                Confirmar
                            </button>
                            <button type="button" className="btn red_btn" onClick={ () => setReactivateProcess( false )} >
                                Cancelar
                            </button>
                        </div>
                    </>
                </ModalBody>
            </Modal>

            {/* GENERAR CONTRATO MODAL */}
            <Modal isOpen={generateContractOpen} className="" id="to_approve">
                <ModalHeader className="justify-content-end">
                    <button className="close bg-transparent border-0" onClick={ () => setGenerateContractOpen( false )} >
                        <span>&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody className={working === true ? 'hidden' : ''}>
                    <>
                        <p className="modal_title green_text">
                            ¿Está seguro de que quiere generar y enviar el contrato a
                            <span className="ms-2 name_applicant"><b> { candidateData.complete_name }</b></span>?
                        </p>

                        <div className="row modal_btns">
                            <button
                                type="button"
                                className="btn blue_btn"
                                id="to_approve_email_confirm_btn"
                                disabled={loading_btn}
                                onClick={ () => set_approve( candidateData.id, 102 ) }
                            >
                                {loading_btn ? (
                                    <>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <Spinner style={{ 'margin-right': '5px' }} size="sm" animation="border" />
                                            <span>Enviando...</span>
                                        </div>

                                    </>
                                ) : 'Confirmar'}
                            </button>
                            <button type="button" disabled={loading_btn} className="btn red_btn" onClick={ () => setGenerateContractOpen( false )} >
                                Cancelar
                            </button>
                        </div>
                    </>
                </ModalBody>
            </Modal>

            {/* Modal para reenvio de mails */}
            <Modal isOpen={openModalSendMail} className="" id="to_approve">
                <ModalHeader className="justify-content-end">
                    <button className="close bg-transparent border-0" onClick={ () => setOpenModalSendMail(false)} >
                        <span>&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody className={working === true ? 'hidden' : ''}>
                    <>
                        <p className="modal_title green_text">
                            ¿Deseas reenviar  mail del contrato a
                            <span className="ms-2 name_applicant"><b> { candidateData.complete_name }</b></span>?
                        </p>

                        <div className="row modal_btns">
                            <button
                                type="button"
                                className="btn blue_btn"
                                id="to_approve_email_confirm_btn"
                                disabled={loading_btn}
                                onClick={ () => resendMailProspect( candidateData.id,setOpenModalSendMail) }
                            > {loading_btn ? (
                                <>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Spinner style={{ 'margin-right': '5px' }} size="sm" animation="border" />
                                        <span>Enviando...</span>
                                    </div>

                                </>
                            ) : 'Confirmar'}
                        </button>
                        <button type="button" disabled={loading_btn} className="btn red_btn" onClick={ () => setOpenModalSendMail(false)} >
                            Cancelar
                        </button>
                        </div>
                    </>
                </ModalBody>

            </Modal>
        </>
    )
}