import React , { useContext } from 'react';
import {Route, Redirect, useHistory} from 'react-router-dom';
import {UserContext} from "../context/userContext";

function ProtectedRoute({isLogged: isLogged, component: Component, allowedRoles: allowedRoles , ...rest }) {
    let history = useHistory();
    const { userData } = useContext(UserContext);

    const isAuth = allowedRoles.includes(userData? userData.role_id : false);

    /* console.log(`isAuth`, isAuth)
    console.log(`isLogged`, isLogged)
    console.log(`...rest`, rest) */

    return (
        <Route {...rest} render={props => (
            isLogged ? (
                isAuth ? (<Component {...props} />) : (history.goBack())
             ) : (
                <Redirect to={{ pathname: '/admin/login' }} />
            )
        )} />
    );
}

export default  ProtectedRoute;