import React, { Component } from "react";

class FormLogin extends Component {
    render() {
        return (
            <form action="#" className="">
                <div className="col-lg-12" id="title_form">
                    ingrese a su cuenta
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text"><i className="fas fa-user"></i></span>
                    </div>
                    <input type="text" className="form-control" placeholder="Ingrese su nombre de Usuario" id="username" name="username" />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text"><i className="fas fa-lock"></i></span>
                    </div>
                    <input type="text" className="form-control" placeholder="Ingrese su Clave" id="clave" name="clave" />
                </div>
                <button type="submit" id="submit_form" className="btn">
                    ingresar
                </button>
                <div className="col-md-12" id="foot_form">
                    <div className="col-md-8">
                        <a href="#recuperar_usuario" className="me-2">Recuperar Usuario</a>
                        <a href="#recuperar_contraseña">Recuperar Contraseña</a>
                    </div>
                    <div className="col-md-4">
                        <a href="#primer_ingreso">
                            Primer Ingreso
                        </a>
                    </div>
                </div>
            </form>
        );
    }
}
export default FormLogin;