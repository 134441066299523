import React from 'react'
//import './FormStep/FormStep.css'
import PublicHeader from '../includes/PublicHeader/PublicHeader';

const Response = () => {

    return (
        <div className="container-fluid" style={{overflow:'hidden'}}>
            <PublicHeader />

            <div className="container-fluid formstep my-5" id="container_form" style={{backgroundColor: '#fff'}}>
                <div className="title_step text-center my-5">
                    <h2 className="display-6">
                        <span className="fw-normal">Gracias por compartirnos tus datos.</span>
                        <br/>
                        En los próximos días un agente de Balanz se estará comunicándo contigo para avanzar en el proceso
                    </h2>
                </div>
            </div>
        </div>
    )
}

export default Response
