import React, { Component } from "react";
class ResumeNewAcc extends Component {
    render() {
        return (
            <form action="#" className="w-100 mb-4 col-xs-12 col-sm-12" id="form_retomar_apertura">
                <h5 className="my-4">Retomá la apertura de tu cuenta. Ingresá tu Apellido y el Código de Apertura que recibiste en tu e-mail.</h5>
                <div className="form-row">
                    <div className="col-md-6 col-xs-12 col-sm-12 mb-4">
                        <label for="last_name">Apellido</label>
                        <input type="text" className="form-control" placeholder="Ingresá tu apellido" id="last_name" name="last_name" />
                    </div>
                    <div className="col-md-6 col-xs-12 col-sm-12 mb-4">
                        <label for="codigo_apertura">Código de Apertura</label>
                        <input type="text" className="form-control" id="codigo_apertura" placeholder="Ingresá el código de apertura" name="codigo_apertura" />
                    </div>
                </div>

                <div className="form-row justify-content-end border-bottom">
                    <input type="submit" className="btn_balanz btn" value="Continuar" />
                </div>
            </form>
        );
    }
}
export default ResumeNewAcc;