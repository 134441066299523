import React, { useContext, useMemo, useState, useEffect } from 'react'
import { UserContext } from "../../../context/userContext";
import { ListcandidateContext } from "../../../context/ListcandidateContext";
// import { CandidateContext } from '../../../context/CandidateContext';
import { useHistory } from "react-router-dom";
import { useSortBy, useTable } from 'react-table'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { faBook } from '@fortawesome/free-solid-svg-icons'

import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { HistoryActions } from '../DetailsApplicant/includes-details/HistoryActions';
import {EditAnalystModal} from '../DetailsApplicant/includes-details/EditAnalystModal';
import canEditAnalyst from '../../../helpers/common';

export const TableCandidatesIfa = () => {
    let history = useHistory();

    const { userData } = useContext(UserContext)
    const { listCandidateData } = useContext(ListcandidateContext)
    const [ working, setWorking ] = useState( false )
    const [historyModal, setHistoryModal] = useState(false)
    const [candidateHistory, setcandidateHistory] = useState(null)
    const [analystModal, setAnalystModal] = useState(false)
    const [analyst, setAnalyst] = useState(null)
    const [analystSelect, setAnalystSelect] = useState('')
    const handleOpen = (candidate) => {
        setHistoryModal(true);
        setcandidateHistory(candidate);
    }
    const changeInput = (row) => {
      const canEdit = canEditAnalyst(row, userData.role_id)

        if(canEdit) {
            setAnalystModal(true);
            setAnalyst(row.id);
            setAnalystSelect(row.analyst_value);
        }
        else {
            return false;
        }
    }
    /** set color for status settled by team
     * case 1 = verde - APPROVE
     * case 2 = rojo - REJECT
     * case 3 = naranja verdoso - PENDING
     */


    const setColorStatus = (status) => {
        let text_center = ""
        switch(status) {
            case 1:
                return " green_success"

            case 2:
                return " red_text"

            case 3:
                return " greenllow_text"

            default:
                return text_center
        }
    }

    /** set text status by team role
     * case 1 = Aprobado
     * case 2 = Rechazado
     * case 3 = pendiente
     */
    const setTextStatus = (status) => {
        switch(status) {
            case 1:
                return "aprobado"
            case 2:
                return "rechazado"
            case 3:
                return "pendiente"
            default:
                return ""
        }
    }

    /* check if cell is a team name and get value settled by team to set color at text data */
    const handleSetClass = (cell) => {
        if(cell.column.id === "eq_comercial") {
            return setColorStatus(cell.row.values.eq_comercial)

        } else if(cell.column.id === "eq_sop_comercial") {
            return setColorStatus(cell.row.values.eq_sop_comercial)

        } else if(cell.column.id === "eq_compliance") {
            return setColorStatus(cell.row.values.eq_compliance)
        }
    }

    /* COMPRUEBA CUAL DE ROLE Y OBTIENE EL VALOR PARA SETEAR EL STATUS CORRESPONDIENTE */
    const handleSetText = (cell) => {
        if(cell.column.id === "eq_comercial") {
            return setTextStatus(cell.row.values.eq_comercial)

        } else if(cell.column.id === "eq_sop_comercial") {
            return setTextStatus(cell.row.values.eq_sop_comercial)

        } else if(cell.column.id === "eq_compliance") {
            return setTextStatus(cell.row.values.eq_compliance)
        }
    }

    /* SETEA EL TEXTO DE ESTADO EN EL QUE SE ENCUENTRA EL CANDIDATO SEGUN SU CODIGO DE ESTATUS */
    const setApplicantStatus = (status) => {
        let result = "";
        switch(status) {
            case 0:
                result = "rechazado "
                result = status
                break;

            case 20:
                case 21: case 22: case 23: case 24: case 25: case 26: case 27: case 28: case 29:
                result = "pre candidato "
                //result = status
                break;

            case 30:
                case 31: case 32: case 33: case 34: case 35: case 36: case 37: case 38: case 39:
                result = "Equipo Comercial "
                //result = status
                break;

            case 40:
                case 41: case 42: case 43: case 44: case 45: case 46: case 47: case 48: case 49:
                result = "Soporte Comercial "
                //result = status
                break;

            case 50:
                case 51: case 52: case 53: case 54: case 55: case 56: case 57: case 58: case 59:
                result = "Compliance "
                //result = status
                break;

            case 100:
                result = "Pendiente envío contrato "
                //result = status
                break;

            case 102:
                result = "Pendiente firma contrato "
                //result = status
                break;

            case 200:
                result = "Firma contrato "
                //result = status
                break;

            case 300:
                result = "Finalizado "
                //result = status
                break;

            default:
                result = status
                break;
        }
        return result
    }

    const handleClick = async ( row ) => {
        setWorking( true )

        history.push({
            pathname: '/admin/details',
            search: '',
            state: { candidato_id: row.id, is_balanz: row.is_balanz }
        })
    }

    /* GET BODY ROWS  */
    const data =  useMemo( () => listCandidateData );
    /* DEF HEADERS COLS */
    const compareDateString = (rowA, rowB, id, desc) => {
        let a = (rowA.original.application_date).split('/');
        let b = (rowB.original.application_date).split('/');
        a = new Date(a[1]+('/')+a[0]+('/')+a[2])
        b = new Date(b[1]+('/')+b[0]+('/')+b[2])
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
    }
    const compareDateStrinCompliance = (rowA, rowB, id, desc) => {
        if(rowA.original.compliance_date === null) return 1
        if(rowB.original.compliance_date === null) return -1

        if(rowA.original.compliance_date != null && rowB.original.compliance_date != null) {
        let a = (rowA.original.compliance_date).split('/');
        let b = (rowB.original.compliance_date).split('/');
        a = new Date(a[1]+('/')+a[0]+('/')+a[2])
        b = new Date(b[1]+('/')+b[0]+('/')+b[2])
        if (a > b) return 1;
        if (a < b) return -1;
        }

        return 0;
    }
    const columns = useMemo(
        () => [
            {Header: 'nombre y apellido', accessor: 'complete_name',},
            {Header: 'fecha postulación',accessor: 'application_date', sortType:compareDateString},
            {Header: 'equipo comercial',accessor: 'status_commercial_team',},
            {Header: 'Asesor',accessor: 'adviser_username',},
            {Header: 'soporte comercial',accessor: 'status_commercial_support',},
            {Header: 'compliance',accessor: 'status_compliance',},
            {Header: 'estado',accessor: 'prospect_status',},
            {Header: 'Analista', accessor: 'analyst_value',},
            {Header: 'Fecha pase a compliance',accessor: 'compliance_date', sortType: compareDateStrinCompliance},
            {Header: 'Contrato', accessor: 'contract_short_name',},
            {Header: 'cliente balanz', accessor: 'is_balanz',},
            {Header: <FontAwesomeIcon icon={faEye} /> ,accessor: 'visto',},
            {Header: <FontAwesomeIcon icon={faBook} />,accessor: 'historial',},
            //{Header: 'status' ,accessor: 'prospect_status',},
        ],
        []
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data }, useSortBy)

    /**
     *  HABILITAR lA VISTA DE LAS FILAS SEGUN SEA EL ESTADO DEL CANDIDATO Y EL ROLE DEL USUARIO
     * */
    const abbleToSeeRow = (row) => {
        /* console.log(`row`, role) */
        let $return = "";

        const status = Number(row.prospect_status)
        const current_role = Number(userData.role_id) // curren user role

        /** SI EL USUARIO ES SOPORTE COMERCIAL Y EL STATUS DEL CANDIDATO ESTÉ EN TURNO DE EQUIPO COMERCIAL */
        if ( current_role === 2 && status < 39 ) {
            $return = "none";

        /** SI EL USUARIO ES COMPLIANCE Y EL STATUS DEL CANDIDATO ESTÉ EN TURNO DE SOPORTE COMERCIAL */
        } else if ( current_role === 3 && status < 49 ){
            $return = "none";
        }

        return $return
    }
    //(role === 1 && row.prospect_status < 30 )? 'visible': 'hidden';

    /* SET [ SI O NO ] ES CLIENTE IFA */
    const setInfoCli = (info_cli) => {
        if(info_cli === 1) {
            return "Si"
        } else {
            return "No"
        }
    }

        // useEffect(() => {
        //     setValue(analystValue)
        //   }, [])
        //   return (
        //     <input
        //       value={value}
        //       onChange={e => setValue(e.target.value)}
        //     />
        //   )
    return (
        <>
            <table {...getTableProps()} className="table">
                <thead className="bg_thead blue_thead_label">
                    {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                        <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                        >
                            {column.render('Header')}
                            <span>
                                {column.isSorted
                                ? column.isSortedDesc
                                    ? ' 🔽'
                                    : ' 🔼'
                                : ''}
                            </span>
                        </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} className="blue_data">
                    {rows.map(row => {
                    prepareRow(row)
                    return (
                        <tr
                            {...row.getRowProps()}
                            onClick={()=> working ? '' : handleClick(row.original) }
                            className={(row.values.visto > 0) ? 'checked' : 'unchecked'}
                            role={ working ? '' : 'button'}
                            style={{display: abbleToSeeRow(row.original)}}
                        >

                        {row.cells.map(cell => {
                            return (
                                <td
                                    {...cell.getCellProps()}
                                    className={
                                        (
                                            cell.column.id === "status_commercial_team" ||
                                            cell.column.id === "status_commercial_support" ||
                                            cell.column.id === "status_compliance"
                                        ) ? handleSetClass(cell)
                                        : (cell.column.id === "adviser_username") ? 'adviser_username'
                                        : (cell.column.id === "application_date") ? 'date'
                                        : (cell.column.id === "complete_name") ? 'complete_name'
                                        : ''
                                    }
                                >
                                    { /* console.log(cell) */ }
                                    {
                                        (  // compara cada celda para buscar los tatus de los equipos
                                            // cell.column.id === "status_commercial_team" ||
                                            // cell.column.id === "status_commercial_support" ||
                                            // cell.column.id === "status_compliance"
                                            false
                                        ) ? handleSetText(cell) // si es alguna de ellas, setea el texto [aprovado, pendiente, rechazado ]
                                            : (cell.column.id === "prospect_status") ? setApplicantStatus(cell.row.values.prospect_status)
                                            : (cell.column.id === "is_balanz") ? setInfoCli(cell.row.values.is_balanz)
                                            : ( cell.column.id === "visto") ? <FontAwesomeIcon icon={faEye} />
                                            : (cell.column.id === "analyst_value" )  ? <span style={{width:'100%',display:'block',height:'100%', padding:'14px 0'}} onClick={(e) => {e.stopPropagation();changeInput(row.original)} }> {cell.row.values.analyst_value} </span>
                                            : ( cell.column.id === "historial") ? <FontAwesomeIcon icon={faBook} onClick={(e) => {e.stopPropagation();  handleOpen(row.original.id)}}/>
                                            : cell.render('Cell') // en caso false, solo muestra el texto correspondiente
                                    }
                                </td>
                            )
                        })}
                        </tr>
                    )
                    })}
                </tbody>
            </table>
            <Modal isOpen={historyModal} className="" id="to_approve">
                <ModalHeader className="justify-content-end">
                    <button className="close bg-transparent border-0" onClick={ () => setHistoryModal( false )} >
                        <span style={{color:"black"}}>&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody className={working === true ? 'hidden' : ''}>
                    <>
                        <HistoryActions prospectsId={candidateHistory} />
                    </>
                </ModalBody>
            </Modal>

            <Modal isOpen={analystModal} className="" id="to_approve">
                <ModalHeader className="justify-content-end">
                <button className="close bg-transparent border-0" onClick={ () => setAnalystModal( false )} >
                        <span style={{color:"black"}}>&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody className="modal-body">
                    <p className="modal_title green_text">
                        ¿Deseas cambia el analista asignado?
                    </p>
                     <EditAnalystModal
                     analyst={analyst}
                     analystSelect={analystSelect}
                     setAnalystModal={setAnalystModal}
                     />
                </ModalBody>
            </Modal>
        </>
    )
}
