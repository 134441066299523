import React, { Component } from "react";
import '../../../assets/css/general.css';
import '../../../assets/css/modales.css';
import FormNewAcc from "./CreateNewAcc-parts/FormNewAcc";
import Header from "./CreateNewAcc-parts/Header";
import ResumeNewAcc from "./CreateNewAcc-parts/ResumeNewAcc";
import Tabs from "./CreateNewAcc-parts/Tabs";
import './CreateNewAcc.css';

import plataforma_digital from "../../../assets/images/icon/plataforma_digital.png";
import operaciones_online from "../../../assets/images/icon/operaciones_online.png";
import asesoramiento_experto from "../../../assets/images/icon/asesoramiento_experto.png";
import informacion_oportuna from "../../../assets/images/icon/informacion_oportuna.png";
import logo_sid from "../../../assets/images/footer/old/logo_sid.png";

class CreateNewAcc extends Component {
    render() {
    return (
        <div className="container" id="new_acc_container_fluid">
            <Header></Header>

            <div className="row">
                <h5 className="mt-2 mb-2 col-xs-12 col-sm-12">¡Estás a un paso de invertir mejor! Abrí tu cuenta en Balanz con el nuevo proceso de apertura.</h5>
                <div className="w-100 col-xs-12 col-sm-12" id="iniciar_retomar">
                    <Tabs></Tabs>
                </div>
            </div>

            <div className="tab-content mt-4">
                <div id="iniciar_acc" className="tab-pane active">
                    <FormNewAcc></FormNewAcc>
                </div>
                
                <div id="retomar_acc" className="tab-pane">
                    <ResumeNewAcc></ResumeNewAcc>
                </div>
            </div>
            <div className="row mt-4">
                <div className="row my-4 mx-auto col-xs-12 col-md-12" id="foot_new_acc">
                    <div className="col-md-6" id="col_video_apertura">
                        <h5 className="mb-4">Nunca fue tan fácil Abrir Cuenta</h5>
                        <iframe className="video_apertura col-xs-12 col-sm-12 mb-4" width="560" height="315" src="https://www.youtube.com/embed/kHYYfNjm9qg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div className="col-md-6" id="col_beneficios">
                        <h5 className="mb-4">Contá con los siguientes Beneficios</h5>
                        <div className="row">
                            <div className="icon col-md-6">
                                <img src={ plataforma_digital } alt="Plataforma Digital" />
                                <h4 className="text_balanz">Plataforma Digital</h4>
                                <p>
                                    Consultá tu posición consolidada e historial las 24hs del día.
                                </p>
                            </div>

                            <div className="icon col-md-6">
                                <img src={ operaciones_online } alt="Operaciones Online" />
                                <h4 className="text_balanz">Operaciones Online</h4>
                                <p>
                                    Consultá tu posición consolidada e historial las 24hs del día.
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="icon col-md-6">
                                <img src={ asesoramiento_experto } alt="Asesoramiento Experto" />
                                <h4 className="text_balanz">Asesoramiento Experto</h4>
                                <p>
                                    Atención exclusiva de un asesor financiero para acercarte mejores propuestas.
                                </p>
                            </div>
                            <div className="icon col-md-6">
                                <img src={ informacion_oportuna } alt="Información Oportuna" />
                                <h4 className="text_balanz">Información Oportuna</h4>
                                <p>
                                    De nuestro equipo de Research para ayudarte a tomar las decisiones.
                                </p>
                            </div>
                        </div>
                        <div className="row my-4 justify-content-center" id="logo_sid">
                            <a href="https://www.argentina.gob.ar/sid-sistema-de-identidad-digital" target="_blank" rel="noreferrer" style={{width: "auto"}}>
                                <img src={ logo_sid } alt="SID - Sistema de Identidad Digital" />
                            </a>
                            <span className="ms-4" style={{width: "auto"}}>Información consultada RENAPER</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    }
}
export default CreateNewAcc;