import React, { Component } from "react";
class Tabs extends Component {
    render() {
    return (
        <ul className="nav nav-tabs d-sm-inline-flex justify-content-around mt-3 mb-3" id="myTab" role="tablist">
            <li className="nav-item col-6 p-0">
                <a href="#iniciar_acc" className="nav-link text-center active" data-toggle="tab" role="tab">INICIAR Apertura de Cuenta</a>
            </li>
            <li className="col-6 p-0">
                <a href="#retomar_acc" className="nav-link text-center" data-toggle="tab" role="tab">RETOMAR Apertura de Cuenta</a>
            </li>
        </ul>
    );
    }
}
export default Tabs;