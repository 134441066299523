import {useContext, useEffect, useState} from 'react';
import './Login.css'
import { useHistory } from 'react-router-dom'
import {API, setToken} from '../../../http-common';
import {UserContext} from "../../../context/userContext";

const LoginCallback = (req) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const history = useHistory()
  const {setUserData, setIsLogged} = useContext(UserContext)

  useEffect(() => {
    const params = new URLSearchParams(req.location.search);
    const user = params.get('user');
    const nonce = params.get('nonce');

    API.post('/auth/login', {user, nonce})
      .then(function (response) {
        setToken(response.data.accessToken);
        setUserData(response.data.data);
        setIsLogged(true);
        history.push('/admin');
      })
      .catch(e => {
        setLoading(false);
        setError(true);
      })
  }, []);

  return (
    <div id="login_container_fluid" className="content-login">
      <div className="loading-wrapper">
        {loading && (
          <>
            <div className="loading"></div>
            <div className='content'>
                <h3>Iniciando sesión</h3>
            </div>
          </>
        )}

        {!loading && error &&  (
          <>
            <div className='content'>
                <h3>Ocurrió un error al iniciar sesión</h3>
                <div className='text-center mt-3'>
                  <button type="button" className="btn-ingresar" onClick={() => {
                    history.push('/admin/login')
                  }}>Reintentar</button>
                </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
};

export default LoginCallback;
