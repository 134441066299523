import React, { useEffect, useContext, useMemo, useState } from 'react';
import { UserContext } from "../../context/userContext";
import { ListcandidateContext } from "../../context/ListcandidateContext";

import '../../assets/css/candidates_for.css';

import Header from './includes_admin/Header';
import { Filters } from './includes_admin/Filters';
import { TableCandidatesIfa } from './TableCandidatesIfa/TableCandidatesIfa';

import { API } from '../../http-common';
import {
    GRUPO_EQ_COMERCIAL,
    ROLE_COMPLIANCE,
    ROLE_SOP_COMERCIAL,
    GROUP
} from "../../constants";


const Admin = () =>  {
    const { userData } = useContext( UserContext )

    const [errorApi, setErrorApi] = useState('Esperando datos...')

    const [listCandidateData, setListCandidateData] = useState([])
    const [originalList, setOriginalList] = useState ([]);
    const ProviderListCandidateData = useMemo(() => ({listCandidateData, setListCandidateData}), [listCandidateData, setListCandidateData]);

    const [filterBy, setFilterBy] = useState('');

    useEffect(() => {
        const getListProspect = async () => {
            await API.get(`/prospects/list`)
            .then(function (response) {
                const data = response.data.data;
                if(data && data.length > 0){
                    setErrorApi('');
                    setListCandidateData( data );
                    setOriginalList(data)

                    addListClass()

                }else{
                    setErrorApi('No hay datos...')
                }
            })
            .catch( function(error) {
                setErrorApi( error.response )
            })
        }

        getListProspect()

        /*pending.forEach((item) =>{
            if(item.children){
                console.log("this is it")
            }
        })*/

    }, [])

    const cleanListClass = (data) => {
        data.forEach(element => {
            element.setAttribute('class', 'unchecked')
        })
    }
    const addListClass = () => {
        const pending = document.querySelectorAll(".unchecked")
        cleanListClass(pending)
        pending.forEach(element => {

            if(element.children[6].innerHTML.includes("PENDIENTE FIRMA")){
                // element.classList.add("pending")
            }
            else if(element.children[6].innerHTML.includes("CONTRATO RECHAZADO")){
                // element.classList.add("user-rejected")
            }
            else if(element.children[6].innerHTML.includes("CONTRATO FIRMADO")){
                // element.classList.add("finish")
            }
            else if(element.children[6].innerHTML.includes("PENDIENTE ENVIO CONTRATO")) {
                // element.classList.add("pending-contract")
            } else if (element.children[2].innerHTML.includes("APROBADO") && GROUP.EQ_DIRECTORES.includes(userData.role_id)) {
                // element.classList.add("approved")
            } else if (element.children[4].innerHTML.includes("APROBADO") && GROUP.EQ_SOPORTE.includes(userData.role_id)) {
                // element.classList.add("approved")
            } else if (element.children[5].innerHTML.includes("APROBADO") && GROUP.COMPLIANCE.includes(userData.role_id)) {
                // element.classList.add("approved")
            }

            if( element.children[6].innerHTML.includes("RECHAZADO")||
                element.children[5].innerHTML.includes("RECHAZADO")||
                element.children[4].innerHTML.includes("RECHAZADO")||
                element.children[2].innerHTML.includes("RECHAZADO")) {
                    // element.classList.add("rejected")
            }
        });
    }

    const handleSelect = async (e) => {
        let option_selected = e.target.value;
        if(e.target.value === "") {
            setListCandidateData(originalList)
            setFilterBy(option_selected);
        } else {
            setListCandidateData(originalList.filter(item => item.prospect_status.includes(option_selected)))
            setFilterBy(option_selected);
        }
        setTimeout(() => {
            addListClass()
        }, 1)

    }

    const searchNameOrAdviser = (nameOrAdviser) => {
        let filteredList = []
        if(nameOrAdviser === ""){
            setListCandidateData(originalList)
        }
        else{
            originalList.forEach(item => {
                if( item.complete_name.toLowerCase().includes(nameOrAdviser.toLowerCase())
                    || item.adviser_username.toLowerCase().includes(nameOrAdviser.toLowerCase())){
                    console.log(item.complete_name)
                    filteredList.push(item)
                }
                setListCandidateData(filteredList)
            })
        }
    }

    return (

        <ListcandidateContext.Provider value={ ProviderListCandidateData } >
            <div className="container-fluid bg_light_gray" id="applicant_container_fluid">
                <div className="row mb-4">
                    <Header />
                </div>
                <div className="row banner_section">
                    <span className="title_section balanz_title">Candidatos a IFA</span>
                </div>

                <Filters changeSelectFilter={ handleSelect } filtered={ filterBy } searchNameOrAdviser={searchNameOrAdviser}/>

                <div className="row container_table_candidatos" id="container_table">
                    <div className="table-responsive">
                        <>
                            {listCandidateData.length > 0 ?
                                <TableCandidatesIfa />
                                :
                                <p>
                                    { errorApi }
                                </p>
                            }
                        </>
                    </div>
                </div>

            </div>
        </ListcandidateContext.Provider>
    );
}

export default Admin;