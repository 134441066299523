import React, { Component } from "react";
import header_apertura from '../../../../assets/images/header/header_apertura.jpg';

class Header extends Component {
    render() {
    return (
        <header className="row mb-4 border-bottom">
            <img src={ header_apertura } alt="balanz logo apertura de cuenta" className="" />
        </header>
    );
    }
}
export default Header;
