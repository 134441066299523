export const API_URL = "https://ifa.balanz.com/api/v1";
export const PROSPECT_FORM_URL = "https://balanz.com/independent-financial-advisor/";

export const LEGAJO_COMITENTE_URL = "https://users.balanz.com/backoffice/legajosv2?comitente="
export const LEGAJO_CUOTAPARTISTA_URL = "https://users.balanz.com/backoffice/legajosv2?cuotapartista="


export const TOKEN_KEY = 'BLNZ_IFA_TOKEN';

export const ROLE_EQ_COMERCIAL = 1;
export const ROLE_EQ_COMERCIAL_LIDER = 15;
export const ROLE_EQ_COMERCIAL_ASISTENTE = 5;
export const ROLE_SOP_COMERCIAL = 2;
export const ROLE_COMPLIANCE = 3;
export const ROLE_ALL = 4;
export const ROLE_AMALIA = 6;
export const ROLE_ASISTENTE_EMBAJADOR = 7;

export const GRUPO_EQ_COMERCIAL = [ROLE_EQ_COMERCIAL, ROLE_EQ_COMERCIAL_LIDER, ROLE_EQ_COMERCIAL_ASISTENTE,ROLE_AMALIA,ROLE_ASISTENTE_EMBAJADOR];
export const EQ_COMERCIAL = [ROLE_EQ_COMERCIAL, ROLE_ALL];
export const EQ_COMERCIAL_LIDER = [ROLE_EQ_COMERCIAL, ROLE_EQ_COMERCIAL_LIDER, ROLE_EQ_COMERCIAL_ASISTENTE, ROLE_ALL,ROLE_AMALIA,ROLE_ASISTENTE_EMBAJADOR];
export const SOP_COMERCIA = [ROLE_SOP_COMERCIAL, ROLE_ALL, ROLE_AMALIA,ROLE_ASISTENTE_EMBAJADOR];
export const COMPLIANCE = [ROLE_COMPLIANCE, ROLE_ALL];

/**
 * Esta constante contiene todos los roles posibles, actualmente se usa en las rutas protegidas de /admin
 * para controlar el acceso a esas pantallas.
 * Actualizar esta constante cuando se agreguen nuevos roles
 */
export const AllRolesList = [ROLE_EQ_COMERCIAL, ROLE_EQ_COMERCIAL_LIDER, ROLE_EQ_COMERCIAL_ASISTENTE, ROLE_SOP_COMERCIAL, ROLE_COMPLIANCE, ROLE_ALL, ROLE_AMALIA,ROLE_ASISTENTE_EMBAJADOR];

// Permissions to see, edit and not see
export const GROUP = {
    EQ_DIRECTORES: [ROLE_EQ_COMERCIAL, ROLE_EQ_COMERCIAL_LIDER, ROLE_EQ_COMERCIAL_ASISTENTE, ROLE_AMALIA, ROLE_ALL,ROLE_ASISTENTE_EMBAJADOR],
    EQ_ASISTENTES: [ROLE_EQ_COMERCIAL_ASISTENTE,ROLE_ASISTENTE_EMBAJADOR],
    EQ_SOPORTE: [ROLE_SOP_COMERCIAL, ROLE_AMALIA,ROLE_ASISTENTE_EMBAJADOR],
    COMPLIANCE: [ROLE_COMPLIANCE],
    TODOS_LOS_EQUIPOS: [ROLE_ALL, ROLE_AMALIA,ROLE_ASISTENTE_EMBAJADOR]
}
export const PROSPECT_STATUS = {
    PRE_CANDIDATO: 1, //Este rol tambien se da si rebota soporte comercial
    CONTACTADO: 3, // Contactado por equipo comercial
    EC_IFA_Y_BLZ: 30, //APROBADO IFA Y BALANZ
    EC_SOLO_IFA: 31,  //APROBADO SOLO IFA
    EC_SOLO_BLZ: 28,  //APROBADO SOLO BALANZ 
    EC_DESCARTADO: 29, // rechazado por equipo comercial
    SC_CANDIDATO: 40, // pasa a soporte comercial
    SC_CANDIDATO_REGRESADO:40, // regresa de compliance
    EC_DESCARTADO_BLZ: 29, // rechazado por equipo comercial
    SC_DESCARTADO: 49, // Rechazado por soporte comercial
    CMP_CANDIDATO: 50, // aprobado por soporte comercial
    CMP_RECHAZADO:59, // Rechazado por Compliance
    SC_APROBADO_COMPLIANCE: 100, // Aprobado por Compliance
    SC_CONTRATO_PENDIENTE: 102, // Generar Contrato
    SC_CONTRATO: 200, //Productor o Referenciador
}
export const GRUPO_STATUS ={
    EQ_COMERCIAL:[PROSPECT_STATUS.PRE_CANDIDATO, PROSPECT_STATUS.EC_IFA_Y_BLZ, PROSPECT_STATUS.EC_SOLO_IFA, PROSPECT_STATUS.EC_SOLO_BLZ],
    EQ_SOPORTE:[PROSPECT_STATUS.SC_CANDIDATO]
}
export const NOT_SEE = 0;
export const SEE = 1;
export const EDIT = 2;

// SIZE FILE

export const FILE_SIZE_LIMIT = 25

export const TYPECONTRACTIFAPE = {
    PRODUCTORIFAPE:6,
    REFERENCIADORIFAPE:5
}