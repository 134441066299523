import React, { Component } from "react";
import FormLogin from "./Login-parts/FormLogin";

import './PublicLogin.css';

import logo_balanz from '../../../assets/images/header/logo_balanz.png';
import disponible_google_play from '../../../assets/images/icon/disponible_google_play.png';
import consiguelo_app_store from '../../../assets/images/icon/consiguelo_app_store.svg';
import balanz_footer from '../../../assets/images/footer/balanz-footer.jpg';

class PublicLogin extends Component {
    render() {
        return (
            <>
                <div className="container-fluid" id="public_login_container_fluid">
                    <header className="row" id="topnav">
                        <img src={ logo_balanz } alt="balanz logo" />
                    </header>

                    <div className="row" id="container_form">
                        <div className="container">
                            <FormLogin />

                            <div className="row justify-content-between" id="images_stores">
                                <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.mobile.balanz.droid&amp;pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                                    <img height="80px" alt="Disponible en Google Play" src={ disponible_google_play } />
                                </a>
                                <a target="_blank" rel="noreferrer" href="https://itunes.apple.com/us/app/balanz/id1318206099?mt=8">
                                    <img className="my-4 mx-0" height="55" alt="Consiguelo en el App Store" src={ consiguelo_app_store } />
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-12" id="alerta_apertura">
                            <div className="alert alert-primary d-flex justify-content-between">
                                <strong>Si aún no tienes tu cuenta, iniciá tu apertura</strong>
                                <a href="create_acc.html" className="btn" id="new_acc">abrir cuenta</a>
                            </div>
                        </div>
                    </div>

                    <footer className="row mt-4">
                        <div className="d-flex col-lg-12">
                            <div className="col-md-6 d-flex justify-content-around">
                                {/* <ul className="d-flex">
                                    <li><i className="fab fa-twitter"></i></li>
                                    <li><i className="fab fa-linkedin-in"></i></li>
                                    <li><i className="fab fa-facebook-f"></i></li>
                                    <li><i className="fab fa-instagram"></i></li>
                                </ul> */}
                                <div className="social d-flex">
                                    <a className="social-twitter" data-original-title="Twitter Balanz Capital" data-placement="bottom" data-toggle="tooltip" href="https://twitter.com/BalanzCapital" target="blank" title="Twitter Balanz Capital">
                                        <i className="fab fa-twitter">­</i>
                                    </a>

                                    <a className="social-linkedin" data-original-title="Linkedin Balanz Capital" data-placement="bottom" data-toggle="tooltip" href="https://www.linkedin.com/company/balanz-capital/" target="blank" title="Linkedin Balanz Capital">
                                        <i className="fab fa-linkedin-in">­</i>
                                    </a>

                                    <a className="social-facebook" data-original-title="Facebook Balanz Capital" data-placement="bottom" data-toggle="tooltip" href="https://www.facebook.com/balanzcapital/" target="blank" title="Facebook Balanz Capital">
                                        <i className="fab fa-facebook-f">­</i>
                                    </a>
                                    <a className="social-instagram" data-original-title="Instagram Balanz Capital" data-placement="bottom" data-toggle="tooltip" href="https://www.instagram.com/balanzcapital/" target="blank" title="Instagram Balanz Capital">
                                        <i className="fab fa-instagram">­</i>
                                    </a>
                                </div>
                                <p>
                                    <i className="fa fa-map-marker-alt text-white"></i>
                                    <span>
                                        Av. Corrientes 316, Piso 3<br/>
                                        Ciudad Autónoma de Buens Aires, C1043AAQ<br/>
                                        Argentina
                                    </span>
                                </p>
                            </div>
                            <div className="col-md-6 d-flex justify-content-end">
                                <a href="#balanz">
                                    <img src={ balanz_footer } alt="balanz footer" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-12 d-flex justify-content-end">
                            <span>
                                &copy; 2017 Balanz Capital
                            </span>
                        </div>
                    </footer>
                </div>

                <hr id="last_line" />
            </>
        );
    }
}

export default PublicLogin;