import React, { useEffect, useState, useMemo } from 'react';

/* CONSTANTS */
import * as Constants from './constants'

/* ROUTE */
import {Route, useHistory, Switch} from 'react-router-dom';
import ProtectedRoute from './private/protectedRoute'

/* CONTEXT */
import { UserContext } from './context/userContext';

/* import { DataProvider } from "./context/dataContext"; */

/* API */
import { API, setToken, deleteToken, getToken, initAxiosInterceptors } from './http-common';

import { useSnackbar } from 'react-simple-snackbar'

import Loading from './private/components/Loading/Loading';

/* PUBLIC PAGES */
import Home from './public/components/HomePublic/Home';
import PublicLogin from './public/components/PublicLogin/PublicLogin';
import FormRrss from './public/components/FormRrss/FormRrss';
import Response from './public/components/FormRrss/Response';
import Email from './private/components/Email';
import Agreement from "./public/components/Agreement/Agreement";
import CreateNewAcc from './public/components/CreateNewAcc/CreateNewAcc';
import AgreementComplete from './public/components/Agreement/AgreementComplete';

/* PAGES ADMIN */
import Login from './private/components/Login/Login';
import Admin from './private/components/Admin';
import { DetailsApplicant } from './private/components/DetailsApplicant/DetailsApplicant';
import LoginCallback from './private/components/Login/login-callback';

initAxiosInterceptors();

const App = () => {

    const [authUrl, setAuthUrl] = useState('/auth/init');
    const [authType, setAuthType] = useState(null);
    const [defaultUser, setDefaultUser] = useState("")
    const [nonce, setNonce] = useState('');

    let history = useHistory();

    const [error, setError] = useState(null);
    const [working, setWorking] = useState(false)

    const [isLogged, setIsLogged] = useState(null);

    const [userData, setUserData] = useState('');
    const ProviderUserData = useMemo(() => ({userData, setUserData, setIsLogged}), [userData, setUserData, setIsLogged]);

    const options = {
        position: 'bottom-center',
        style: {
            backgroundColor: 'var(--bs-red)',
            border: '0',
            color: '#fff',
            fontSize: '20px',
            textAlign: 'center',
            marginBottom: '5rem',
        },
        closeStyle: {
            color: '#fff',
            fontSize: '16px',
            marginRight: '1rem',
        },
    }

    const [openSnackbar] = useSnackbar(options)

    function showError(message) {
        setError(message);
    }

    const backAction = () => {
        setAuthType(null)
        setNonce(null)
        setAuthUrl('/auth/init')
    }

    async function login(user, password) {

        if(authType === null) {
            // testExternal: 1
            API.post(authUrl, {user: user })
            .then(function (response) {
                const data = response.data
                if([0, 1].includes(data.tipoAutenticacion)){
                    setAuthType(data.tipoAutenticacion)
                    setAuthUrl('/auth/login')
                    setDefaultUser(data.user)
                    setNonce(data.nonce)
                    setWorking(false)
                }else{
                    // data.URLAuth
                    window.location.href = data.URLAuth;
                }
            })
            .catch(e => {
                setWorking(false);
                openSnackbar(e.response.data.message)
            })

        }

        if([0,1].includes(authType)) {


            const data = {
                user: user,
                pass: password,
                nonce: nonce
            }

            API.post(authUrl, data)
            .then(function (response) {
                setToken(response.data.accessToken);
                setUserData(response.data.data);
                setIsLogged(true);
                setAuthType(null)
                setNonce(null)
                setAuthUrl('/auth/init')
                setWorking(false)
                history.push("/admin");
            })
            .catch(async (e) => {
                openSnackbar(e.response.data.message)
                setIsLogged(false)
                setWorking(false)

                const newInstance = await API.post('/auth/init', {user: user });
                setNonce(newInstance.data.nonce)
            })
        }

        /* API.post('/user/login', {user, password})
            .then(function (response) {

                setToken(response.data.accessToken);
                setUserData(response.data.user);
                setIsLogged(true);
                history.push("/admin");

                setWorking( false )
            })
            .catch( function(error) {
                console.log(`error.response`, error)
                openSnackbar('Usuario o contraseña incorrecta');
                setWorking( false )
            }) */
    }

    const loadUser = async () => {
        if (!getToken()) {
            setUserData(null);
            setIsLogged(false);
            return;
        }
        try {
            const resp = await API.get('/user/whoami');
            if (resp.data.user) {
                setUserData(resp.data.user);
                setIsLogged(true)

            } else {
                setIsLogged(false)
            }

        } catch (error) {
            console.log(`catch loadUser `)
            deleteToken();
            setUserData(null);
            setIsLogged(false);
        }
    }

    const ProspectFormRedirect = () => {
        window.location.replace(Constants.PROSPECT_FORM_URL);
        return null;
    }

    // Se llama cada vez que el componente hace render
    useEffect(() => {
        loadUser();
    }, []);

    if (isLogged === null) {
        return <Loading />

    } else {
        return (
            <>
                <Switch>
                    {/* HOME PUBLIC */}
                    <Route exact path={['/', '/home']} component={ProspectFormRedirect} />
                    <Route exact path="/test-formulario-home" component={ Home } />

                    {/* LOGIN AND CREATE BALANZ ACCOUNT */}
                    <Route exact path="/login" component={ PublicLogin } />
                    <Route exact path="/create" component={ CreateNewAcc } />

                    {/* GENERA/SIGN CONTRACT FOR POSTULANT */}
                    {/* <Route exact path="/contract" component={ GeneraContract } /> */}

                    {/* RUTAS PUBLICAS PARA EL FORM DE RRSS Y CV QUE DEBE LLENAR EL POSTULANTE */}
                    <Route exact path="/form-rrss/:hash" children={ <FormRrss /> } />
                    <Route exact path="/form-rrss/response/index" component={ Response } />

                    {/* VIEW OF A MAIL */}
                    <Route exact path="/mail" component={ Email } />

                    {/* CONTRATOS */}
                    <Route exact path="/agreement/completed" children={ <AgreementComplete type="completed" /> } />
                    <Route exact path="/agreement/cancel" children={ <AgreementComplete type="cancel" /> } />
                    <Route exact path="/agreement/declined" children={ <AgreementComplete type="declined" /> } />
                    <Route exact path="/agreement/:hash" children={ <Agreement/> } />


                    <UserContext.Provider value={ ProviderUserData } >
                        <Route
                            exact path="/admin/login"
                            render={
                                props =>
                                    <Login {...props}
                                    login={ login }
                                    showError={ showError }
                                    working={ working }
                                    setWorking={ setWorking }
                                    authType={authType}
                                    backAction={backAction}
                                    defaultUser={defaultUser}
                                />
                            }
                        />

                        <Route
                            exact path="/admin/login/sso-callback"
                            render={
                                props =>
                                    <LoginCallback {...props}

                                />
                            }
                        />

                        <ProtectedRoute
                            exact path="/admin"
                            component={ Admin }
                            allowedRoles={ Constants.AllRolesList }
                            isLogged={ isLogged }
                        />
                        <ProtectedRoute
                            exact path="/admin/details"
                            component={ DetailsApplicant }
                            allowedRoles={ Constants.AllRolesList }
                            isLogged={ isLogged }
                        />

                    </UserContext.Provider>

                </Switch>
            </>
        )
    }

}
export default App;