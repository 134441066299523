import React, { useEffect, useState } from 'react';
import './Login.css';
import ComeBack from '../../../assets/images/come-back.png'

import { useSnackbar } from 'react-simple-snackbar'

const Login = ({ login, showError, setWorking, working, authType, defaultUser = "", backAction }) => {

    const checkAuthType = [0, 1].includes(authType);

    // Variables de formulario Login
    const datosLoginInitialState = {
        username: '',
        password: ''
    }
    const [datosLogin, setDatosLogin] = useState(datosLoginInitialState)

    //Variables de comprobacion
    const [errorUser, setErrorUser] = useState(false)
    const [errorPassword, setErrorPassword] = useState(false)

    const [submit, setSubmit] = useState(false)

    const options = {
        position: 'bottom-center',
        style: {
            backgroundColor: 'var(--bs-red)',
            border: '0',
            color: '#fff',
            fontSize: '20px',
            textAlign: 'center',
            marginBottom: '5rem',
        },
        closeStyle: {
            color: '#fff',
            fontSize: '16px',
            marginRight: '1rem',
        },
    }

    const [openSnackbar] = useSnackbar(options)

    const handleChange = async event => {
        const { name, value } = event.target

        setDatosLogin( prevDatos => ({
            ...prevDatos,
            [name]: value
        }))
    }

    useEffect(() => {
        if (submit) {
            setWorking( true )

            if(!errorUser && !errorPassword) {
                setTimeout(() => {
                    sendInformation()
                }, 500);
            }

            setSubmit(false)
        }

    }, [submit])

    const handleSubmit = (event) => {
        event.preventDefault();
        setSubmit( checkInputs() )
    }

    //Metodo para el envío de la información a la
    const sendInformation = async () => {

        //seccion donde se debe de poner el try-catch para el envío de variables
        try {
            login(datosLogin.username, datosLogin.password)

        } catch (error) {
            showError(error);
            setWorking( false )
            setDatosLogin(datosLoginInitialState)
        }
    }

    const checkInputs = () => {
        let submit = true
        if(datosLogin.username.length === 0) {
            setErrorUser(true)
            openSnackbar('Ingrese usuario')
            document.querySelector('input[name="username"]').focus()
            submit = false

        } else {
            setErrorUser(false)
        }

        if(datosLogin.username.length > 0 && datosLogin.password.length === 0 && checkAuthType) {
            setErrorPassword(true)
            openSnackbar('Ingrese contraseña')
            document.querySelector('input[name="password"]').focus()
            submit = false

        } else {
            setErrorPassword(false)
        }
        return submit
    }

    return (
        <div className="container-fluid" id="login_container_fluid">
            <div className="row" id="login_container">
                <div className="col-md" id="logo_container">
                    <img src="/images/login/login_logo.png" alt="balanz ifa panel" />
                </div>
                <div className="col-md" id="form_container">
                    <form action="#" method="POST" id="login_form" onSubmit={ handleSubmit }>
                        <div className="username">
                            <input
                                onChange={ (e) => handleChange(e) }
                                value={datosLogin.username}
                                type="text"
                                name="username"
                                id="username"
                                placeholder="usuario"
                                className={errorUser ? 'error' : ''}
                                disabled={ working || checkAuthType }
                            />

                            {checkAuthType && (
                                <button type="button" onClick={backAction} class="come-back-action">
                                <img src={ComeBack} alt="come back" />
                                </button>
                            )}
                        </div>

                        {checkAuthType && (
                            <input
                                onChange={ (e) => handleChange(e) }
                                value={datosLogin.password}
                                type="password"
                                name="password"
                                id="password"
                                placeholder="contraseña"
                                className={errorPassword ? 'error' : ''}
                                disabled={ working }
                            />
                        )}

                        <input
                            type="submit"
                            id="submit_login_form"
                            value={ working ? 'validando ...' : 'Ingresar'}
                            className="btn gray_btn"
                            disabled={ working }
                        />
                    </form>
                </div>
            </div>
        </div>
    )

}
export default Login;