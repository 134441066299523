import React, {useEffect, useState} from 'react'
import { API } from '../../../../http-common'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'


export const HistoryActions = ({prospectsId}) => {
    const historyActions = [
        {
            "usuario": "Douglas Estevano",
            "fecha":"2021-10-30",
            "accion":"aprobó al usuario ev srdv ewdfver ihbub iubiun iuuuu niubnibuybg hjbhbuhbuy kswdw "
        },
        {
            "usuario": "Eliu Ramirez",
            "fecha":"2021-11-13",
            "accion":"contrato entregado"
        },
        {
            "usuario": "Lorenzo Delfino",
            "fecha":"2021-11-30",
            "accion":"contrato firmado"
        }
    ]

    const [logs, setLogs] = useState([])

    useEffect(async () => {
        await API.get(`/prospects/history/${prospectsId}`).then((res => {
            let log = res.data.data
            setLogs(log)

        })).catch(error => {
            console.log(error)
        })
    }, [])

    return (
        <div className="col-md-12 item my-4" id="container_history">
            <div className="container_history_here">
                <h4 className="d-block blue_label">Historial:</h4>
                <div className="car item_history">
                    <div className="card-body">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Usuario</th>
                                    <th className="w-25">Fecha</th>
                                    <th>Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                               
                                    {
                                        logs.length > 0 ?
                                        logs.map((data, index) => {
                                        return(
                                            <tr key={index}>
                                            <td>{data.username}</td>
                                            <td>{data.date_log}</td>
                                            <td dangerouslySetInnerHTML={{ __html: data.message }}></td>
                                            </tr>
                                            )
                                        }) :
                                        (
                                            <tr key="">
                                                <td>No tiene historial</td>
                                            </tr>
                                        )
                                    }
                                
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    )
}
