import React, { useContext, useEffect, useState } from 'react'
import { useSnackbar } from 'react-simple-snackbar'

import { CandidateContext } from '../../../../context/CandidateContext';

/* API */
import { API } from './../../../../http-common';

export const DataRRSS = () => {

    const { candidateData } = useContext(CandidateContext)

    const dataRrssInitialState = {
        rrss_fb: '',
        rrss_ig: '',
        rrss_lk: '',
        rrss_tw: ''
    }
    const [dataRrss, setDataRrss] = useState('')

    const [working, setWorking] = useState('')

    const [submit, setSubmit] = useState(false)

    const optionsError = {
        position: 'bottom-right',
        style: {
            backgroundColor: 'var(--bs-red)',
            border: '0',
            color: '#fff',
            fontSize: '20px',
            textAlign: 'center',
            marginBottom: '5rem',
        },
        closeStyle: {
            color: '#fff',
            fontSize: '16px',
            marginRight: '1rem',
        },
    }

    const optionsSuccess = {
        position: 'bottom-right',
        style: {
            backgroundColor: 'var(--bs-green)',
            border: '0',
            color: '#fff',
            fontSize: '20px',
            textAlign: 'center',
            marginBottom: '5rem',
        },
        closeStyle: {
            color: '#fff',
            fontSize: '16px',
            marginRight: '1rem',
        },
    }

    const [openSnackbarSuccess] = useSnackbar(optionsSuccess)
    const [openSnackbarError] = useSnackbar(optionsError)

    const handleChangeInputRss = (event) => {
        const { name, value } = event.target;
        setDataRrss( prevDataRrss => ({
            ...prevDataRrss,
            [name]: value
        }))
    }

    const handleSubmitFormRrss = event => {
        event.preventDefault();
        openSnackbarSuccess(' Guardando datos... ')
        setWorking( true )
        setSubmit( true )
        //const validation = checkInputsRrss();
    }

    useEffect(() => {
        if( submit ) {
            saveDataRrss();
        }
    }, [submit])

    useEffect( () => {
        if(candidateData.id) {
            getDataRrss()
        }
    }, [candidateData])

    const getDataRrss = async () => {
        await API.get(`/prospects/social_networks/${candidateData.id}`)
        .then(function (response) {
            if( response.data.social_networks !== null ) {
                setDataRrss(response.data.social_networks);
            } else {
                setDataRrss( dataRrssInitialState )
            }
        })
        .catch(function (error) {
            console.log(`getDataRrss error =>`, error.response);
        });
	};

    const saveDataRrss = async () => {
        await API.post(`/prospects/social_networks/${candidateData.id}`, dataRrss)
        .then(function (response) {
            openSnackbarSuccess('¡ Redes sociales guardadas !')
        })
        .catch(function (error) {
            console.log(`setDataRrss error =>`, error.response);
            openSnackbarError('no se pudo guardar los datos')
        });

        setWorking( false )
        setSubmit( false )
	};

    const setDisplayClass = () => {
        const status = candidateData.prospect_status;
        let display_btn = "btn blue_btn balanz_btn"
        if( status === 28 || status === 29 || status === 48 || status === 49 || status === 58 || status === 59) {
            display_btn = "d-none"
        }

        return display_btn
    }


    return (
        <div className="col-md-12 item mb-4" id="container_add_data_bank">
            <div className="row header_data_section">Redes Sociales</div>
            <form id="form_add_data_bank" action="#" onSubmit={ (e) => handleSubmitFormRrss(e) }>
                <div className="row form-row">
                    <div className="col-md-6">
                        <label className="data_label blue_data d-blok" htmlFor="rrss_fb">Facebook</label>
                        <input onChange={ (e) => handleChangeInputRss(e) } type="text" value={ dataRrss.rrss_fb } placeholder="Facebook" name="rrss_fb" id="rrss_fb" className="form-control" />
                    </div>
                    <div className="col-md-6">
                        <label className="data_label blue_data d-blok" htmlFor="rrss_ig">Instagram</label>
                        <input onChange={ (e) => handleChangeInputRss(e) } type="text" value={ dataRrss.rrss_ig } placeholder="Instagram" name="rrss_ig" id="rrss_ig" className="form-control" />
                    </div>
                </div>
                <br/>
                <div className="row form-row">
                    <div className="col-md-6">
                        <label className="data_label blue_data d-blok" htmlFor="rrss_tw">Twitter</label>
                        <input onChange={ (e) => handleChangeInputRss(e) } type="text" value={ dataRrss.rrss_tw } placeholder="Twitter" name="rrss_tw" id="rrss_tw" className="form-control" />
                    </div>
                    <div className="col-md-6">
                        <label className="data_label blue_data d-blok" htmlFor="rrss_lk">Linkedin</label>
                        <input onChange={ (e) => handleChangeInputRss(e) } type="text" value={ dataRrss.rrss_lk } placeholder="Linkedin" name="rrss_lk" id="rrss_lk" className="form-control" />
                    </div>
                </div>
                <div className="row form-row mt-2">
                    <div className="col-md-6"></div>

                    <div className="col-md-6 d-flex align-items-end justify-content-end mt-4">
                        <button
                            type="submit"
                            name="submit_add_data_bank"
                            id="submit_add_data_bank"
                            className={ setDisplayClass() }
                            disabled={ working }
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </form>

        </div>
    )
}
