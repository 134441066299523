import React, { useState, useContext, useEffect } from "react";
import { useSnackbar } from "react-simple-snackbar";
import { CandidateContext } from '../../../../context/CandidateContext';
import { Form } from 'react-bootstrap';
import { API } from "../../../../http-common";
import {GRUPO_EQ_COMERCIAL, ROLE_COMPLIANCE, ROLE_EQ_COMERCIAL_LIDER, ROLE_SOP_COMERCIAL, GROUP} from "../../../../constants";

export const TypeContract = ({ user_role, check_data }) => {
  	const { candidateData } = useContext( CandidateContext )
	  const checkContractStatus = candidateData.prospect_status === 100;

  	const [getDataApi, setGetDataApi] = useState( true )
	const [working, setWorking] = useState(false)
	const [submit, setSubmit] = useState(false)

	const [contractList, setContractList] = useState([])
	const [contractValidationList, setContractValidationList] = useState([])
	const [contractPercentList, setContractPercentList] = useState([])

	const [adviser, setAdviser] = useState("")
	const [businessId, setBusinessId] = useState(1)
	const [tipoDeContrato, setTipoDeContrato] = useState("")
	const [IdTipoContrato, setIdTipoContrato] = useState(0)
	const [isInputEnabled, setIsInputEnabled] = useState(false)
	const [valid, setValid] = useState("")
	const [percent, setPercent] = useState("")
	const [mount, setMount] = useState("")
	const [errorMount, setErrorMount] = useState("")
	const {TYPECONTRACTIFAPE} = require("../../../../constants");
	const dataContractInitalState = {
		tipo_contrato: '',
		vigencia_acuerdo: '',
		comision: '',
		fa_asignado: '',
		amount: ''
	}

	const ErrordataContractInitalState = {
		tipo_contrato: '',
		vigencia_acuerdo: '',
		comision: '',
		fa_asignado: '',
		amount: ''
	}

	const [dataContract, setDataContract] = useState(dataContractInitalState)
	const [ErrordataContract, setErrorDataContract] = useState(ErrordataContractInitalState)

	const optionsError = {
		position: "bottom-right",
		style: {
			backgroundColor: "var(--bs-red)",
			border: "0",
			color: "#fff",
			fontSize: "20px",
			textAlign: "center",
			marginBottom: "5rem",
		},
		closeStyle: {
				color: "#fff",
				fontSize: "16px",
				marginRight: "1rem",
		},
	};

	const optionsSuccess = {
	position: "bottom-right",
	style: {
		backgroundColor: "var(--bs-green)",
		border: "0",
		color: "#fff",
		fontSize: "20px",
		textAlign: "center",
		marginBottom: "5rem",
	},
	closeStyle: {
		color: "#fff",
		fontSize: "16px",
		marginRight: "1rem",
	},
	};



	const [openSnackbarSuccess] = useSnackbar(optionsSuccess);
	const [openSnackbarError] = useSnackbar(optionsError);

	useEffect(() => {
		
		if (businessId === 2 && (IdTipoContrato === 5 || IdTipoContrato === 6)) {
			setIsInputEnabled(true);
		  } else {
			setIsInputEnabled(false);
		  }

	}, [IdTipoContrato])

	const handleChangeInput = (event) => {
		const { name, value, type } = event.target
		
		if( name === "tipo_contrato" ) {
			const numericValue = Number(value);
			let value_vigencia = ''

			if ( Number(value) === 2 ) {
				value_vigencia = 12
			} else {
				value_vigencia = ''
			}
			setIdTipoContrato(numericValue)

			setDataContract( prev => ({
				...prev,
				vigencia_acuerdo: value_vigencia,
				amount:''
			}) )
			setErrorDataContract( prev => ({
				...prev,
				vigencia_acuerdo: ''
			}))
		}
		if(name === "amount"){
			if(type === "number"){
			setDataContract( prev => ({
				...prev,
				[name]: value
			}))
			}
			else {
				setErrorDataContract( prev => ({
					...prev,
					amount: ''
				}))
			}
			
		}
	
		setDataContract( prev => ({
			...prev,
			[name]: value
		}) )

		/* CLEAN ERROR */
		setErrorDataContract( prev => ({
			...prev,
			[name]: ''
		}))
	}


	const handleSubmit = (event) => {
		event.preventDefault();
		setSubmit( checkInputs() )
	};

	const checkInputs = () => {
		let success = true;
		//Reasignar el valor de amount a 0  en la validacion si el tipo de contrato no es ifa pe 
		if (![TYPECONTRACTIFAPE.PRODUCTORIFAPE, TYPECONTRACTIFAPE.REFERENCIADORIFAPE].includes(Number(dataContract.tipo_contrato))) {
			dataContract.amount = 0;
		  }
		  else {
			if (dataContract.amount === '') {
			  setErrorDataContract( prevData => ({
				...prevData,
			  }))
		  } 
		}
		for( const [key, value] of Object.entries(dataContract) ){
			if (value.length < 1 || value === null) {
				setErrorDataContract( prevData => ({
					...prevData,
					[key]: "el campo es requerido"
				}))
				openSnackbarError("¡ Campos requeridos !")
				success = false
			}
		}
		console.log(success,"change contract type")
		return success
	}

	const setStatusDataContract = async (id) => {
		setGetDataApi( false )

		const dataContractInitalState = {
			tipo_contrato:  '',
			vigencia_acuerdo: '',
			comision: '',
			amount: ''
		}

		await API.get(`prospects/contracts/${id}`).then(res => {
			let contracts = res.data.data.contracts
			let adviser = res.data.data.adviser
			let business_id = res.data.data.adviser_business
			let validations = {}
			let percents = {}
			
			setAdviser(adviser)
			setContractList(contracts);
			setBusinessId(business_id)

			contracts.forEach(item => {
				validations[item.id] = item.validation
				percents[item.id] = item.percents
				if(item.selected > 0){
					check_data(true)
					
					dataContractInitalState.tipo_contrato = item.id
					setTipoDeContrato(item.name)
					setIdTipoContrato(item.id)
					item.validation.forEach(valid => {
						if(valid.selected > 0) {
							dataContractInitalState.vigencia_acuerdo = valid.id
							setValid(valid.name)
						}
					})
					item.percents.forEach(percent => {
						if(percent.selected > 0) {
							dataContractInitalState.comision = percent.id
							setPercent(percent.name)
						}
					})
					dataContractInitalState.amount = item.amount
					setMount(item.amount)
				}
			})

			setContractPercentList(percents)
			setContractValidationList(validations)
			//console.log(percents)

		}).catch(err => {
			console.log(err);
		})

		setDataContract( dataContractInitalState )
	}

	useEffect(() => {
		if( GROUP.EQ_SOPORTE.includes(user_role) &&candidateData.prospect_status >= 40){
			setWorking(false)
		}
		if ( candidateData.id && getDataApi) {
			( async () => {
				await setStatusDataContract(candidateData.id)
			})();
		}
	}, [candidateData])

	const makeErrorField = (text) => {
		return (
			<Form.Text
				className="text-danger fw-bold"
				style={{ fontSize: "11px", lineHeight: "0" }}
			>
				{text}
			</Form.Text>
		);
	};

	useEffect(() => {


		if( submit ) {
			setWorking( true )
			openSnackbarSuccess(' Guardando datos... ')
			saveDataContract()
		}
	}, [submit])

	const saveDataContract = async () => {

		let body = {
			contract_id: dataContract.tipo_contrato,
			validation_id: dataContract.vigencia_acuerdo,
			percent_id: dataContract.comision,
			amount: dataContract.amount || null
		}

        await API.post(`/prospects/contracts/${candidateData.id}`, body)
        .then(function (response) {
            console.log(`saveDataContract response => `, response)
            openSnackbarSuccess('¡Datos de contrato guardados!')
			check_data(true)
        })
        .catch(function (error) {
            console.log(`saveDataContract error =>`, error.response);
            openSnackbarError('no se pudo guardar los datos del contrato')
        });

        setWorking( false )
        setSubmit( false )
	};

	/*const setDisplayClass = () => {
        const status = candidateData.prospect_status;
        let display_btn = "btn blue_btn balanz_btn"
        if( status === 28 || status === 29 || status === 48 || status === 49 || status === 58 || status === 59) {
            display_btn = "d-none"
        }

        return display_btn
    }*/

	const setSeeAble = () => {
        const status = candidateData.prospect_status;

        const show = 'btn blue_btn balanz_btn';
        const none = 'd-none';
        let display_btn = "";

        if( status === 28 || status === 29 || status === 48 || status === 49 || status === 58 || status === 59) {
            display_btn = none
        }
        else if( GROUP.EQ_DIRECTORES.includes(user_role) && status > 20 && status <= 31  ) {
            display_btn = show

        } else if ( GROUP.EQ_SOPORTE.includes(user_role) && status > 29 && status <= 40 ) {
            display_btn = show

        } else if ( GROUP.COMPLIANCE.includes(user_role) && status > 39 && status <= 50 ) {
            display_btn = show

        } else{
            display_btn = none
        }

        return display_btn
    }

  	return (
		<div className="col-md-12 item my-4">
			<div className="row header_data_section">
				tipo y características del contrato
			</div>
			{user_role !== 3? (
				<form action="#" id="type_contract" onSubmit={(e) => handleSubmit(e)}>
					<div className="row form-row">
						<div className="col-md-4 col-xs-12 mb-4">
							<label className="blue_data" htmlFor="tipo_contrato">
								Tipo de contrato &nbsp;
								{ ErrordataContract.tipo_contrato &&
									makeErrorField(ErrordataContract.tipo_contrato)
								}
							</label>
							<select
								onChange={(e) => handleChangeInput(e)}
								name="tipo_contrato"
								id="tipo_contrato"
								className={ ErrordataContract.tipo_contrato.length > 0 ? 'form-control border border-danger data_item' : 'form-control data_item' }
								value={dataContract.tipo_contrato}
								disabled={ working || checkContractStatus}
							>
								<option value="" disabled>
									Seleccionar Contrato
								</option>
								{contractList.length > 0 && contractList.map((contract, index) => {
									return <option value={contract.id} key={index}>{contract.name}</option>
								})}
							</select>
						</div>
						<div  className="col-md-4 col-xs-12">
							<label className="blue_data" htmlFor="vigencia_acuerdo">
								Vigencia &nbsp;
								{ ErrordataContract.vigencia_acuerdo &&
									makeErrorField(ErrordataContract.vigencia_acuerdo)
								}
							</label>
							<select
								onChange={(e) => handleChangeInput(e)}
								name="vigencia_acuerdo"
								id="vigencia_acuerdo"
								className={ ErrordataContract.vigencia_acuerdo.length > 0 ? 'form-control border border-danger data_item' : 'form-control data_item' }
								value={dataContract.vigencia_acuerdo}
								disabled={working || checkContractStatus || dataContract.tipo_contrato === "" || dataContract.tipo_contrato === "2" ? true : false}
							>

								<option value="" disabled> Seleccionar </option>
								{dataContract.tipo_contrato !== "" && contractValidationList[dataContract.tipo_contrato].map((mes, index) => {
									return <option value={mes.id} key={index}>
											{mes.name}
										</option>
								})}
							</select>

						</div>
						{	
							isInputEnabled && (
								<div  className="col-md-4 col-xs-12">
							  <label id="amount" className="me-2 d-inline data_label blue_title">
								Monto &nbsp;
								{ ErrordataContract.amount &&
									makeErrorField(ErrordataContract.amount)
								}
								</label>
                                <input
								onChange={(e) => handleChangeInput(e)}
                                type="number"
                                value={dataContract.amount}
                                name="amount"
                                id="amount"
                                className="form-control d-inline"
                                />
	
							</div>
							
							)
						}
						<div className="col-md-4"></div>
					</div>
					<br />
					<div className="row form-row">
						<div className="col-md-4 col-xs-12 mb-4">
							<label className="blue_data" htmlFor="fa_asignado">
								F.A. Asignado &nbsp;
								{ ErrordataContract.fa_asignado &&
									makeErrorField(ErrordataContract.fa_asignado)
								}
							</label>
							<select
								onChange={(e) => handleChangeInput(e)}
								value={dataContract.fa_asignado}
								disabled={checkContractStatus}
								placeholder="F.A. Asignado"
								className={ ErrordataContract.fa_asignado.length > 0 ? 'form-control border border-danger data_item' : 'form-control data_item' }
							>
								<option value="" key="0">{adviser}</option>
							</select>
						</div>
						<div className="col-md-4 col-xs-12 mb-4">
							<label className="blue_data" htmlFor="comision">
								Porcentaje de Comisión &nbsp;
								{ ErrordataContract.comision &&
									makeErrorField(ErrordataContract.comision)
								}
							</label>
							<select
								onChange={(e) => handleChangeInput(e)}
								name="comision"
								id="comision"
								className={ ErrordataContract.comision.length > 0 ? 'form-control border border-danger data_item' : 'form-control data_item' }
								value={dataContract.comision}
								disabled={working || checkContractStatus || dataContract.tipo_contrato === '' ? true : false }
							>
							<option value="" disabled>
								Seleccionar %
							</option>
							{dataContract.tipo_contrato !== "" && contractPercentList[dataContract.tipo_contrato].map((porcentaje, index) => {
								return <option value={porcentaje.id} key={index}>{porcentaje.name}</option>
							})}
							</select>
						</div>
						<div className="col-md-4 d-flex align-items-end justify-content-end mb-4">
							<button
							type="submit"
							name="submit_form_bank"
							id="submit_form_bank"
							className={ setSeeAble() }
							disabled={ working || checkContractStatus }
							> Guardar </button>
						</div>
					</div>
				</form>
				) : (
				<>
					<div className="row">
						<div className="col-md-4 col-xs-12">
						<span className="data_label blue_title d-block mt-4">
							Tipo de contrato
						</span>
						<span className="data_item blue_title">{tipoDeContrato}</span>
						</div>
						<div className="col-md-4 col-xs-12">
						<span className="data_label blue_title d-block mt-4">
							Vigencia
						</span>
						<span className="data_item blue_title">{valid}</span>
						</div>
						{
							isInputEnabled && (
								<div className="col-md-4 col-xs-12">
								<span className="data_label blue_title d-block mt-4">
									Monto
								</span>
								<span className="data_item blue_title">{mount}</span>
								</div>
							)
						}
						{
							businessId === 1 && (
								<div className="col-md-4"></div>
							)
						}
					</div>
					<div className="row mt-4">
						<div className="col-md-4 col-xs-12">
						<span className="data_label blue_title d-block mt-4">
						FA asignado
						</span>
						<span className="blue_title data_item">{adviser}</span>
						</div>
						<div className="col-md-4 col-xs-12">
						<span className="data_label blue_title d-block mt-4">
							Porcentaje de Comisión
						</span>
						<span className="blue_title data_item">{percent}</span>
						</div>
					</div>
				</>
			)}
		</div>
  	);
};
