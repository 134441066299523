import React, { useEffect, useState } from "react";
import { API } from "../../../http-common";
import { useParams } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
//import pdfUrl from "../../../assets/pdf/Auspiciante_Persona_Física.PDF";
import { useSnackbar } from 'react-simple-snackbar'
import "./Agreement.css";

const optionsError = {
  position: 'bottom-right',
  style: {
      backgroundColor: 'var(--bs-red)',
      border: '0',
      color: '#fff',
      fontSize: '20px',
      textAlign: 'center',
      marginBottom: '5rem',
      position: 'fixed',
      zIndex: "99"
  },
  closeStyle: {
      color: '#fff',
      fontSize: '16px',
      marginRight: '1rem',
  },
}

const optionsSuccess = {
  position: 'bottom-right',
  style: {
      backgroundColor: 'var(--bs-green)',
      border: '0',
      color: '#fff',
      fontSize: '20px',
      textAlign: 'center',
      marginBottom: '5rem',
  },
  closeStyle: {
      color: '#fff',
      fontSize: '16px',
      marginRight: '1rem',
  },
}

const Agreement = () => {
  const { hash } = useParams();
  const [pdfAgreement, setPdfAgreement] = useState("");
  const [candidateId, setId] = useState("")
  const [contractModal, setContractModal] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [modalMessage, setModalMessage] = useState("")
  const [rejectMsg, setRejectMsg] = useState("")
  const [signUrl, setSignUrl] = useState('')
  //const pdfUrl = "https://pdf-lib.js.org/assets/with_update_sections.pdf";

  const [openSnackbarSuccess] = useSnackbar(optionsSuccess)
  const [openSnackbarError] = useSnackbar(optionsError)

  const handleClose = () => {
    setRejectMsg("");
    setOpenModal(false)
    setContractModal(0)
  }

  const setReject = async ( candidato_id, msg, code_id ) => {
    /* ROL QUE APRUEBA */
    console.log(`Reject candidato => `, candidato_id, msg)

    if(msg !== ""){
      await API.post(`/prospects/status/${candidato_id}/${code_id}`, {message: msg})
    .then(function (response) {
        console.log(`Reject response => `, response)
        openSnackbarSuccess(`Se ha actualizado el estado`);
        window.history.back();
    })
    .catch(function (error) {
        if(error.response){
            openSnackbarError(`Ocurrio un error por favor contacte con el equipo de soporte` )
        }
    });
    }
    else{
      openSnackbarError(`Debe indicar el motivo del rechazo` )
    }

}

/*const setAprove = async ( candidato_id, status ) => {
  /!* ROL QUE APRUEBA *!/
  console.log(`setAprove candidato => `, candidato_id)
  await API.post(`/prospects/status/${candidato_id}/${status}`)
  .then(function (response) {
      console.log(`setAprove response => `, response)
      openSnackbarSuccess(`Se ha actualizado el estado`);
      setTimeout(function () {
          window.location.reload();
      }, 3000);

  })
  .catch(function (error) {
      if(error.response){
          openSnackbarError(`Ocurrio un error por favor contacte con el equipo de soporte` )
      }
  });
}*/

  useEffect(async () => {
    await API.get(`prospects/contracts/link/${hash}`)
      .then((res) => {
        console.log(res.data.data.url);
        setPdfAgreement(res.data.data.url);
        setId(res.data.data.prospect_id);
        setSignUrl(res.data.data.signUrl)
      })
      .catch((err) => {
          openSnackbarError(`Ocurrio un error por favor contacte con el equipo de soporte`);
      });
    // setPdfAgreement(pdfUrl)
  }, []);

  /*useEffect(() => {
    if(contractModal === 1){
      setOpenModal(true)
      setModalMessage("Esta a punto de aceptar el contrato, desea continuar?")
    }
    else if(contractModal === 2){
      setOpenModal(true)
      setModalMessage("Esta a punto de rechazar el contrato, indique brevemente el motivo de rechazo")

    }
  }, [contractModal])*/

  return (
    <>
    <div className="headerModal d-flex align-items-center justify-content-center mb-5" style={{color:"white", fontSize:"40px",textAlign:"center", fontWeight:"bold"}}>BALANZ-IFA CONTRATO</div>
    <div className="agreementButtons">
        {/* <Button variant="success" onClick={() => {setContractModal(1)}}>Aceptar</Button>
        <Button variant="danger"onClick={() => {setContractModal(2)}}>Rechazar</Button> */}
        {signUrl && <Button href={signUrl} className='my-2 mb-5' variant="success">FIRMAR DOCUMENTO</Button>}
      </div>
      <div className="pdfContainer">
        {pdfAgreement && (
          <iframe
            src={pdfAgreement + "#toolbar=0"}
            className="pdfViewer"
          ></iframe>
        )}
      </div>

      {/*<Modal isOpen={openModal} className="" id="to_reject">
                <ModalHeader className="justify-content-end">
                    <button className="close bg-transparent border-0" onClick={e => handleClose()} >
                        <span>&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody className="modal-body">

                    {contractModal === 1 ?
                    <>
                      <p className="modal_title green_text">
                          {modalMessage}
                      </p>
                      <div className="row modal_btns">
                        <button
                            type="button"
                            className="btn blue_btn"
                            id="to_approve_email_confirm_btn"
                            onClick={ (e) => setAprove( candidateId, 200 ) }
                        >
                            Confirmar
                        </button>
                        <button type="button" className="btn red_btn" id="to_approve_email_cancel_btn" onClick={e => handleClose()}>
                            Cancelar
                        </button>
                    </div>
                    </>
                      :
                    <>
                      <p className="modal_title red_text">
                            {modalMessage}
                      </p>
                      <form action="#">
                        <textarea name="motivo" id="motivo" cols="30" rows="7" value={rejectMsg} onChange={ e => setRejectMsg(e.target.value) }></textarea>

                        <div className="row modal_btns">
                            <button type="button" className="btn blue_btn" id="to_reject_confirm_btn" onClick={() => setReject(candidateId, rejectMsg, 201)}>
                                Confirmar
                            </button>
                            <button type="button" className="btn red_btn" id="to_reject_cancel_btn" onClick={e => handleClose()}>
                                Cancelar
                            </button>
                        </div>
                    </form>
                    </>}

                </ModalBody>
        </Modal>*/}

    </>
  );
};

export default Agreement;
