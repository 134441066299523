import React, {useEffect, useState} from 'react'
import {API} from "../../../../http-common";
export const EditAnalystModal = ({ analyst, setAnalystModal, analystSelect}) => {
    const [inputValueAction, setInputValueAction] = useState(analystSelect)

    const handleInputChange = (e) => {
        const {value} = e.target;
        setInputValueAction(value)
      };
    const sendInputValueAction = async () =>{
        try{
            await  API.post(`prospects/analyst/${analyst}`, {analyst: inputValueAction})
            .then(function (response) {
                window.history.back();
                
            })
        }
        catch (e) {
            console.log(e)
        }
        window.location.reload()
    }
   
    return (
        <>
            <p className="modal_text_info blue_title">
            <input
             type="text"
             className={'form-control d-inline'}
             value={inputValueAction === '-' ? '' : inputValueAction} 
             onChange={handleInputChange}
             
              />
            </p>
            <div className="row modal_btns">
                <button
                    type="button"
                    className="btn blue_btn"
                    id="to_approve_email_confirm_btn"
                    onClick={sendInputValueAction}
                >
                    Confirmar
                </button>
                <button type="button" className="btn red_btn" id="to_approve_email_cancel_btn" onClick={e => setAnalystModal(false)}>
                    Cancelar
                </button>
            </div>
        </>
    )
}