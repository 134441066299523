export const listGender = [
    {
        id: 1,
        name: 'Masculino',
    },{
        id: 2,
        name: 'Femenino',
    }
]

export const listMaritalStatus = [
    {
        id: 1,
        name: 'Soltero/a',
    }, {
        id: 2,
        name: 'Casado/a',
    }, {
        id: 3,
        name: 'Viudo/a'
    }, {
        id: 4,
        name: 'Divorciado/a'
    }, {
        id: 5,
        name: 'Separado/a'
    }, {
        id: 6,
        name: 'Concubino/a'
    }
]

export const listToggle = [
    {
        id: 2,
        name:"SI"
    }, {
        id: 1,
        name: 'NO'
    }
]

export const listPosTaxProfit = [
    {
        id: 1,
        name: 'Inscripto',
    }, {
        id: 2,
        name: 'No inscripto',
    }, {
        id: 3,
        name: 'No responsable'
    }, {
        id: 4,
        name: 'Exento'
    }
]

export const listPostFrontIva = [
    {
        id: 1,
        name: 'Consumidor final',
    }, {
        id: 2,
        name: 'Monotributista',
    }, {
        id: 3,
        name: 'Exento',
    }, {
        id: 4,
        name: 'Responsable inscripto',
    },
]

export const listIncomeTaxSituation = [
    {
        id: 1,
        name: 'Régimen general',
    }, {
        id: 2,
        name: 'Régimen especial',
    }, {
        id: 3,
        name: 'Régimen simplificado',
    }, {
        id: 4,
        name: 'Convenio multilateral',
    }, {
        id: 5,
        name: 'Contribuyente local',
    }, {
        id: 6,
        name: 'Exento',
    },
]

export const listToggleOwns = [
    {
        id: 2,
        name: 'Posee',
    },
    {
        id: 1,
        name: 'No posee',
    },
]

export const listTypeAccount = [
    {
        id: 1,
        name: 'Cuenta corriente'
    },{
        id: 2,
        name: 'Caja de ahorro'
    },
]

export const listActivityType = [
    {
        "id": 0,
        "name": "Seleccionar"
    },
    {
        "id": 2,
        "name": "Agricultura, Ganadería, Caza, Silvicultura y Pesca"
    },
    {
        "id": 10,
        "name": "Fabricación de maquinarias y Equipos (Todas)"
    },
    {
        "id": 11,
        "name": "Fabricación de Vehículos Automotores, Remolques y Semirremolques"
    },
    {
        "id": 13,
        "name": "Electricidad, Gas, Vapor y Agua Caliente"
    },
    {
        "id": 14,
        "name": "Captación, Depuración y Distribución de Agua"
    },
    {
        "id": 15,
        "name": "export Construcción"
    },
    {
        "id": 19,
        "name": "Hotelería y Restaurantes"
    },
    {
        "id": 20,
        "name": "Transporte, Almacenamiento y Comunicaciones"
    },
    {
        "id": 21,
        "name": "Intermediación financiera y/o bursátil y/o cambiario y/o cia de seguros"
    },
    {
        "id": 22,
        "name": "Inmobiliarios, Empresariales y de Alquiler"
    },
    {
        "id": 23,
        "name": "Administración Pública, Defensa y Seguridad Social Obligatoria"
    },
    {
        "id": 24,
        "name": "Enseñanza"
    },
    {
        "id": 28,
        "name": "Oficio/Ocupación"
    },
    {
        "id": 36,
        "name": "NOTARIO"
    },
    {
        "id": 38,
        "name": "Ejecutivo Directivo"
    },
    {
        "id": 43,
        "name": "Jubilado"
    },
    {
        "id": 44,
        "name": "Estudiante"
    },
    {
        "id": 47,
        "name": "Desempleado"
    },
    {
        "id": 55,
        "name": "Otro"
    },
    {
        "id": 80,
        "name": "Profesional Independiente"
    },
    {
        "id": 83,
        "name": "Metalúrgica"
    },
    {
        "id": 86,
        "name": "Locación de Inmuebles"
    },
    {
        "id": 87,
        "name": "Consul General"
    },
    {
        "id": 89,
        "name": "Despachante de Aduana"
    },
    {
        "id": 97,
        "name": "Servicios sociales, asistenciales y previsionales"
    },
    {
        "id": 107,
        "name": "Producción, comercialización y distribución de productos de elecrónica"
    },
    {
        "id": 108,
        "name": "Funcionario Público"
    },
    {
        "id": 113,
        "name": "Servicios Bursátiles"
    },
    {
        "id": 117,
        "name": "Farmacéutica"
    },
    {
        "id": 124,
        "name": "Generación de Energía NCP"
    },
    {
        "id": 131,
        "name": "Producción de Espectaculos Teatrales y Musicales"
    },
    {
        "id": 139,
        "name": "Juez"
    },
    {
        "id": 153,
        "name": "Sociedad de Garantía Recíproca"
    },
    {
        "id": 157,
        "name": "Productor y/o Asesor de Seguros"
    },
    {
        "id": 175,
        "name": "TEXTIL"
    },
    {
        "id": 181,
        "name": "Fondos Comunes de Inversión"
    },
    {
        "id": 185,
        "name": "Industria manufacturera"
    },
    {
        "id": 187,
        "name": "Servicios de Crédito"
    },
    {
        "id": 192,
        "name": "Escribano Público"
    },
    {
        "id": 194,
        "name": "Servicios gastronómicos y hotelería"
    },
    {
        "id": 195,
        "name": "INFORMATICA"
    },
    {
        "id": 203,
        "name": "SERVICIOS DE INFORMATICA"
    },
    {
        "id": 205,
        "name": "GENERACION DE ENERGIA"
    },
    {
        "id": 206,
        "name": "Electrónica"
    },
    {
        "id": 210,
        "name": "Servicios de Asesoramiento"
    },
    {
        "id": 227,
        "name": "RECOLECCION DE DESECHOS NO PELIGROSOS"
    },
    {
        "id": 233,
        "name": "Agente/Corredor  Inmobiliaria matriculado"
    },
    {
        "id": 238,
        "name": "Secretario de Estado Pcia Cordoba"
    },
    {
        "id": 240,
        "name": "PROCESAMIENTO DE DATOS"
    },
    {
        "id": 242,
        "name": "TRADUCTOR/A PUBLICO/A"
    },
    {
        "id": 249,
        "name": "Deportistas profesionales"
    },
    {
        "id": 265,
        "name": "GESTION CULTURAL"
    },
    {
        "id": 271,
        "name": "Rentistas"
    },
    {
        "id": 291,
        "name": "Empleado"
    },
    {
        "id": 294,
        "name": "SERVICIOS DE ASESORAMIENTO Y DIRECCION"
    },
    {
        "id": 307,
        "name": "SERVICIOS EMPRESARIALES"
    },
    {
        "id": 318,
        "name": "MEDICINA PREPAGA"
    },
    {
        "id": 322,
        "name": "RECOLECCION,TRANSPORTE Y SIDPOSICION FIANL DE RESIDUOS"
    },
    {
        "id": 348,
        "name": "SERVICIOS DE ORGANIZACIONES PROFESIONALES"
    },
    {
        "id": 352,
        "name": "GUIA DE TREKKING"
    },
    {
        "id": 353,
        "name": "Contador Público matriculado"
    },
    {
        "id": 357,
        "name": "SERVICIO DE TRANSPORTE AUTOMOTOR DE CARGAS N.C.P"
    },
    {
        "id": 358,
        "name": "ORGANIZADOR DE EVENTOS"
    },
    {
        "id": 363,
        "name": "SERVICIOS DE GESTION ADUANERA"
    },
    {
        "id": 366,
        "name": "INDUSTRIA PETROLERA"
    },
    {
        "id": 367,
        "name": "SEPELIO"
    },
    {
        "id": 368,
        "name": "SERVICIOS DE PUBLICIDAD"
    },
    {
        "id": 369,
        "name": "SERVICIO DE FAENA DE ANIMALES"
    },
    {
        "id": 370,
        "name": "Servicios sociales y de la salud y asociados al riesgo de trabajo"
    },
    {
        "id": 371,
        "name": "SERVICIOS JURIDICOS"
    },
    {
        "id": 372,
        "name": "FUNDACION"
    },
    {
        "id": 373,
        "name": "SERVICIOS AUXILIARES A LOS SERVICIOS DE SEGUROS"
    },
    {
        "id": 374,
        "name": "GESTION DE COBRANZA"
    },
    {
        "id": 377,
        "name": "SERVICIOS DE ENTIDADES DE TARJETA DE CREDITO"
    },
    {
        "id": 378,
        "name": "SERVICIOS INMOBILIARIOS"
    },
    {
        "id": 379,
        "name": "SERVICIOS FINANCIEROS"
    },
    {
        "id": 384,
        "name": "SUMINISTRO DE AGUA POTABLE"
    },
    {
        "id": 385,
        "name": "SERVICIO DE ESTACIONAMIENTO Y GARAGE"
    },
    {
        "id": 387,
        "name": "SERVICIOS DE VIGILANCIA"
    },
    {
        "id": 391,
        "name": "SERVICIOS DE FAST FOOD Y LOCALES DE VENTA DE COMIDAS Y BEBIDAS AL PASO"
    },
    {
        "id": 395,
        "name": "SERVICIOS DE TELECOMUNICACIONES"
    },
    {
        "id": 397,
        "name": "Información, tecnología y comunicaciones"
    },
    {
        "id": 398,
        "name": "SERVICIOS DE ASOCIACIONES"
    },
    {
        "id": 399,
        "name": "SERVICIOS DE BOLSA DE COMERCIO"
    },
    {
        "id": 400,
        "name": "Servicios turísticos"
    },
    {
        "id": 402,
        "name": "SERVICIOS PROFESIONALES, CIENTIFICOS Y TECNICOS"
    },
    {
        "id": 403,
        "name": "Comerciante de rodados, yates, naves, aeronaves"
    },
    {
        "id": 404,
        "name": "Comercio de arte, antigüedades, joyas"
    },
    {
        "id": 406,
        "name": "Fiduciario/intermed./adm. Fideicomiso"
    },
    {
        "id": 407,
        "name": "Persona que explota juegos de azar"
    },
    {
        "id": 408,
        "name": "SERVICIOS DE ENTRETENIMIENTO"
    },
    {
        "id": 412,
        "name": "SERVICIOS DE LIMPIEZA GENERAL DE EDIFICIOS"
    },
    {
        "id": 414,
        "name": "Servicios de Seguros e Intermediacion Financierra"
    },
    {
        "id": 416,
        "name": "SERVICIOS DE SALUD HUMANA"
    },
    {
        "id": 418,
        "name": "SERVICIOS DE COBROS"
    },
    {
        "id": 431,
        "name": "Comercio al por mayor y menor"
    },
    {
        "id": 432,
        "name": "Provisión de servicios públicos (luz, agua, gas, cloacas)"
    },
    {
        "id": 433,
        "name": "Servicios de arrendamiento de bienes muebles (equipos, máquinas, tractores)"
    },
    {
        "id": 434,
        "name": "Servicios de transporte público"
    },
    {
        "id": 435,
        "name": "Servicios artísticos, culturales, deportivos y de esparcimiento"
    },
    {
        "id": 436,
        "name": "Organizaciones No Gubernamentales"
    },
    {
        "id": 437,
        "name": "Asociaciones Gremiales / Sindicales"
    },
    {
        "id": 438,
        "name": "Asociaciones civiles y cooperativas de trabajo (no crediticias)"
    },
    {
        "id": 439,
        "name": "Clubes deportivos"
    },
    {
        "id": 440,
        "name": "Servi de créd n.c.p. (coop de crédito, vivienda y consumo, mutuales, tarj reg)"
    },
    {
        "id": 441,
        "name": "Remesadoras de Fondos"
    },
    {
        "id": 442,
        "name": "Registros Públicos (IGJ, Automotor, Aeronaves, de Comercio, etc)"
    },
    {
        "id": 443,
        "name": "Emisoras y operadoras de cheques de viajero y tarjetas de crédito"
    },
    {
        "id": 444,
        "name": "Transportadoras de Caudales"
    },
    {
        "id": 445,
        "name": "Empresas de servicios postales"
    },
    {
        "id": 446,
        "name": "Entidades religiosas, culto, credo, etc"
    },
    {
        "id": 447,
        "name": "Explotación minera, gas y energía"
    },
    {
        "id": 448,
        "name": "Servicios de reparación de bienes muebles e inmuebles"
    },
    {
        "id": 449,
        "name": "Fideicomisos financieros"
    },
    {
        "id": 450,
        "name": "Fideicomisos no financieros"
    },
    {
        "id": 451,
        "name": "LABORATORIO"
    },
    {
        "id": 452,
        "name": "Holding"
    },
    {
        "id": 453,
        "name": "Servicios de asesoramiento (empresarial, jurídico, contable, etc.)"
    },
    {
        "id": 454,
        "name": "Servicios de logística y transporte de bienes muebles"
    },
    {
        "id": 455,
        "name": "Servicios de arrendamiento de bs inmuebles propios"
    },
    {
        "id": 456,
        "name": "servicio de logistica"
    },
    {
        "id": 457,
        "name": "Previsión Social"
    },
    {
        "id": 458,
        "name": "SGR (Sociedad de Garantía Recíproca)"
    },
    {
        "id": 459,
        "name": "Servicios de Criptomonedas"
    },
    {
        "id": 460,
        "name": "Fabricación y/o comercialización de armas"
    },
    {
        "id": 461,
        "name": "Industria farmacéutica"
    },
    {
        "id": 462,
        "name": "Imprentas, editoriales"
    }
]