import React, { useContext } from 'react'

import img_salir from "../../../assets/images/header/salir.png";
import '../../../assets/css/header.css';
import { UserContext } from '../../../context/userContext';
import { deleteToken } from '../../../http-common';
import { useHistory } from 'react-router-dom';

const Header = () => {

    let history = useHistory();

    const { userData, setUserData} = useContext(UserContext)

    const handleLogout = () => {
        deleteToken();
        setUserData(null);
        history.push("/admin/login");
    }

    return (
        <header className="row header_row">
            <div className="col-md" id="container_brand">
                <span className="header_title">BALANZ IFA <span className="header_subtitle">panel</span> </span>
            </div>
            <div className="col-md" id="container_user">
                <span className="username">{ userData.username }</span>
                <button onClick={ (e)=>handleLogout() } className="border-0 bg-transparent">
                    <img src={ img_salir } alt="arrow logout" />
                </button>
            </div>
        </header>
    );

}

export default Header;