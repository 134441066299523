import React, { Component } from "react";
class FormNewAcc extends Component {
    render() {
        return (
            <form action="#" className="w-100 mb-4 col-xs-12 col-sm-12" id="form_iniciar_apertura">
                <h5 className="my-4">Completá los siguientes datos para empezar la apertura de cuenta para persona física.</h5>
                <div className="form-row row">
                    <div className="col-md-4 col-xs-12 col-sm-12 mb-4">
                        <label for="name">Nombre Completo</label>
                        <input type="text" className="form-control" id="name" placeholder="Nombre Completo" name="name" />
                    </div>
                    <div className="col-md-4 col-xs-12 col-sm-12 mb-4">
                        <label for="last_name">Apellido Completo</label>
                        <input type="text" className="form-control" placeholder="Apellido Completo" id="last_name" name="last_name" />
                    </div>
                    <div className="col-md-4 col-xs-12 col-sm-12 mb-4">
                        <label for="email">E-mail</label>
                        <input type="email" className="form-control" id="email" placeholder="E-mail" name="email" />
                    </div>
                </div>

                <div className="form-row row d-none">
                    <label className="col-md-12 my-4" for="check_ingreso_por_asesor_financiero">¿Estás ingresando por un asesor financiero, un productor o referido?</label>
                    <div className="col-md-1 mb-4" id="check_ingreso_por_asesor_financiero">
                        <input type="checkbox" data-toggle="switch" data-size="large" data-on-color="primary" data-on-text="SI" data-off-color="default" data-off-text="NO" id="input_switch" value="0" />
                    </div>

                    <div className="col-md-4">
                        <input type="email" className="form-control d-none" id="email_contact" placeholder="E-mail del contacto" name="email_contact" />
                    </div>
                </div>

                <div className="form-row row justify-content-end border-bottom">
                    <input type="submit" className="btn_balanz btn" value="Continuar" />
                </div>
            </form>
                
        );
    }
}
export default FormNewAcc;