import React, { useContext, useEffect, useState } from 'react'
import { useSnackbar } from 'react-simple-snackbar'
import { CandidateContext } from '../../../../context/CandidateContext';
/* API */
import { API } from './../../../../http-common';
import { ValidateInput } from '../../../../hooks/ValidateInput'
import {API_URL, GRUPO_EQ_COMERCIAL, ROLE_SOP_COMERCIAL, GROUP} from '../../../../constants';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DataBank = ({user_role, setCheckBandkData}) => {

    const saveDocs = (e, type) => {
        const dataForm = new FormData();
        dataForm.append(type, e.target.files[0])
        API.post(`/prospects/uploads/${type}/${type}/${candidateData.id}`, dataForm, { timeout: 10000 }).then(res => {
            console.log(res.data)
            window.location.reload()
        }).catch(err => {
            console.log(err)
        })

    }

    const deleteDocs = (type) => {
        API.post(`prospects/uploads/delete/${type}/${candidateData.id}`)
            .then(() => {window.location.reload()})
    }

    const { candidateData } = useContext( CandidateContext )

    const dataBankInitialState = {
        bank_name: '',
        cbu: '',
        account_type: ''
    }

    const errorDataBankInitialState = {
        bank_name: '',
        cbu: '',
        account_type: '',
    }

    const [dataBank, setDataBank] = useState(dataBankInitialState)

    const [errorDataBank, setErrorDataBank] = useState(errorDataBankInitialState)

    const [working, setWorking] = useState('')

    const [submit, setSubmit] = useState(false)

    const [getDataApi, setGetDataApi] = useState( true )

    const optionsError = {
        position: 'bottom-right',
        style: {
            backgroundColor: 'var(--bs-red)',
            border: '0',
            color: '#fff',
            fontSize: '20px',
            textAlign: 'center',
            marginBottom: '5rem',
        },
        closeStyle: {
            color: '#fff',
            fontSize: '16px',
            marginRight: '1rem',
        },
    }

    const optionsSuccess = {
        position: 'bottom-right',
        style: {
            backgroundColor: 'var(--bs-green)',
            border: '0',
            color: '#fff',
            fontSize: '20px',
            textAlign: 'center',
            marginBottom: '5rem',
        },
        closeStyle: {
            color: '#fff',
            fontSize: '16px',
            marginRight: '1rem',
        },
    }

    const listTypeAccount = [
        {
            id: 1,
            name: 'Cuenta corriente Pesos'
        },{
            id: 2,
            name: 'Caja de ahorro Pesos'
        },
        {
            id: 3,
            name: 'Cuenta corriente Dolares'
        },{
            id: 4,
            name: 'Caja de ahorro Dolares'
        },
    ]

    const [openSnackbarSuccess] = useSnackbar(optionsSuccess)
    const [openSnackbarError] = useSnackbar(optionsError)

    const handleValidateInput = async ( event, typeOfPermitido ) => {
        const { name, value } = event.target

        const checkInput = ValidateInput(value, typeOfPermitido)

        let validation = true

        if(checkInput === false && name !== "account_type") {
            //console.log("name: ", name, " error")
            setErrorDataBank( prevErrorDataBank => ({
                ...prevErrorDataBank,
                [name]: "valor inválido"
            }))

            validation = false

        } else if(checkInput === true) {
            setErrorDataBank( prevErrorDataBank => ({
                ...prevErrorDataBank,
                [name]: ""
            }))
            validation = true
        }

        return validation
    }

    const handleChange = async ( e, typeOfPermitido ) => {
        const { name, value } = e.target
        const validation = await handleValidateInput( e, typeOfPermitido );

        setDataBank( prevDataBank => ({
            ...prevDataBank,
            [name]: value
        }))

        if ( validation ) {
            setErrorDataBank( prevErrorDataBank => ({
                ...prevErrorDataBank,
                [name]: ""
            }))
        }
    }

    const handleSubmit = event => {
        event.preventDefault();

        setSubmit( checkInputsDataBank() );

        //console.log(`dataBank`, dataBank)
    }

    const checkInputsDataBank = () => {
        let success = true;
        for( const [clave, valor] of Object.entries(dataBank) ) {
            if( valor === "" || valor === null ) {
                setErrorDataBank( prevErrorDataBank => ({
                    ...prevErrorDataBank,
                    [clave]: "el campo es requerido"
                }))
                openSnackbarError('! Campos requeridos !')
                success = false;

            } else if( clave === "cbu" && valor.length !== 22 ) {
                setErrorDataBank( prevErrorDataBank => ({
                    ...prevErrorDataBank,
                    [clave]: "la longitud debe ser 22 caracteres numéricos"
                }))
                openSnackbarError('! CBU inválido !')

                success = false
            } else if ( clave === "bank_name" && valor.length < 3 ) {
                setErrorDataBank( prevErrorDataBank => ({
                    ...prevErrorDataBank,
                    [clave]: "la longitud debe ser, al menos, mayor a 2 caracteres"
                }))
            } else if ( clave === "account_type" && valor.length < 1 ) {
                setErrorDataBank( prevErrorDataBank => ({
                    ...prevErrorDataBank,
                    [clave]: "Tipo de cuenta inválida, seleccione un valor de la lista desplegable"
                }))
            }

            for ( const [ key, value ] of Object.entries( errorDataBank ) ) {
                if(key === "is_incubator"){
                    continue;
                }
                if ( value.length > 0 && key ){
                    openSnackbarError(`!Verifique los campos!`)
                    success = false
                }
            }
        }

        return success;
    }

    useEffect(() => {
        if( submit ) {
            openSnackbarSuccess(' Guardando datos... ')
            setWorking( true )
            saveDatabank();
        }
    }, [submit])

    useEffect(() => {
		if ( candidateData.id && getDataApi) {
			( async () => {
				await getDataBank()
			})();
		}
	})


    const getDataBank = async () => {
        setGetDataApi( false )

        await API.get(`/prospects/bank_data/${candidateData.id}`)
        .then(function (response) {
            if( response.data.bank_data ) {
                const data = response.data.bank_data

                setDataBank( prev => ({
                    ...prev,
                    bank_name: data.bank_name??"",
                    cbu: data.cbu??"",
                    account_type: data.account_type??"",
                }) );

                if ( data.bank_name.length > 0 && data.cbu.toString().length > 0 && data.account_type.toString().length > 0) {
                    setCheckBandkData( true )
                }

            } else {
                setDataBank( dataBankInitialState )
            }
        })
        .catch(function (error) {
            console.log(`getDataBank error =>`, error.response);
        });
	};

    const saveDatabank = async () => {

        /* const data = {
            bank_name: dataBank.bank_name,
            cbu: dataBank.cbu
        } */

        await API.post(`/prospects/bank_data/${candidateData.id}`, dataBank)
        .then(function (response) {
            console.log(`saveDatabank response => `, response)
            setCheckBandkData( true )

            openSnackbarSuccess('¡Datos bancarios guardados!')
        })
        .catch(function (error) {
            try {
                openSnackbarError(error.response.data.errors[0].msg)
            }catch (e) {
                openSnackbarError('No se pudo guardar los datos bancarios')
            }
            // console.log(`saveDatabank error =>`, error.response.data.errors[0].msg);
            // openSnackbarError('no se pudo guardar los datos bancarios')
        });

        setWorking( false )
        setSubmit( false )
	};

    const setDisplayClass = () => {
        const status = candidateData.prospect_status;
        let display_btn = "btn blue_btn balanz_btn"
        if( status === 28 || status === 29 || status === 48 || status === 49 || status === 58 || status === 59) {
            display_btn = "d-none"
        }

        return display_btn
    }

    return (
        <div className="col-md-12 item mb-4" id="container_add_data_bank">
            <div className="row header_data_section">agregar datos bancarios</div>
            {[...GROUP.EQ_DIRECTORES, GROUP.EQ_SOPORTE].includes(user_role) ? (
                <form id="form_add_data_bank" action="#" onSubmit={ (e) => handleSubmit(e) }>
                    <div className="row form-row">
                        <div className="col-md-6">
                            <label className="data_label blue_data d-blok" htmlFor="bank">
                                Banco
                                {errorDataBank.bank_name &&
                                    <span className="text-danger ms-2 fw-bold" style={{fontSize: '12px'}}>{ errorDataBank.bank_name }</span>
                                }
                            </label>
                            <input
                                type="text"
                                value={ dataBank.bank_name }
                                placeholder="Banco"
                                name="bank_name"
                                id="bank_name"
                                onChange={ (e) => handleChange(e, 'business_name') }
                                className={ errorDataBank.bank_name.length > 0 ? 'form-control border border-danger' : 'form-control' }
                            />
                        </div>
                        <div className="col-md-6">
                            <label className="blue_data" htmlFor="account_type">
                                Tipo cuenta bancaria
                                {errorDataBank.account_type &&
                                    <span className="text-danger ms-2 fw-bold" style={{fontSize: '12px'}}>{ errorDataBank.account_type }</span>
                                }
                            </label>

                            <select
                                onChange={ (e) => handleChange(e) }
                                value={ dataBank.account_type }
                                name="account_type"
                                id="account_type"
                                className={ errorDataBank.account_type.length > 0 ? 'form-control border border-danger data_item' : 'form-control data_item'}
                            >
                                <option value="0" disabled> Seleccione ... </option>
                                {listTypeAccount.map((option, index) => {
                                    return (
                                        <option key={index} value={option.id}>
                                            {option.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="row form-row mt-4">
                        <div className="col-md-6">
                            <label className="data_label blue_data d-blok" htmlFor="cbu">
                                CBU
                                {errorDataBank.cbu &&
                                    <span className="text-danger ms-2 fw-bold" style={{fontSize: '12px'}}>{ errorDataBank.cbu }</span>
                                }
                            </label>
                            <input
                                onChange={ (e) => handleChange(e, 'num') }
                                type="text"
                                value={ dataBank.cbu }
                                placeholder="CBU"
                                name="cbu"
                                id="cbu"
                                className={ errorDataBank.cbu.length > 0 ? 'form-control border border-danger' : 'form-control' }
                            />

                        </div>

                        <div className="col-md-6" style={{marginTop:"26px"}}>
                        {
                            candidateData.btn_ccbu === 0 ?
                            <>
                                <button type="button" onClick={ () =>
                                    {document.querySelector("#CCBU").click()}}
                                    className="btn blue_btn balanz_btn_longx">
                                    Constancia CBU

                                    <input type="file" id="CCBU" onChange={(e)=>{saveDocs(e, "ccbu")}} hidden/>
                                </button>
                            </>
                            :
                            <>
                                <a href={API_URL+`/prospects/${candidateData.id}/file/${candidateData.btn_ccbu}`} target="_blank" className="btn gray_btn button_cv balanz_btn_longx">CONSTANCIA CBU</a>
                                <FontAwesomeIcon style={{marginLeft: "10px", color: "red"}} icon={faTrash} onClick={() => {deleteDocs(candidateData.btn_ccbu)}}/>
                            </>
                            }
                        </div>

                        <div className="d-flex align-items-end justify-content-end mt-4">
                            <button
                                type="submit"
                                name="submit_add_data_bank"
                                id="submit_add_data_bank"
                                className={ setDisplayClass() }
                                disabled={ working }
                            >
                                Guardar
                            </button>
                        </div>
                    </div>
                </form>
            ): (
                <div className="row ml-3">
                    <div className="col-md-4 mb-4">
                        <span className="data_label blue_title d-blok me-2">Banco</span>
                        <span className="blue_title data_item">BBVA</span>
                    </div>

                    <div className="col-md-4 mb-4">
                        <span className="data_label blue_title d-blok me-2">Tipo Cuenta Bancaria</span>
                        <span className="blue_title data_item">Cuenta corriente</span>
                    </div>

                    <div className="col-md-4">
                        <span className="data_label blue_title d-blok me-2">CBU</span>
                        <span className="blue_title data_item">434000000045403954353</span>
                    </div>
                </div>
            )}

        </div>
    )
}
