import {GROUP, GRUPO_STATUS, PROSPECT_STATUS} from "../constants";

export const parseRelationshipsData = relationships => {
    if (typeof relationships === 'string') return relationships;

    if (!relationships?.length) return '';

    const returnString = '(Comitente/Cuotapartista): ';
    const items = relationships.map(account => `${account.NumComitente}/${account.NumCuotapartista}`);

    return returnString.concat(humanItemsList(items));
}

export const humanItemsList = (items, separator = ', ', lastSeparator = ' y ') => {
    if (items.length < 3) return items.join(lastSeparator);

    const lastItem = items.pop();
    return items.join(separator).concat(lastSeparator, lastItem);
}


export default function canEditAnalyst(row, role_id) {
    const EQ_COMERCIAL = [...GRUPO_STATUS.EQ_COMERCIAL, PROSPECT_STATUS.CONTACTADO];
    if(GROUP.EQ_DIRECTORES.includes(role_id) && EQ_COMERCIAL.includes(row.prospect_status_id) ){
        return true;
    }
    else if(GROUP.EQ_ASISTENTES.includes(role_id) && GRUPO_STATUS.EQ_COMERCIAL.includes(row.prospect_status_id) ){
        return true;
    }
    else if(GROUP.EQ_SOPORTE.includes(role_id) && GRUPO_STATUS.EQ_SOPORTE.includes(row.prospect_status_id) ){
        return true;
    }
    else if(GROUP.EQ_SOPORTE.includes(role_id) && GRUPO_STATUS.EQ_SOPORTE.includes(row.prospect_status_id) ){
        return true;
    }
    else if(GROUP.COMPLIANCE.includes(role_id) && GRUPO_STATUS.COMPLIANCE.includes(row.prospect_status_id) ){
        return true;
    }
    else {
        return false;
    }

    }