import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { API_URL } from '../../../../constants';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PrintRowData = ({label, type, id, value, handleChange, typeOfPermitido, error, makeErrorField, cbu, deleteCBU = true, disabled = false}) => {

    const popover = (
        <Popover id="popover-basic" className="shadow">
            <Popover.Body>
                <span className="blue_data">Formato de entrada: <b>12123456781</b></span>
            </Popover.Body>
        </Popover>
    );

    //let today = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`
    var today = new Date();
       // var today_max = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }

        // console.log(value, "what are you?")
    return (

        type === "nodata" ?
            <tr className={"tr_"+id}>
                <td> <label className="data_label blue_data" htmlFor={ id }>{ label }</label> </td>
                <td>
                    <span className="data_item blue_data">{ value === "" || value === undefined || value === null? label : value }</span>
                </td>
            </tr>
        :
            <tr className={"tr_"+id}>

                <td> <label className="data_label blue_data" htmlFor={ id }>{ label }</label> </td>
                <td>
                    
                    {id === "cuit" ?
                        <OverlayTrigger trigger="focus" placement="top" overlay={popover} >
                            <input
                                type={ type }
                                className="data_item blue_data"
                                name={ id }
                                id={ id }
                                value={ value }
                                disabled
                                placeholder={ value ? value.lenght > 0 ? value : '' : label }
                                onChange={ (e) => handleChange(e, typeOfPermitido)}
                            />
                        </OverlayTrigger>
                        :
                            <input
                                type={ type }
                                className="data_item blue_data"
                                name={ id }
                                id={ id }
                                value={ value }
                                disabled
                                placeholder={ value ? value.lenght > 0 ? value : '' : label }
                                onChange={ (e) => handleChange(e, typeOfPermitido)}
                                max={type === "date" ? `${yyyy}-${mm}-${dd}` : ""}
                            />
                    }

                    {cbu?.isCbu === true && (
                        cbu.candidateData.btn_ccbu === 0 ? 
                        <div>   
                            <button type="button" disabled={disabled} onClick={ () => 
                                {document.querySelector("#CCBU").click()}}
                                className="btn blue_btn balanz_btn_longx"> 
                                Constancia CBU

                                <input type="file" disabled={disabled} id="CCBU" onChange={(e)=>{cbu.saveDocs(e, "ccbu")}} hidden/>
                            </button>
                        </div>
                        :
                        <div>
                            <a href={API_URL+`/prospects/${cbu.candidateData.id}/file/${cbu.candidateData.btn_ccbu}`} target="_blank" className="btn gray_btn button_cv balanz_btn_longx">CONSTANCIA CBU</a>
                            {deleteCBU && (
                                <FontAwesomeIcon style={{marginLeft: "10px", color: "red"}} icon={faTrash} onClick={() => {cbu.deleteDocs(cbu.candidateData.btn_ccbu)}}/>
                            )}

                        </div>
                    )
                        
                    }

                    {error &&
                        makeErrorField(error)
                    }
                </td>
            </tr>
    )
}
