import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

import { useParams } from "react-router-dom";

/* API */
import { API } from "../../../http-common";

import "./FormRrss.css";
import PublicHeader from "../includes/PublicHeader/PublicHeader";
import { ModelAlert } from "../../../ModelAlert";

const FormRrss = () => {
	/* HASH GET FROM URL*/
	const { hash } = useParams();

	const [submit, setSubmit] = useState(false);

	/* MODALS ALERTS */
	const [modalSuccess, setModalSuccess] = useState(false);
	const [modalError, setModalError] = useState(false);

	/* const modalValidationInitialState = {
		state: false,
		msg: ""
	}
	const [modalValidation, setModalValidation] = useState(modalValidationInitialState); */

	/* OBJ DATA FORM */
	const DatosRrssInitialState = {
		rrss_ig: "",
		rrss_fb: "",
		rrss_tw: "",
		rrss_lk: "",
		cv: "",
	};
	const [datosRrss, setDatosRrss] = useState(DatosRrssInitialState);

	/* CV FILE */
	const [file, setFile] = useState(null);
	/* HANDLE ERROR FILE CV */
	const [errorFile, setErrorFile] = useState("");
	/* UPLOADING FILE */
	const [uploadingFile, setUploadingFile] = useState(false);

	/* HANDLE CHANGE INPUT FILE - FILE CV */
	const handleChangeInputFile = (event) => {
		if (event.target.files) {
			validateFile(event.target.files);
		}
	};

	const validateFile = (file_) => {
		const { name, size, type } = file_[0];

		console.log(`size`, size)

		let res = true;
		if( size > 5242880  ) {
			setErrorFile('Archivo muy pesado... adjunte un archivo menor a 5Mb');
			res = false
			setFile(null)
			document.querySelector("input[type=file]").value = "";
		}
		else if ( name !== "" && (type === "pdf" || type === "application/pdf") ) {
			setFile(file_);
			setErrorFile("");
		} else {
			res = false;
			setFile(null);
			/* REMOVE FILE NAME FROM INPUT VALUE */
			document.querySelector("input[type=file]").value = "";
			setErrorFile('CV inválido... Solo puede adjuntar archivos "PDF" ');
		}
		return res;
	};

	/* HANDLE CHANGE INPUT DATA RRSS */
	const handleChangeInputRrss = async (event) => {
		const { name, value } = event.target;

		await setDatosRrss((prevDatosRrss) => ({
			...prevDatosRrss,
			[name]: value,
		}));
	};

	/* HANDLE SUBMIT FORM */
	const handleSubmit = (e) => {
		e.preventDefault();

		/* if (datosRrss.cv === "" || file === null) {
			setSubmit(false);
			setErrorFile("Por favor adjunte su Curriculum Vitae");
		} else if (validateFile(file)) {
			setSubmit(true);
		} */

		setSubmit(true);
	};

	/* OBSERVER TO FILE */
	useEffect(() => {
		if (file) {
			datosRrss.cv = file[0];
		}
	}, [file]);

	/* SET STATUS ONCE TIME */
	useEffect(() => {
		setErrorFile("");

		/* GET DATA RRSS CANDIDATO */
		getDataRrss();
	}, []);

	/* OBSERVER SUBMIT */
	useEffect(() => {
		if (submit) {
			/* if (datosRrss.cv && hash) {
				guardarDatos();
			} */
			guardarDatos();
		}
	}, [submit]);

	const getDataRrss = async () => {
		await API.get(`/prospects/social_networks/hash/${hash}`)
		.then(function (response) {
			setDatosRrss(response.data.social_networks);
		})
		.catch(function (error) {
			console.log(`getDataRrss error =>`, error.response);
		});
	};

	const sendFileToApi = async () => {
		setUploadingFile(true);
		setErrorFile("");

		const dataForm = new FormData();
		dataForm.append("files", file);

		await API.post(
		"/file",
		{ dataForm, hash },
		{ headers: { "Content-type": "multipart/form-data" } }
		)
		.then(function (response) {
			setDatosRrss((prevDatosRrss) => ({
			...prevDatosRrss,
			["cv"]: response.data.data.path,
			}));
			setUploadingFile(false);
		})
		.catch(function (error) {
			setSubmit(false);

			/* REMOVE FILE NAME FROM INPUT VALUE */
			document.querySelector("input[type=file]").value = "";

			setFile(null);
			setUploadingFile(false);

			setErrorFile("No se pudo guardar su archivo, intente de nuevo");
			console.log(`sendFileToApi error =>`, error.response);
		});
	};

	const guardarDatos = async () => {
		setUploadingFile(true);

		const dataForm = new FormData();

		for (const [key, value] of Object.entries(datosRrss)) {
			dataForm.append(key, value);
		}
		//dataForm.delete('cv')

		await API.post( `/prospects/social_networks/hash/${hash}`,
			dataForm,
			{ timeout: 10000 }
		)
		.then(function (response) {
			setModalSuccess(true);
			setSubmit(false);
			setDatosRrss(DatosRrssInitialState);
			/* setTimeout(() => {
				window.location="/"
			}, 5000); */
		})
		.catch(function (error) {
			setSubmit(false);

			/* REMOVE FILE NAME FROM INPUT VALUE */
			document.querySelector("input[type=file]").value = "";

			setFile(null);
			setUploadingFile(false);

			setModalError(true);

			setDatosRrss(DatosRrssInitialState);

			console.log(`guardarDatos error =>`, error.response);
		});

		setUploadingFile(false);

		/* REMOVE FILE NAME FROM INPUT VALUE */
		document.querySelector("input[type=file]").value = "";
		setSubmit(false);
	};

  	const makeErrorField = (text) => {
		return (
			<Form.Text
				className="text-danger fw-bold"
				style={{ fontSize: "11px", lineHeight: "0" }}
			>
				{text}
			</Form.Text>
		);
	};

	return (
		<div className="container-fluid" id="container_fluid-rrss">
			<PublicHeader />

			<div className="container-fluid formstep my-5" id="container_form_rrss">
				<Form action="response" onSubmit={handleSubmit}>
					<div className="title_rrss text-center my-5">
						<h2 className="h4 fw-bold">
						Gracias por postularte como Independent Financial Advisor.
						<br />
						Para avanzar en tu postulación te pedimos que por favor completes
						este formulario.
						</h2>

						<br />
						<p className="h4">
						Completa cada box con el nombre de usuario de las redes sociales
						que figuran aquí debajo. En caso de no poseer una de estas redes
						sociales, puedes dejar el campo vacío.
						</p>
					</div>

					<Form.Group as={Row} className="mb-3 justify-content-center">
						<Form.Label className="fw-bold" column sm={2}>
							Instagram
						</Form.Label>
						<Col sm={6}>
							<Form.Control
								type="text"
								name="rrss_ig"
								id="rrss_ig"
								placeholder="perfil de Instagram"
								value={datosRrss.rrss_ig}
								onChange={(e) => handleChangeInputRrss(e)}
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3 justify-content-center">
						<Form.Label className="fw-bold" column sm={2}>
							Facebook
						</Form.Label>
						<Col sm={6}>
							<Form.Control
								type="text"
								name="rrss_fb"
								id="rrss_fb"
								placeholder="perfil de Facebook"
								value={datosRrss.rrss_fb}
								onChange={(e) => handleChangeInputRrss(e)}
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3 justify-content-center">
						<Form.Label className="fw-bold" column sm={2}>
							Twitter
						</Form.Label>
						<Col sm={6}>
							<Form.Control
								type="text"
								name="rrss_tw"
								id="rrss_tw"
								placeholder="perfil de Twitter"
								value={datosRrss.rrss_tw}
								onChange={(e) => handleChangeInputRrss(e)}
							/>
						</Col>
					</Form.Group>

					<Form.Group as={Row} className="mb-3 justify-content-center">
						<Form.Label className="fw-bold" column sm={2}>
							Linkedin
						</Form.Label>
						<Col sm={6}>
							<Form.Control
								type="text"
								name="rrss_lk"
								id="rrss_lk"
								placeholder="perfil de Linkedin"
								value={datosRrss.rrss_lk}
								onChange={(e) => handleChangeInputRrss(e)}
							/>
						</Col>
					</Form.Group>

					<div className="title_rrss text-center mt-5">
						<p className="h4"> Por favor, adjunta tu Curriculum Vitae </p>
					</div>
					<Form.Group as={Row} className="mb-3 justify-content-center">
						<Col sm={6}>
							<Form.Control
								type="file"
								id="cv"
								name="cv"
								placeholder="adjuntar"
								multiple={false}
								accept=".pdf"
								onChange={(e) => handleChangeInputFile(e)}
							/>
							{errorFile.length > 0 && (
								makeErrorField(errorFile)
							)}
							{uploadingFile && (
								<Form.Text
								className="text-success fw-bold"
								style={{ fontSize: "11px", lineHeight: "0" }}
								>
								preparando archivo ...
								</Form.Text>
							)}
						</Col>
					</Form.Group>
					<br />
					<br />

					<Form.Group as={Row} className="mb-3 justify-content-center">
						<Col sm={4}>
							<Form.Control
								type="submit"
								value="Enviar"
								className="btn btn-balanz blue_btn"
								disabled={uploadingFile ? true : false}
							/>
						</Col>
					</Form.Group>
					<br />
					<br />
				</Form>
			</div>

			<ModelAlert
				title=""
				text="Muchas gracias por completar este formulario. En breve nos estaremos comunicando contigo para avanzar en el proceso de pre ingreso"
				isOpen={modalSuccess}
				setIsOpen={() => setModalSuccess(false)}
			/>

			{/* <ModelAlert
				title=""
				text={ modalValidation.msg }
				isOpen={ modalValidation.state }
				setIsOpen={ () => setModalValidation({'state': false}) }
			/> */}

			<ModelAlert
				title="Error ..."
				text="No fue posible guardar tus datos, intenta de nuevo"
				isOpen={modalError}
				setIsOpen={() => setModalError(false)}
			/>
		</div>
	);
};

export default FormRrss;
