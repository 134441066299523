import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import './footer.css'

/* IMAGES */
import logo_balanz from '../../../assets/images/footer/logo_balanz.png'

import IconTl from '../../../assets/images/footer/icon-tl.png'
import IconFb from '../../../assets/images/footer/icon-fb.png'
import IconIg from '../../../assets/images/footer/icon-ig.png'
import IconYtb from '../../../assets/images/footer/icon-ytb.png'
import IconTw from '../../../assets/images/footer/icon-tw.png'
import IconLk from '../../../assets/images/footer/icon-lk.png'
import IconAppStore from '../../../assets/images/footer/icon-appstore.png'
import IconPlayStore from '../../../assets/images/footer/icon-playstore.png'

import cnv from '../../../assets/images/footer/cnv.png'
import byma from '../../../assets/images/footer/byma.png'
import mae from '../../../assets/images/footer/mae.png'
import bcra from '../../../assets/images/footer/bcra.png'
import rofex from '../../../assets/images/footer/rofex.png'
import cafci from '../../../assets/images/footer/cafci.png'
import sid from '../../../assets/images/footer/sid.png'
import cab from '../../../assets/images/footer/cab.png'
import thomsonReuters from '../../../assets/images/footer/thomsonReuters.png'
import isoGestionAmbiental from '../../../assets/images/footer/isoGestionAmbiental.png'

import qrDolar from '../../../assets/images/footer/qrDolar.png'
import qrValores from '../../../assets/images/footer/qrValores.png'
import qrSociedad from '../../../assets/images/footer/qrSociedad.png'

const Footer = () => {
    return (

        <Container fluid className="container_main section_page" id="footer">
            <Row id="logo-links">
                <div id="col-logo">
                    <img src={ logo_balanz } alt="logo balanz" />
                    <br />
                    <p className="p-0 ps-3">
                        Av. Corrientes 316. Piso 3.<br/>
                        CABA. Argentina<br/>
                        consultas@balanz.com<br/>
                        0800 123 456<br/>
                    </p>
                </div>

                <div id="col-links1">
                    <ul>
                        <li> <a href="#" target="_blank">Inversiones</a> </li>
                        <li> <a href="#" target="_blank">Preguntas frecuentes</a> </li>
                        <li> <a href="#" target="_blank">Contactanos</a> </li>
                        <li> <a href="#" target="_blank">Colección Balanz</a> </li>
                    </ul>
                </div>

                <div id="col-links2">
                    <ul>
                        <li> <a href="#" target="_blank">Trabajá en Balanz</a> </li>
                        <li> <a href="#" target="_blank">Comisiones</a> </li>
                        <li> <a href="#" target="_blank">Legales</a> </li>
                    </ul>
                </div>

                <div id="col-icons-rrss">
                    <ul>
                        <li> <a href="#" target="_blank"> <img src={ IconIg }  alt="icon Instagram" /></a>  </li>
                        <li> <a href="#" target="_blank"> <img src={ IconFb }  alt="icon Facebook" /> </a>  </li>
                        <li> <a href="#" target="_blank"> <img src={ IconTw }  alt="icon Twitter" />  </a>  </li>
                        <li> <a href="#" target="_blank"> <img src={ IconLk }  alt="icon Linkedin" /> </a>  </li>
                        <li> <a href="#" target="_blank"> <img src={ IconYtb } alt="icon Youtube" />  </a>  </li>
                        <li> <a href="#" target="_blank"> <img src={ IconTl }  alt="icon Telegram" /> </a>  </li>
                    </ul>

                    <ul>
                        <li> <a href="#" target="_blank"> <img src={ IconAppStore }  alt="icon App Store" />  </a>  </li>
                        <li> <a href="#" target="_blank"> <img src={ IconPlayStore } alt="icon Play Store" /> </a>  </li>
                    </ul>
                </div>
            </Row>

            <Row id="text-qr">
                <div id="footer-text" id="col-text">
                    <p>
                        BALANZ CAPITAL VALORES S.A.U. Agente de Liquidación y Compensación y Agente de Negociación Integral N°210 ante la CNV. Agente de Colocación y Distribución Integral de Fondos Comunes de Inversión N° 62 ante la CNV. Agente miembro de BYMA, MAE, MAV y Rofex. INSCRIPTO ANTE INSPECCIÓN GENERAL DE JUSTICIA BAJO EL N° 13981 DEL LIBRO 40, TOMO – SOCIEDAD POR ACCIONES. CUIT: 30-71063067-0.
                    </p>

                    <p>
                        BALANZ SOCIEDAD GERENTE DE FONDOS COMUNES DE INVERSION S.A.U. Agente de Administración de Productos de Inversión Colectiva de Fondos Comunes de Inversión N°32 ante la CNV.
                    </p>

                    <p>
                        Av. Corrientes 330, piso 1. CABA, Argentina. INSCRIPTO ANTE IGJ BAJO EL N° 13981 DEL LIBRO 40, TOMO – SOCIEDAD POR ACCIONES CUIT: 33-70812346-9.
                    </p>
                </div>

                <div id="col-qr">
                    <Row>
                        <Col>
                            <a href="#" target="_blank">
                                <img src={ qrValores } alt="Balanz capital valores S.A.U." />
                            </a>
                            <a href="#" target="_blank">
                                Balanz<br/>Capital<br/>Valores S.A.U
                            </a>
                        </Col>
                        <Col>
                            <a href="#" target="_blank">
                                <img src={ qrSociedad } alt="Balanz Sociedad Gerente de Fondos Comunes de Inversión S.A.U." />
                            </a>
                            <a href="#" target="_blank">
                                Balanz Sociedad<br/>Gerente de<br/>Fondos<br/>Comunes de<br/>Inversión S.A.U.
                            </a>
                        </Col>
                        <Col>
                            <a href="#" target="_blank">
                                <img src={ qrDolar } alt="Dólar Balanz S.A.U." />
                            </a>
                            <a href="#" target="_blank">
                                Dólar Balanz<br/>S.A.U.
                            </a>
                        </Col>

                        <Col md={ 12 }>
                            <br/>
                            <img src={ isoGestionAmbiental } alt="iso gestion ambiental" className="w-100" />
                        </Col>
                    </Row>
                </div>
            </Row>

            <Row id="last-col-img">
                <ul>
                    <li>
                        <a href="http://www.cnv.gov.ar/sitioweb/" rel="noreferrer" target="_blank">
                            <img src={ cnv } alt="comisión nacional de valores republica de argentina" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.byma.com.ar/en/homepage/" rel="noreferrer" target="_blank">
                            <img src={ byma } alt="BYMA" />
                        </a>
                    </li>
                    <li>
                        <a hhref="http://www.mae.com.ar/" rel="noreferrer" target="_blank">
                            <img src={ mae } alt="mercado abierto electrónico" />
                        </a>
                    </li>
                    <li>
                        <a href="http://www.bcra.gov.ar/" rel="noreferrer" target="_blank">
                            <img src={ bcra } alt="BCRA" />
                        </a>
                    </li>
                    <li>
                        <a href="http://www.rofex.com.ar/" rel="noreferrer" target="_blank">
                            <img src={ rofex } alt="ROFEX" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.cafci.org.ar/" rel="noreferrer" target="_blank">
                            <img src={ cafci } alt="Cámara de Argentina de Fondos Comunes de Inversión" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.argentina.gob.ar/sid-sistema-de-identidad-digital" rel="noreferrer" target="_blank">
                            <img src={ sid } alt="SID Sistema de Identidad Digital" />
                        </a>
                    </li>
                    <li>
                        <a href="http://www.cadane.com.ar/home.php?v=1" rel="noreferrer" target="_blank">
                            <img src={ cab } alt="cámara de agentes de bolsa" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.thomsonreuters.com.ar/es/tienda.html?gclid=CjwKCAjw2MTbBRASEiwAdYIpsbnHLGX5zlcFg2QVFrVKWiANuDPggBGB1FdxPw7_AXeVCm0PrIjZWxoCVkwQAvD_BwE" rel="noreferrer" target="_blank">
                            <img src={ thomsonReuters } alt="thomson reuters" />
                        </a>
                    </li>
                </ul>
            </Row>

            <Row id="derechos_reservados">
                <p>© 2021 Balanz. Todos los derechos reservados.</p>
            </Row>

        </Container>
    )
}
export default Footer