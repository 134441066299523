import React, { useState } from 'react';
import { Container, Row, Col, Nav, Form, Tab } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Button from '@restart/ui/esm/Button';

/* ICONS FONTAWESOME */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

/* CSS */
import '../../../assets/css/general.css';
import '../../../assets/css/modales.css';
import './Home.css';

/* IMAGENES */
import imgPropaganda from '../../../assets/images/home/propaganda.png';
import iconList from '../../../assets/images/home/icon-list.png';

/* FORMTYPE */
import FormType from './FormType';

/* FOOTER */
import Footer from './Footer';

const Home = () => {

    const formInitial = "form_fisica"
    const [formType, setFormType] = useState(formInitial)

    const [checkInputFormTypeFisico, setCheckInputFormTypeFisico] = useState(true)
    const [checkInputFormTypeJuridico, setCheckInputFormTypeJuridico] = useState(false)

    const handleChangeFormType = (e) => {
        /* console.log(`e`, e.target.value) // form_fisica / form_juridica */
        let target = e.target.value
        if(!e) {
            setFormType(formInitial)
        }

        if (target === "form_fisica") {
            setCheckInputFormTypeFisico(true)
            setCheckInputFormTypeJuridico(false)

        } else {
            setCheckInputFormTypeFisico(false)
            setCheckInputFormTypeJuridico(true)
        }

        setFormType(target)
    }

    const handleCliclSetFormType = (type) => {
        if(!type) {
            setFormType(formInitial)
        }

        if (type === "form_fisica") {
            setCheckInputFormTypeFisico(true)
            setCheckInputFormTypeJuridico(false)

        } else {
            setCheckInputFormTypeFisico(false)
            setCheckInputFormTypeJuridico(true)
        }

        setFormType(type)
    }

    return (
        <>
            <Container fluid className="container_main">
                <Row className="" id="header_top">
                    <Nav defaultActiveKey="/home" as="ul">
                        <Nav.Item as="li" id="li_home">
                            <Link to="/home">Balanz</Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                            <Link to="/inversiones">Inversiones</Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Link to="/cómo_invertir">¿Cómo invertir?</Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Link to="/imvestment_ideas">imvestment Ideas</Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Link to="/reportes">Reportes</Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Link to="/balanz_university">Balanz University</Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Link to="/eventos">Eventos</Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <Link to="/sobre_nosotros">Sobre nosotros</Link>
                        </Nav.Item>

                        <Nav.Item as="li" id="li_new_acc">
                            <Link to="./">Abrir Cuenta</Link>
                        </Nav.Item>

                        <Nav.Item as="li" id="li_login">
                            <Link to="./">Ingresar</Link>
                        </Nav.Item>

                        <Nav.Item as="li" id="li_search">
                            <Button id="search" className="btn bg-transparent text-white">
                                <FontAwesomeIcon icon={faSearch} />
                            </Button>
                        </Nav.Item>
                    </Nav>
                </Row>

                {/* BANNER */}
                <Row id="banner_section" className="banner_home section_page">
                    <Col>
                        <h1>IFA</h1>
                    </Col>
                </Row>

                {/* TEXT AFTER BANNER */}
                <Row id="text_after_banner" className="section_page">
                    <Col>
                        <strong>La independencia que querés,<br/>con el respaldo que necesitás.</strong>
                    </Col>
                </Row>


                {/* PROPAGANDO BALANZ IFA */}
                <Row id="propaganda" className="section_page">
                    <Col>
                        <Row>
                            <img src={imgPropaganda} alt="balanz IFA" />

                            <p>
                                Sumate al mejor equipo de Asesores Financieros del mercado, a través de nuestro programa Independent Financial Advisor.
                            </p>
                        </Row>
                    </Col>
                </Row>

                <Row id="row_list" className="section_page">
                    <Col>
                        <div id="row_list-col_list">
                            <h3 className="col_list-title_list">
                                ¿Estás interesado en ser un profesional independiente en el mercado de capitales?
                            </h3>

                            <ul>
                                <li>
                                    <img src={iconList} alt="arrow icon list" />
                                    <span>Desarrollá tu cartera.</span>
                                </li>
                                <li>
                                    <img src={iconList} alt="arrow icon list" />
                                    <span>Llevá a cabo tus propias estrategias de inversión.​</span>
                                </li>
                                <li>
                                    <img src={iconList} alt="arrow icon list" />
                                    <span>Manejá tus tiempos.​</span>
                                </li>
                                <li>
                                    <img src={iconList} alt="arrow icon list" />
                                    <span>Tené el respaldo e infraestructura de la empresa líder del mercado, con capacitación constante, research a disposición y atención personalizada.</span>
                                </li>
                                <li>
                                    <img src={iconList} alt="arrow icon list" />
                                    <span>Contá con la mejor tecnología y plataforma de operaciones.</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="container_main section_page" id="row_form">
                <Row id="row_form_postulant_ifa">
                    <Col md={12} id="row_form_postulant_ifa-header_form">
                        <h3>
                            <strong>Tenemos una propuesta para vos.</strong>
                            <b>Completá tus datos</b>
                        </h3>
                    </Col>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={ formInitial }>

                        <Col md={6}>
                            <Nav className="mb-3" id="nav_tab_form">
                                <Nav.Item onClick={ () => handleCliclSetFormType('form_fisica') }>
                                    <Nav.Link eventKey="form_fisica">
                                        <Form.Check
                                            onClick={ (e) => handleChangeFormType(e) }
                                            onChange={ () => '' }
                                            inline
                                            checked={ checkInputFormTypeFisico }
                                            value="form_fisica"
                                            label="Persona Física"
                                            name="radio-type_form"
                                            type="radio"
                                            id="radio-form_fisica"
                                        />
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item onClick={ () => handleCliclSetFormType('form_juridica') } className="hidden">
                                    <Nav.Link eventKey="form_juridica">
                                        <Form.Check
                                            onClick={ (e) => handleChangeFormType(e) }
                                            onChange={ () => '' }
                                            inline
                                            className="d-none"
                                            checked={ checkInputFormTypeJuridico }
                                            value="form_juridica"
                                            label="Persona Jurídica"
                                            name="radio-type_form"
                                            type="radio"
                                            id="radio-form_juridica"
                                        />
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col md={12}>
                            <Tab.Content>
                                <FormType formType={formType} />
                            </Tab.Content>
                        </Col>
                    </Tab.Container>
                </Row>
            </Container>

            <Container fluid className="container_main">

                <Row id="RowVideo" className="section_page">
                    <Col className="row">
                        <Col md={12}>
                            <div id="RowVideo-col_text">
                                <strong>Convertí tu pasión en tu mejor negocio.</strong>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div id="RowVideo-col_video">
                                <iframe id="video_iframe" title="video_iframe" width="873" height="338" src="https://www.youtube.com/embed/dYMaL3zXUTk"> </iframe>
                            </div>
                        </Col>
                    </Col>
                </Row>

                <Row id="provincias_clientes" className="section_page">
                    <Col>
                        <Col md={11} id="provincias_clientes-col_container">
                            <p className="text-center">La estructura y soporte de un grande, con la dinámica y flexibilidad de los emprendedores.</p>
                            <Col md={9}>
                                <Col id="provincias_clientes-col_container-provincia">
                                    <p>Presencia en</p>
                                    <p>23 provincias.</p>
                                </Col>
                                <Col id="provincias_clientes-col_container-clientes">
                                    <p>Más de</p>
                                    <p>200.000 clientes.</p>
                                </Col>
                            </Col>
                        </Col>
                    </Col>
                </Row>

                <Footer />

            </Container>
        </>
    );
}
export default Home;